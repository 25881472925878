import { T, useTranslate } from '@tolgee/react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UserRoleEnum } from '../../common/enums/userRoleEnum';
import { useWindowSizeType } from '../../common/hooks/useWindowSizeType';
import { AssignedProvidersContainer } from '../../features/AssignedProviders/components/AssignedProvidersContainer/AssignedProvidersContainer';
import { AppointmentByPatientContainer } from '../../features/appointmentsByPatient/components/AppointmentsByPatientContainer/AppointmentByPatientContainer';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { ProfilePatient } from '../../features/profile/components/ProfilePatient/ProfilePatient';
import { BackButton, Button } from '../../features/ui';
import { ViewSwitch } from '../../features/ui/ViewSwitch/VIewSwitch';
import styles from './patient-details-page.module.scss';

const VIEW_CONFIG = [
  {
    key: 'all_appointments',
    label: <T keyName="common.allAppointmentsTab.label" />
  },
  {
    key: 'assigned_providers',
    label: <T keyName="pages.PatientDetailsPage.providersTab.label" />
  }
];

export const PatientDetailsPage = () => {
  const { t } = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentView = searchParams.get('activeViewKey') || VIEW_CONFIG[0].key;
  const params = useParams();
  const { user } = useAuthConfigSelector();
  const navigate = useNavigate();
  const userRole = user?.role.id;
  const isAdmin = userRole === UserRoleEnum.Admin;
  const showViewSwitcher = isAdmin;

  const { isDesktop, isMobile } = useWindowSizeType();

  const onViewChange = (activeViewKey: string) => {
    setSearchParams({ activeViewKey });
  };

  const BookAppointmentButton = () => (
    <Button type="primary" className={styles.button} onClick={() => navigate('/appointments/appointment-create')}>
      {t('common.bookAppointmentBtn.text')}
    </Button>
  );

  return (
    <div className={styles.container}>
      <BackButton label={t('common.backBtn.label')} onClick={() => navigate(-1)} />
      <div className={styles.content}>
        <div className={styles.leftPanel}>
          <ProfilePatient userId={params.id!} />
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.rightPanelContent}>
            {showViewSwitcher ? (
              <ViewSwitch items={VIEW_CONFIG} onChange={onViewChange} activeView={currentView} />
            ) : (
              <span className={styles.pageTitleProvider}>{t('common.allAppointmentsTab.label')}</span>
            )}
            {isDesktop && <BookAppointmentButton />}
          </div>
          {currentView === VIEW_CONFIG[0].key ? <AppointmentByPatientContainer /> : <AssignedProvidersContainer />}
          {isMobile && <BookAppointmentButton />}
        </div>
      </div>
    </div>
  );
};
