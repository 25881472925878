import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const selector = (state: RootState) => state.appointmentsByPatient;

const appointmentsByPatientSelector = (state: RootState) => {
  const appointmentsByPatient = selector(state);
  return appointmentsByPatient;
};

export const useAppointmentsByPatienSelector = () => {
  const state = useAppSelector(appointmentsByPatientSelector);
  return state;
};
