import { type FC, type PropsWithChildren } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation
} from 'react-router-dom';
import { AccessConfigEnum, AccessService } from '../../features/access/AccessService';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import {
  AppointmentCreatePage,
  AppointmentDetailsPage,
  AppointmentJoinPage,
  ChangePasswordPage,
  ErrorPage,
  HomePage,
  LoginPage,
  PatientsPage
} from '../../pages';
import Layout from '../../pages/Layout';
import { MailRedirect } from '../../pages/emailRedirect/mail-redirect';
import { NotificationsPage } from '../../pages/notifications/NotificationsPage';
import { PatientDetailsPage } from '../../pages/patientDetails/PatientDetailsPage';
import { PersonalInformationPage } from '../../pages/personalInformation/PersonalInformationPage';
import { ProviderDetailsPage } from '../../pages/providerDetails/ProviderDetailsPage';
import { ProvidersPage } from '../../pages/providers/ProvidersPage';
import { ServiceDetailsPage } from '../../pages/serviceDetails/ServiceDetailsPage';
import { ServicesPage } from '../../pages/services/ServicesPage';

interface RestrictedAreaGuard extends PropsWithChildren {
  activeNestedKey?: AccessConfigEnum;
}

const RestrictedAreaGuard: FC<RestrictedAreaGuard> = ({ children, activeNestedKey }) => {
  const location = useLocation();
  const { user } = useAuthConfigSelector();
  const activeKey = location.pathname.split('/')[1] as AccessConfigEnum;

  if (user?.role.id && !AccessService.checkRouteAccess(activeKey, user.role.id, activeNestedKey)) {
    return <ErrorPage />;
  }
  return <>{children}</>;
};

const RequireAuth: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { authorized } = useAuthConfigSelector();

  if (!authorized) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }
  return <>{children}</>;
};

const RequireUnauth: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { authorized, changePassword } = useAuthConfigSelector();

  if (authorized) {
    const to = location.state?.from || '/appointments';
    return <Navigate to={to} replace />;
  }
  if (changePassword && location.pathname !== '/change-password') {
    return <Navigate to="/change-password" replace />;
  }
  if (!changePassword && location.pathname !== '/login') {
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* put here pages without top nav bar */}
      <Route path="/join/appointments/:id" element={<AppointmentJoinPage />} />
      <Route path="/proxy/email-redirect" element={<MailRedirect />} />
      {/* <Route path="/any" element={<div>Any page without top nav bar</div>} /> */}
      <Route element={<Layout />}>
        <Route
          element={
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          }
        >
          <Route path="/" element={<Navigate to="/appointments" replace />} />
          <Route path="/appointments" element={<HomePage />} />
          <Route
            path="/patients"
            element={
              <RestrictedAreaGuard>
                <PatientsPage />
              </RestrictedAreaGuard>
            }
          />
          <Route
            path="/patients/:id"
            element={
              <RestrictedAreaGuard>
                <PatientDetailsPage />
              </RestrictedAreaGuard>
            }
          />
          <Route
            path="/providers"
            element={
              <RestrictedAreaGuard>
                <ProvidersPage />
              </RestrictedAreaGuard>
            }
          />
          <Route path="/providers/:id" element={<ProviderDetailsPage />} />
          <Route
            path="/appointments/appointment-create"
            element={
              <RestrictedAreaGuard activeNestedKey={AccessConfigEnum['appointment-create']}>
                <AppointmentCreatePage />
              </RestrictedAreaGuard>
            }
          />
          <Route path="/appointments/:id" element={<AppointmentDetailsPage />} loader={AppointmentDetailsPage.loader} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route
            path="/services"
            element={
              <RestrictedAreaGuard>
                <ServicesPage />
              </RestrictedAreaGuard>
            }
          />
          <Route
            path="/services/:id"
            element={
              <RestrictedAreaGuard>
                <ServiceDetailsPage />
              </RestrictedAreaGuard>
            }
          />
          <Route path="/personal-information" element={<PersonalInformationPage />} />
        </Route>
        <Route
          element={
            <RequireUnauth>
              <Outlet />
            </RequireUnauth>
          }
        >
          <Route path="/login" element={<LoginPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
        </Route>
      </Route>
    </Route>
  )
);

// @ts-expect-error any
if (import.meta.hot) import.meta.hot.dispose(() => router.dispose());

export default function AppRoutes() {
  return <RouterProvider router={router} />;
}
