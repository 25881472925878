import { useTranslate } from '@tolgee/react';
import React, { useEffect } from 'react';
import { useChangeLanguageReaction } from '../../../../common/hooks/useChangeLanguageReaction';
import { isToday } from '../../../../common/utils/dateFormat';
import { Pagination } from '../../../ui/Pagination/Pagination';
import { useGroupedNotificationList } from '../../hooks/useGroupedNotificationList';
import { useNotificationListSelector } from '../../store/notificationListSelectors';
import { useNotificationListActionCreators } from '../../store/notificationListSlice';
import { NotificationListAppointmentItem } from '../NotificationListItem/NotificationListAppointmentItem';
import { NotificationListAvailabilityItem } from '../NotificationListItem/NotificationListAvailabilityItem';
import style from './notification-list-container.module.scss';

const PAGE_SIZE = 10;

export const NotificationListContainer: React.FC = () => {
  const { t } = useTranslate();
  const data = useNotificationListSelector();
  const notificationActions = useNotificationListActionCreators();
  const { groupedNotificationList } = useGroupedNotificationList(data);

  const fetchData = () => {
    notificationActions.getList(data.page);
  };

  useChangeLanguageReaction({ callback: fetchData });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={style.container}>
      {data.count === 0 && (
        <p className={style.noNotifications}>{t('features.NotificationListContainer.emptyList.title')}</p>
      )}
      {groupedNotificationList.map(([date, groupList]) => (
        <div key={date}>
          <p className={style.date}>{isToday(date) ? t('features.NotificationListContainer.today.label') : date}</p>
          {groupList.map((item, index) => {
            if (item.appointment) {
              return <NotificationListAppointmentItem key={`${data.page}${index}`} notification={item} />;
            } else {
              return <NotificationListAvailabilityItem key={`${data.page}${index}`} notification={item} />;
            }
          })}
        </div>
      ))}
      {data.count > PAGE_SIZE && (
        <Pagination
          className={style.pagination}
          current={data.page}
          total={data.count}
          onChange={(page) => notificationActions.getList(page)}
          pageSize={PAGE_SIZE}
          showSizeChanger={false}
        />
      )}
    </div>
  );
};
