import { useTranslate } from '@tolgee/react';
import { ConfigProvider, Radio, RadioChangeEvent } from 'antd';
import { FC } from 'react';
import './appointment-by-patient-history-switch.module.scss';
import { useTheme } from '../../../../common/providers/ThemeProvider';

interface Props {
  onChange: (checked: RadioChangeEvent) => void;
  isChecked: boolean;
}

export const AppointmentsByPatientHistorySwitch: FC<Props> = ({ onChange, isChecked }) => {
  const { t } = useTranslate();
  const {colors} = useTheme();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSizeLG: 13,
          colorPrimary: colors.textColor,
          colorText: colors.primary,
          colorTextDisabled: colors.primary,
          colorBgContainerDisabled: colors.background,
          borderRadius: 2
        },
        components: {
          Radio: {
            buttonCheckedBg: colors.primary,
            colorBorder: colors.primary
          }
        }
      }}
    >
      <Radio.Group defaultValue={isChecked} onChange={onChange} size="large" className="appointment-history-switch">
        <Radio.Button value={false}>{t('features.AppointmentsByPatientHistorySwitch.upcomingBtn.text')}</Radio.Button>
        <Radio.Button value={true}>{t('features.AppointmentsByPatientHistorySwitch.pastBtn.text')}</Radio.Button>
      </Radio.Group>
    </ConfigProvider>
  );
};
