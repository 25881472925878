import type { CaseReducer, Draft, PayloadAction } from '@reduxjs/toolkit';
import { StateStatus } from '../enums/store';
import { SliceState } from '../interfaces/store';

export const setSliceSuccess =
  <T, A>(reducer?: CaseReducer<T, PayloadAction<A>>) =>
    (state: Draft<SliceState & T>, action: PayloadAction<A>) => {
      state.status = StateStatus.SUCCESS;
      state.error = null;
      reducer?.(state, action);
    };

export const setSliceError = (state: SliceState, action: PayloadAction<any, string, any, any>) => {
  state.status = StateStatus.ERROR;
  state.error = action.error;
};

export const setSliceLoading = (state: SliceState) => {
  state.status = StateStatus.LOADING;
};
