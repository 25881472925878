import { FC } from 'react';
import { formatDateWithDayJS } from '../../../../../common/utils/dateFormat';

interface Props {
  start: string;
  end: string;
}

export const AppointmentTime: FC<Props> = ({ start, end }) => {
  return (
    <>
      {`${formatDateWithDayJS(start, 'LT')} - ${formatDateWithDayJS(end, 'LT')}, `}
      {formatDateWithDayJS(start, 'LL')}{' '}
    </>
  );
};
