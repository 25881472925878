import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProviderService } from '../services/ProviderService';
import { AssignedProvider } from './assignedProvidersSlice';

export interface AssignedProvidersResponse {
  assignedProviders: AssignedProvider[];
  pharmacist: AssignedProvider | null
}

export const getAssignedProviders = createAsyncThunk<AssignedProvidersResponse, string>(
  'assignedProviders/get-list',
  async (userId) => {
    const assignedProviders: AssignedProvidersResponse = await ProviderService.getAssignedProviders(userId);
    return assignedProviders;
  }
);

export const assignedProvidersThunks = {
  getAssignedProviders
};
