import { ConfigProvider } from 'antd';
import { type FC, type PropsWithChildren } from 'react';
import vars from '../../assets/styles/variables.module.scss';
import { antDLocales } from '../../localization/date';
import { getDateLocale } from '../utils/dateFormat';

export const useTheme = () => {
  const body: CSSStyleDeclaration = getComputedStyle(document.body);

  return {
    colors: {
      primary: body.getPropertyValue('--primary'),
      darkBlue: body.getPropertyValue('--darkBlue'),
      background: body.getPropertyValue('--background'),
      black: body.getPropertyValue('--black'),
      textColor: body.getPropertyValue('--textColor'),
    }
  };
};

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useTheme();
  return (
    <ConfigProvider
      locale={antDLocales[getDateLocale()]}
      theme={{
        token: {
          fontFamily: vars.fontFamily
        },
        components: {
          Button: {
            borderRadiusLG: 2,
            controlHeightLG: 50,
            fontSizeLG: 15,
            colorPrimary: colors.primary,
            algorithm: true
          },
          Input: {
            borderRadiusLG: 5,
            controlHeightLG: 35,
            colorBorder: colors.darkBlue,
            algorithm: true
          },
          Checkbox: {
            colorBorder: colors.primary,
            colorPrimary: colors.primary,
            borderRadiusSM: 2,
            controlInteractiveSize: 18,
            fontSize: 15,
            lineWidth: 2,
            algorithm: true
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeProvider;
