import { FC } from 'react';
import TrashIcon from '../../../../assets/icons/trash.svg';
import UndoIcon from '../../../../assets/icons/undo.svg';
import { Schedule, Slot } from '../../interfaces/schedule';
import style from '../AvailabilitySchedule/availability-schedule.module.scss';

interface Props {
  record: Schedule;
  slot: Slot;
  handleRemoveSlot: (dayOfTheWeek: number, index: number) => void;
  handleUndoRemoveSlot: (dayOfTheWeek: number, index: number) => void;
  index: number;
}

export const AvailabilitySlotActions: FC<Props> = ({ record, slot, handleRemoveSlot, handleUndoRemoveSlot, index }) => {
  return (
    <>
      {record.enabled && (
        <div className={style.availabilityActions}>
          {!slot.deleted && (
            <img
              src={TrashIcon}
              className={style.trashIcon}
              onClick={() => handleRemoveSlot(record.dayOfTheWeek, index)}
            />
          )}
          {slot.deleted && (
            <img
              src={UndoIcon}
              className={style.undoIcon}
              onClick={() => handleUndoRemoveSlot(record.dayOfTheWeek, index)}
            />
          )}
        </div>
      )}
    </>
  );
};
