import { useTranslate } from '@tolgee/react';
import { Button, ConfigProvider, DatePicker, Radio, RadioChangeEvent, Select, Space } from 'antd';
import { Dayjs } from 'dayjs';
import { FC, useCallback, useState } from 'react';
import { customDateFormat } from '../../../../common/constants';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { disabledPastDate, getWeekDayName } from '../../../../common/utils/dateFormat';
import useModal from '../../../ui/Modal/useModal';
import { NumberInput } from '../../../ui/NumberInput/NumberInput';
import {
  AppointmentSerieEndOptionEnum,
  AppointmentSeriesIntervalUnitEnum,
  AppointmentSeriesMonthWeekEnum,
  DayOfTheWeekEnum
} from '../../enums/appointmentEnums';
import { SeriesConfig } from '../../interfaces/appointment';
import { useAppointmentRecurrenceConfirmModal } from './AppointmentRecurrenceConfirmModal';
import styles from './appointment-recurrence.module.scss';
import { useTheme } from '../../../../common/providers/ThemeProvider';

const weekDays = [
  DayOfTheWeekEnum.MONDAY,
  DayOfTheWeekEnum.TUESDAY,
  DayOfTheWeekEnum.WEDNESDAY,
  DayOfTheWeekEnum.THURSDAY,
  DayOfTheWeekEnum.FRIDAY,
  DayOfTheWeekEnum.SATURDAY,
  DayOfTheWeekEnum.SUNDAY
];

const weekDayOptions = weekDays.map((value) => ({
  label: getWeekDayName(value),
  value
}));

export const useAppointmentRecurrenceSetupModal = () => {
  const { Modal, hideModal, showModal } = useModal({
    width: 600
  });

  const AppointmentRecurrenceSetupModal: FC<{ onDone: (config: SeriesConfig) => void }> = useCallback(
    ({ onDone }) => {
      const { t } = useTranslate();
      const { isMobile } = useWindowSizeType();

      const [intervalUnit, setIntervalUnit] = useState<AppointmentSeriesIntervalUnitEnum>(
        AppointmentSeriesIntervalUnitEnum.WEEK
      );

      // Weekly
      const [weeklyInterval, setWeeklyInterval] = useState(1);
      const [weeklyDays, setWeeklyDays] = useState<DayOfTheWeekEnum[]>([new Date().getDay()]);

      // Monthly
      const [monthlyMode, setMonthlyMode] = useState<'day' | 'weekday'>('day');
      const [day, setDay] = useState(1);
      const [monthWeek, setMonthWeek] = useState<AppointmentSeriesMonthWeekEnum>(AppointmentSeriesMonthWeekEnum.FOURTH);
      const [dayKind, setDayKind] = useState<DayOfTheWeekEnum>(() => new Date().getDay());
      const [monthlyInterval, setMonthlyInterval] = useState(1);
      const [monthlyWeekdayInterval, setMonthlyWeekdayInterval] = useState(1);

      // end
      const [end, setEnd] = useState<AppointmentSerieEndOptionEnum>(AppointmentSerieEndOptionEnum.NEVER);
      const [dateOn, setDateOn] = useState<Dayjs | null>();
      const [occurrences, setOccurrences] = useState(1);

      const {colors} = useTheme();

      const { AppointmentRecurrenceConfirmModal, showAppointmentRecurrenceConfirmModal } =
        useAppointmentRecurrenceConfirmModal();

      const onIntervalUnitChange = (e: RadioChangeEvent) => {
        setIntervalUnit(e.target.value);
      };

      const onMonthlyModeChange = (e: RadioChangeEvent) => {
        setMonthlyMode(e.target.value);
      };

      const doneHandler = () => {
        hideModal();
        const endValues: Record<AppointmentSerieEndOptionEnum, string | number | undefined> = {
          [AppointmentSerieEndOptionEnum.NEVER]: undefined,
          [AppointmentSerieEndOptionEnum.ON]: dateOn?.toISOString(),
          [AppointmentSerieEndOptionEnum.AFTER]: occurrences
        };
        const weekDaysValues: Record<AppointmentSeriesIntervalUnitEnum, number[] | undefined> = {
          [AppointmentSeriesIntervalUnitEnum.WEEK]: weeklyDays,
          [AppointmentSeriesIntervalUnitEnum.MONTH]: monthlyMode === 'weekday' ? [dayKind] : undefined
        };
        onDone({
          intervalUnit,
          interval: intervalUnit === AppointmentSeriesIntervalUnitEnum.WEEK ? weeklyInterval : monthlyMode === 'weekday' ? monthlyWeekdayInterval : monthlyInterval,
          weekDays: weekDaysValues[intervalUnit],
          monthDay: intervalUnit === AppointmentSeriesIntervalUnitEnum.MONTH && monthlyMode === 'day' ? day : undefined,
          monthWeek:
            intervalUnit === AppointmentSeriesIntervalUnitEnum.MONTH && monthlyMode === 'weekday'
              ? monthWeek
              : undefined,
          end: {
            option: end,
            value: endValues[end]
          }
        });
      };

      const doneDisabled = end === AppointmentSerieEndOptionEnum.ON && !dateOn;

      const okHandler = () => {
        if (day > 28 && intervalUnit === AppointmentSeriesIntervalUnitEnum.MONTH) {
          showAppointmentRecurrenceConfirmModal();
        } else {
          doneHandler();
        }
      };

      const intervalUnitOptions = [
        {
          label: t('features.AppointmentRecurrenceSetupModal.intervalUnit.week'),
          value: AppointmentSeriesIntervalUnitEnum.WEEK
        },
        {
          label: t('features.AppointmentRecurrenceSetupModal.intervalUnit.month'),
          value: AppointmentSeriesIntervalUnitEnum.MONTH
        }
      ];

      const monthWeekOptions = [
        {
          label: t('features.AppointmentRecurrenceSetupModal.monthWeek.option.first'),
          value: AppointmentSeriesMonthWeekEnum.FIRST
        },
        {
          label: t('features.AppointmentRecurrenceSetupModal.monthWeek.option.second'),
          value: AppointmentSeriesMonthWeekEnum.SECOND
        },
        {
          label: t('features.AppointmentRecurrenceSetupModal.monthWeek.option.third'),
          value: AppointmentSeriesMonthWeekEnum.THIRD
        },
        {
          label: t('features.AppointmentRecurrenceSetupModal.monthWeek.option.fourth'),
          value: AppointmentSeriesMonthWeekEnum.FOURTH
        },
        {
          label: t('features.AppointmentRecurrenceSetupModal.monthWeek.option.last'),
          value: AppointmentSeriesMonthWeekEnum.LAST
        }
      ];

      return (
        <Modal onCancel={hideModal}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: colors.primary
              },
              components: {
                Radio: {
                  dotSize: 8,
                  wrapperMarginInlineEnd: 20
                },
                InputNumber: {
                  fontSizeLG: 15
                }
              }
            }}
          >
            <div className={styles.content}>
              <p className={styles.title}>{t('features.AppointmentRecurrenceSetupModal.title')}</p>
              <Radio.Group onChange={onIntervalUnitChange} value={intervalUnit} options={intervalUnitOptions} />
              {intervalUnit === AppointmentSeriesIntervalUnitEnum.WEEK ? (
                <div className={styles.repeat}>
                  <div>
                    {t('features.AppointmentRecurrenceSetupModal.weekly.repeat.title', {
                      day: () => (
                        <NumberInput
                          value={weeklyInterval}
                          onValueChange={setWeeklyInterval}
                          max={99}
                          min={1}
                          controls
                        />
                      )
                    } as any)}
                  </div>
                  <div>{t('features.AppointmentRecurrenceSetupModal.weekly.repeat.weekdays.title')}</div>
                  <div className={styles.weekDays}>
                    {weekDayOptions.map(({ label, value }, i) => {
                      const selected = weeklyDays.includes(value);
                      const onClick = () =>
                        setWeeklyDays((weekdays) => {
                          if (selected && weekdays.length > 1) {
                            return weekdays.filter((selectedDay) => selectedDay !== value);
                          }
                          if (!selected) {
                            return [...weekdays, value];
                          }
                          return weekdays;
                        });
                      return (
                        <Button
                          key={i}
                          type={selected ? 'primary' : 'default'}
                          shape="default"
                          icon={label[0]}
                          size={'middle'}
                          onClick={onClick}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className={styles.repeat}>
                  <Radio.Group onChange={onMonthlyModeChange} value={monthlyMode} className={styles.text}>
                    <Space direction="vertical" size={16}>
                      <Space className={styles.long}>
                        {t('features.AppointmentRecurrenceSetupModal.monthly.day.title', {
                          radio: (text: string) => <Radio value={'day'}>{text}</Radio>,
                          day: () => (
                            <>
                              <NumberInput value={day} onValueChange={setDay} min={1} max={31} />
                              {isMobile && <div className={styles.deviderDay} />}
                            </>
                          ),
                          month: () => (
                            <NumberInput value={monthlyInterval} onValueChange={setMonthlyInterval} min={1} max={99} controls />
                          )
                        } as any)}
                      </Space>
                      <Space className={styles.long}>
                        {t('features.AppointmentRecurrenceSetupModal.monthly.weekday.title', {
                          radio: (text: string) => <Radio value={'weekday'}>{text}</Radio>,
                          week: () => (
                            <Select
                              value={monthWeek}
                              onChange={setMonthWeek}
                              options={monthWeekOptions}
                              popupMatchSelectWidth={false}
                            />
                          ),
                          weekday: () => (
                            <>
                              <Select
                                value={dayKind}
                                onChange={setDayKind}
                                options={weekDayOptions}
                                popupMatchSelectWidth={false}
                              />
                              {isMobile && <div className={styles.devider} />}
                            </>
                          ),
                          month: () => (
                            <NumberInput value={monthlyWeekdayInterval} onValueChange={setMonthlyWeekdayInterval} min={1} max={99} controls />
                          )
                        } as any)}
                      </Space>
                    </Space>
                  </Radio.Group>
                </div>
              )}

              <div className={styles.ends}>
                <div>{t('features.AppointmentRecurrenceSetupModal.end.title')}</div>
                <Radio.Group onChange={(e) => setEnd(e.target.value)} value={end} className={styles.text}>
                  <Space direction="vertical">
                    {t('features.AppointmentRecurrenceSetupModal.end.option.never', {
                      radio: (text: string) => (
                        <Radio value={AppointmentSerieEndOptionEnum.NEVER} className={styles.item}>
                          {text}
                        </Radio>
                      )
                    } as any)}
                    <Space>
                      {t('features.AppointmentRecurrenceSetupModal.end.option.on', {
                        radio: (text: string) => <Radio value={AppointmentSerieEndOptionEnum.ON}>{text}</Radio>,
                        picker: (text: string) => (
                          <DatePicker
                            value={dateOn}
                            onChange={setDateOn}
                            disabledDate={disabledPastDate}
                            format={customDateFormat}
                            placeholder={text}
                            inputReadOnly
                          />
                        )
                      } as any)}
                    </Space>
                    <Space>
                      {t('features.AppointmentRecurrenceSetupModal.end.option.after', {
                        radio: (text: string) => <Radio value={AppointmentSerieEndOptionEnum.AFTER}>{text}</Radio>,
                        count: () => (
                          <NumberInput value={occurrences} onValueChange={setOccurrences} controls max={99} min={1} />
                        )
                      } as any)}
                    </Space>
                  </Space>
                </Radio.Group>
              </div>
              <div className={styles.actions}>
                <Button
                  block
                  type="primary"
                  className="buttonPrimary"
                  size="large"
                  onClick={okHandler}
                  disabled={doneDisabled}
                >
                  {t('features.AppointmentRecurrenceSetupModal.confirmBtn.text')}
                </Button>
                <Button type="primary" block className="buttonCancel" size="large" onClick={hideModal}>
                  {t('features.AppointmentRecurrenceSetupModal.cancelBtn.text')}
                </Button>
              </div>
            </div>
          </ConfigProvider>
          <AppointmentRecurrenceConfirmModal onOk={doneHandler} day={day} />
        </Modal>
      );
    },
    [Modal]
  );

  return {
    AppointmentRecurrenceSetupModal,
    showAppointmentRecurrenceSetupModal: showModal
  };
};
