
export const LANGS = process.env.REACT_APP_LOCALES?.split(',') || ([] as string[]);

export const localesTranslationsMap: Record<string, string> = {
  'en-Us': 'common.lang.en-Us',
  'en-Jm': 'common.lang.en-Jm',
  'fr-Ca': 'common.lang.fr-Ca',
};

export enum Lang {
  en = 'en',
  fr = 'fr'
}

export enum Locales {
  'en-Jm' = 'en-Jm',
  'fr-Ca' = 'fr-Ca',
  'en-Us' = 'en-Us',
}

