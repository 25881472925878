import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import  {ReactComponent as PlusIcon} from '../../../../assets/icons/plus.svg';
import { currentTheme } from '../../../../common/constants';

interface AvailabilityAddNewTimeSlotBtnProps {
  handleAddSlot: () => void;
}

export const AvailabilityAddNewTimeSlotBtn: FC<AvailabilityAddNewTimeSlotBtnProps> = ({ handleAddSlot }) => {
  const { t } = useTranslate();
  return (
    <div onClick={handleAddSlot} className={`add-slot-button slot-color-${currentTheme}`}>
      <PlusIcon className={`slot-color-${currentTheme}`} />
      {t('features.AvailabilityAddNewTimeSlotBtn.text')}
    </div>
  );
};
