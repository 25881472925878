import { Checkbox } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { Schedule } from '../../interfaces/schedule';
import style from '../AvailabilitySchedule/availability-schedule.module.scss';
import { tolgee } from '../../../../app/App';
import { Locale } from '../../../../localization/data';

interface AvailabilityDayCheckboxProps {
  record: Schedule;
  dayOfTheWeek: number;
  handleDayChange: (day: number, enabled: boolean) => void;
}

export const AvailabilityDayCheckbox: FC<AvailabilityDayCheckboxProps> = ({
  record,
  dayOfTheWeek,
  handleDayChange
}) => {
  const language = tolgee.getLanguage();
  dayjs.locale(language ? language : Locale.EN);
  return (
    <div onClick={(e) => e.stopPropagation()} className={style.dayCheckboxContainer}>
      <Checkbox
        className={classNames({ [style.checkboxDisabled]: !record.enabled })}
        checked={record.enabled}
        onChange={(e) => handleDayChange(dayOfTheWeek, e.target.checked)}
      >
        {formatDateWithDayJS(dayjs().day(dayOfTheWeek).toDate(), 'ddd').toUpperCase()}
      </Checkbox>
    </div>
  );
};
