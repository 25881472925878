import { T } from '@tolgee/react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useNavigationTabs } from '../../common/hooks/useNavigationTabs';

import { useAuthConfigSelector } from '../auth/store/authSelectors';
import { NotificationListCount } from '../notificationList/components';
import { useNotificationListSelector } from '../notificationList/store/notificationListSelectors';
import { useNotificationListActionCreators } from '../notificationList/store/notificationListSlice';
import { StickyTabBar, StickyTabBarProps } from '../ui';

export enum NavKeys {
  appointments = 'appointments',
  providers = 'providers',
  patients = 'patients',
  services = 'services',
  notifications = 'notifications',
  personalInformation = 'personal-information'
}

export enum ServiceKeys {
  langSwitch = 'langSwitch',
  layout = 'layout'
}

export const RoutedTabs = () => {
  const { user, token } = useAuthConfigSelector();
  const navigate = useNavigate();

  const data = useNotificationListSelector();
  const notificationActions = useNotificationListActionCreators();

  const config: StickyTabBarProps['items'] = [
    {
      label: <T keyName="features.RoutedTabs.appointments.name" />,
      key: NavKeys.appointments
    },
    {
      label: <T keyName="features.RoutedTabs.providers.name" />,
      key: NavKeys.providers
    },
    {
      label: <T keyName="features.RoutedTabs.patients.name" />,
      key: NavKeys.patients
    },
    {
      label: <T keyName="features.RoutedTabs.services.name" />,
      key: NavKeys.services
    },
    {
      label: <NotificationListCount count={data.unreadCount} />,
      key: NavKeys.notifications
    }
  ];

  const { tabs, activeKey } = useNavigationTabs(config);

  const onTabChange = useCallback((key: string) => {
    if (NavKeys[key as NavKeys & ServiceKeys]) {
      navigate(`/${key}`);
    }
  }, []);

  useEffect(() => {
    if (user && token) {
      notificationActions.updateUnreadAmount();
    }
  }, [user, token]);

  return (
    <>
      <StickyTabBar onChange={onTabChange} activeKey={activeKey} items={tabs} />
    </>
  );
};
