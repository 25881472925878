import { useTranslate } from '@tolgee/react';
import { Button, Modal } from 'antd';
import type { Dispatch, FC } from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { AppointmentCreatePatientList } from '../AppointmentCreatePatientList/AppointmentCreatePatientList';
import { AppointmentCreateProviderList } from '../AppointmentCreateProviderList/AppointmentCreateProviderList';
import styles from './appointment-create-add-user.module.scss';

interface Props {
  type: 'provider' | 'patient' | 'tester';
  setIsPopupOpen: Dispatch<boolean>;
  isPopupOpen: boolean;
  disabled: boolean;
  roles?: UserRoleEnum[];
  providerId?: number;
  patientId?: number;
}

export const AppointmentCreateAddUser: FC<Props> = ({
  disabled,
  isPopupOpen,
  setIsPopupOpen,
  type,
  patientId,
  providerId,
  roles
}) => {
  const { t } = useTranslate();
  const userType = type === 'patient' || type === 'tester' ? UserRoleEnum.User : UserRoleEnum.Provider;

  return (
    <>
      <Button type="default" className={styles.buttonOutlined} onClick={() => setIsPopupOpen(true)} disabled={disabled}>
        {t('features.AppointmentCreateAddUser.addBtn.text', { userRole: t(`common.userRolesTitles.${userType}.name`) })}
      </Button>
      <Modal
        width="auto"
        open={isPopupOpen}
        title={t('features.AppointmentCreateAddUser.selectMdl.title', {
          userRole: t(`common.userRolesTitles.${userType}.name`)
        })}
        onCancel={() => setIsPopupOpen(false)}
        footer={null}
      >
        {userType === UserRoleEnum.Provider ? (
          <AppointmentCreateProviderList
            isSelect
            roles={roles}
            patientId={patientId}
            includeRequester
            skipSavingFilters
          />
        ) : (
          <AppointmentCreatePatientList isSelect roles={roles} providerId={providerId} empty={type === 'tester'} />
        )}
      </Modal>
    </>
  );
};
