import styles from './appointment-join-notification-bar.module.scss';

interface NotificationBarProps {
  message?: string;
}

export const MeetingNotificationBar = ({ message }: NotificationBarProps) => {
  if (!message) {
    return null;
  }
  return (
    <div className={styles.notificationBar}>
      <p>{message}</p>
    </div>
  );
};
