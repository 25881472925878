import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { getFullName } from '../../../../common/utils/helpers';
import useModal from '../../../ui/Modal/useModal';
import { Appointment } from '../../interfaces/appointment';
import { getAttendeeByRole } from '../../utils/helpers';
import { AppointmentStatus } from '../AppointmentStatus/AppointmentStatus';
import { AppointmentType } from '../AppointmentType/AppointmentType';
import style from './appointment-action-modal.module.scss';

export const useAppointmentActionModal = (appointment?: Appointment) => {
  const { t } = useTranslate();
  const { Modal, hideModal, showModal } = useModal({});

  const AppointmentActionModal = useCallback(
    ({
      onOk,
      okLabel = t('features.AppointmentActionModal.okBtn.label'),
      okRef,
      onNo = () => {},
      title,
      message,
      noLabel = t('features.AppointmentActionModal.noBtn.label')
    }: {
      onOk: () => void;
      onNo?: () => void;
      title: string;
      message: string;
      okLabel?: string;
      noLabel?: string;
      okRef?: React.RefObject<HTMLButtonElement>;
    }) => {
      if (!appointment) return null;
      const patient = getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
      const tester = getAttendeeByRole(appointment.attendees, UserRoleEnum.Tester);
      const isPeerNavigatorAppointment = !!getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator);

      const okHandler = () => {
        onOk();
        hideModal();
      };

      const noHandler = () => {
        onNo();
        hideModal();
      };

      return (
        <Modal onCancel={noHandler}>
          <div className={style.modalContent}>
            <p className={style.title}>{title}</p>
            <p className={style.date}>{formatDateWithDayJS(appointment.startDatetime, 'MMMM DD, YYYY')}</p>
            <p className={style.time}>{formatDateWithDayJS(appointment.startDatetime, 'LT')}</p>
            <div className={style.statusLine}>
              <AppointmentType type={appointment.type} isPeerNavigatorAppointment={isPeerNavigatorAppointment} />
              <AppointmentStatus status={appointment.status} isPeerNavigatorAppointment={isPeerNavigatorAppointment} />
            </div>
            {patient && (
              <p>
                {t('common.patient.label')} -{' '}
                <Link to={`/patients/${patient.userId}`} className="userInfoName">
                  {getFullName(patient)}
                </Link>
              </p>
            )}
            {tester && (
              <p>
                {t('common.tester.label')} - <span className={style.testerName}>{tester.ssiId}</span>
              </p>
            )}
            <p className={style.infoMessage}>{message}</p>
            <div>
              <Button
                ref={okRef}
                type="primary"
                className={`${style.button} buttonPrimary`}
                size="large"
                onClick={okHandler}
              >
                {okLabel}
              </Button>
              <Button type="primary" className={`${style.button} buttonCancel`} size="large" onClick={noHandler}>
                {noLabel}
              </Button>
            </div>
          </div>
        </Modal>
      );
    },
    [Modal, appointment]
  );

  return {
    AppointmentActionModal,
    showAppointmentActionModal: showModal
  };
};
