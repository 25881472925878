import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const selector = (state: RootState) => state.profile;

const profileSelector = (state: RootState) => {
  const profile = selector(state);
  return profile;
};

export const useProfileSelector = () => {
  const state = useAppSelector(profileSelector);
  return state;
};
