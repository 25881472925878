export enum IncomingNotificationTypesEnum {
  START_CALL = 'start_call',
  UPCOMING_APPOINTMENT = 'upcoming_appointment',
  REQUESTED_BY_PATIENT = 'requested_by_patient',
  APPOINTMENT_CANCELED = 'appointment_canceled',
  REQUESTED_BY_PROVIDER = 'requested_by_provider',
  PENDING_CONFIRMATION = 'pending_confirmation',
  APPOINTMENT_CONFIRMATION = 'appointment_confirmation',
  AVAILABILITY_CHANGED = 'availability_changed',
  APPOINTMENT_COMPLETED = 'appointment_completed',
  REQUESTED_BY_TESTER = 'requested_by_tester',
  // APPOINTMENT_CANCELED_BY_TESTER = 'appointment_canceled_by_tester',
  STACK = 'stack'
}