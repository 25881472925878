import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Email } from '../../../../assets/icons/email.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone.svg';
import { StateStatus } from '../../../../common/enums/store';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { User } from '../../../../common/interfaces/user';
import { ConditionalWrapper } from '../../../ui/ConditionalWrapper/ConditionalWrapper';
import { useProfileSelector } from '../../store/profileSelectors';
import { useProfileActionCreators } from '../../store/profileSlice';
import { BIO_PREVIEW_LENGTH, getBioText } from '../../utils/getBioText';
import style from './profile-provider-contact-information.module.scss';

const DEFAULT_PATIENT_COUNT = 5;

export const ProfileProviderContactInformation: FC<{ user: User }> = ({user}) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { isTablet } = useWindowSizeType();
  const role = user.role.id || user.role;
  const providerId = user.id;
  const [showAllBio, setShowAllBio] = useState<boolean>(false);
  const profileActions = useProfileActionCreators();
  const { patientList, status, contactPersons } = useProfileSelector();
  const isLoadingPatientsList = status === StateStatus.LOADING;
  const showPatientsAndContactsRoles = [UserRoleEnum.Provider, UserRoleEnum.Admin];
  const isShowPatients = showPatientsAndContactsRoles.includes(role);

  const bio = getBioText(user.bio, showAllBio);

  useEffect(() => {
    if (isShowPatients) {
      profileActions.getPatientList({ page: 1, limit: DEFAULT_PATIENT_COUNT, providerId });
      profileActions.getContactPersons({ providerId });
    }
    return () => {
      profileActions.resetPatientsList();
      profileActions.resetContactPersons();
    };
  }, []);

  return (
    <div className={style.personalData}>
      <h4 className={style.blockTitle}>{t('features.ProfileProviderContactInformation.title')}</h4>
      <ConditionalWrapper
        condition={isTablet}
        wrapper={(children) => <div className={style.infoContainer}>{children}</div>}
      >
        <p className={style.line}>
          <span className={style.fieldTitle}>{t('common.providerData.id.label')}: </span>
          <span className={style.fieldValue}>{user.uniqueCode}</span>
        </p>
        <p className={style.line}>
          <span className={style.fieldTitle}>{t('common.providerData.email.label')}: </span>
          <span className={style.fieldValue}>{user.email}</span>
        </p>
        <p className={style.line}>
          <span className={style.fieldTitle}>{t('common.providerData.phone.label')}: </span>
          <span className={style.fieldValue}>{user.phone ? user.phone : ' -'}</span>
        </p>
        <p className={style.line}>
          <span className={style.fieldTitle}>{t('common.providerData.region.label')}: </span>
          <span className={style.fieldValue}>{user.region || ' -'}</span>
        </p>
        <p className={style.line}>
          <span className={style.fieldTitle}>{t('common.providerData.address.label')}: </span>
          <span className={style.fieldValue}>{user.address || ' -'}</span>
        </p>
      </ConditionalWrapper>
      <div className={style.divider} />
      <h4 className={style.blockTitle}>{t('common.providerData.bio.label')}:</h4>
      <p className={style.line}>
        <span className={style.fieldValue}>{bio}</span>
      </p>
      {user.bio && user.bio.length > BIO_PREVIEW_LENGTH && (
        <span className={style.seeMore} onClick={() => setShowAllBio(!showAllBio)}>
          {showAllBio ? t('features.ProfileProviderContactInformation.bioLess.text') : t('features.ProfileProviderContactInformation.bioMore.text')}
        </span>
      )}
      <div className={style.divider} />
      {contactPersons?.length ? (
        <>
          <h4 className={style.blockTitle}>{t('features.ProfileProviderContactInformation.contactPersons.text')}</h4>
          {contactPersons.map((item) => (
            <div key={item.id}>
              <h4 className={style.contactPersonType}>{item.clinicServiceTitle || item.clinicServiceType}:</h4>&nbsp;
              <span className={style.contactPersonName}>{item.firstName} {item.lastName}</span>
              <div className={style.contactInfo}>
                <Email className={style.emailIcon} /> {item.email}
              </div>
              { item.phone && <div className={style.contactInfo}>
                <Phone className={style.phoneIcon}/> {item.phone}
              </div> }
            </div>
          ))}
          <div className={style.divider} />
        </>
      ) : null }
      {isShowPatients && (
        <h4 className={style.blockTitle}>
          {t('features.ProfileProviderContactInformation.patientsBlock.title', { amount: patientList.count })}
        </h4>
      )}
      {isShowPatients && patientList.items?.length && !isLoadingPatientsList ? (
        <>
          <span className={style.line}>
            <ul className={classNames(style.fieldValue, style.patientList)}>
              {patientList.items.map((item) => (
                <li key={item.id} className={style.patientListItem}>
                  <Link to={`/patients/${item.id}`}>
                    {item.firstName} {item.lastName} - {item.email}
                  </Link>
                </li>
              ))}
            </ul>
          </span>
          {patientList.count > DEFAULT_PATIENT_COUNT && (
            <span
              className={style.seeMore}
              onClick={() =>
                navigate('/patients', {
                  state: { providerDetails: user }
                })
              }
            >
              {t('features.ProfileProviderContactInformation.morePatients.text')}
            </span>
          )}
        </>
      ) : null}
    </div>
  );
};
