import { useTranslate } from '@tolgee/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useErrorActionCreators } from '../../../../common/middlewares/listener';
import { Socket, SocketEvent, SocketService } from '../../../../common/services/socket/socket.service';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { getFullName } from '../../../../common/utils/helpers';
import { Appointment } from '../../../appointment/interfaces/appointment';
import { AppointmentDaoService } from '../../../appointment/services/AppointmentDaoService';
import { useAppointmentActionCreators } from '../../../appointment/store/appointmentSlice';
import { getAttendeeByRole } from '../../../appointment/utils/helpers';
import { Chat } from '../../../ui/Chat/Chat';
import { ChatMessage, ChatUser, SendFunction } from '../../../ui/Chat/interfaces';
import styles from './appointment-join-chat.module.scss';
import { isSelfSystemMessage } from './utils';

enum ChatEventsEnum {
  JOIN_CHAT_ROOM = 'join_chat_room',
  CHAT_ROOM_MESSAGE = 'chat_room_message',
  LEAVE_CHAT_ROOM = 'leave_chat_room',
  CONNECT = 'connect'
}

interface JoinChatRoomResponse {
  id?: string;
  messages?: ChatMessage[];
  users?: ChatUser[];
  error?: string;
}

type Props = {
  appointment: Appointment;
  close: () => void;
  isAdmin: boolean;
};

export const AppointmentJoinChat = ({ appointment, close, isAdmin }: Props) => {
  const { t } = useTranslate();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [users, setUsers] = useState<ChatUser[]>([]);
  const socketRef = useRef<Socket | null>(null);
  const connectionRef = useRef({
    Meeting: { MeetingId: '' },
    Attendee: { AttendeeId: '' }
  });
  const timersRef = useRef<Record<string, NodeJS.Timeout>>({});
  const appointmentActions = useAppointmentActionCreators();
  const errorActions = useErrorActionCreators();

  const patient = getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
  const patientName = getFullName(patient);
  const date = formatDateWithDayJS(appointment.startDatetime, 'LLL');

  const onSend = useCallback<SendFunction>((text, id) => {
    const message: ChatMessage = {
      _id: id || uuid(),
      text,
      createdAt: new Date()
    };

    const pendingMessage = { ...message, user: { _id: connectionRef.current.Attendee.AttendeeId }, pending: true };
    setMessages((msgs) => [...msgs.filter((msg) => msg._id !== message._id), pendingMessage]);

    socketRef.current?.emit(ChatEventsEnum.CHAT_ROOM_MESSAGE, message, (data: any) => {
      console.log(ChatEventsEnum.CHAT_ROOM_MESSAGE + ' SENT', data);
    });
    if (timersRef.current[message._id]) {
      clearTimeout(timersRef.current[message._id]);
    }
    timersRef.current[message._id] = setTimeout(() => {
      setMessages((msgs) =>
        msgs.map((msg) => {
          if (msg._id !== message._id) return msg;
          return { ...msg, pending: false, sent: false };
        })
      );
    }, 15000);
  }, []);

  useEffect(() => {
    const unmaskPatientForAdmin = (message: ChatMessage) =>
      isAdmin && message.user?.role === UserRoleEnum.User
        ? { ...message, user: { ...message.user, name: patientName } }
        : message;
    const messageListener = (message: ChatMessage | null) => {
      console.log(ChatEventsEnum.CHAT_ROOM_MESSAGE + ' GOT', message);
      if (message) {
        if (timersRef.current[message._id]) {
          clearTimeout(timersRef.current[message._id]);
          delete timersRef.current[message._id];
        }
        const userId = connectionRef.current.Attendee.AttendeeId;
        const isMySystemMessage = isSelfSystemMessage(message, userId);
        if (!isMySystemMessage) {
          setMessages((msgs) => [...msgs.filter((msg) => msg._id !== message._id), unmaskPatientForAdmin(message)]);
          if (message.system) {
            setUsers((attendees) => [
              ...attendees.filter((attendee) => attendee._id !== message.user!._id),
              ...(message.text.includes('join') ? [message.user!] : [])
            ]);
          }
        }
      }
    };
    const roomListener = (data: JoinChatRoomResponse) => {
      console.log(ChatEventsEnum.JOIN_CHAT_ROOM + ' SENT', data);
      if (data.error) {
        errorActions.error(new Error(data.error));
        close();
        return;
      }
      if (data.messages) {
        const userId = connectionRef.current.Attendee.AttendeeId;
        const newMessages = data.messages.filter((message) => !isSelfSystemMessage(message, userId)).map(unmaskPatientForAdmin);
        setMessages(msgs => {
          const pendingMessages = msgs.filter(msg => msg.pending && !newMessages.some(m => m._id === msg._id));
          const unsentMessages = msgs.filter(msg => msg.sent === false && !newMessages.some(m => m._id === msg._id));
          pendingMessages.forEach(msg => onSend(msg.text, msg._id));
          return [...newMessages, ...unsentMessages];
        });
      }
      if (data.users) {
        setUsers(data.users);
      }
    };
    const connectListener = () => {
      socketRef.current?.emit(ChatEventsEnum.JOIN_CHAT_ROOM, connectionRef.current, roomListener);
    };
    const events: SocketEvent[] = [
      {
        name: ChatEventsEnum.CHAT_ROOM_MESSAGE,
        listener: messageListener
      },
      {
        name: ChatEventsEnum.CONNECT,
        listener: connectListener
      }
    ];
    const join = async () => {
      try {
        const data = await AppointmentDaoService.connect(appointment.id);
        connectionRef.current.Attendee = data.attendeeResponse.Attendee;
        connectionRef.current.Meeting = data.meetingResponse.Meeting;
        socketRef.current = SocketService.connect(events);
      } catch (err) {
        errorActions.error(new Error(t('features.AppointmentJoinChat.errConnect')));
        appointmentActions.resetActiveChat();
      }
    };
    join();
    return () => {
      socketRef.current?.disconnect();
    };
  }, []);

  return (
    <Chat
      name={patientName}
      date={date}
      messages={messages}
      onClose={close}
      onSend={onSend}
      users={users}
      userId={connectionRef.current.Attendee.AttendeeId}
      className={isAdmin ? styles.containerAdmin : styles.containerProvider}
      loading={users.length === 0}
    />
  );
};

AppointmentJoinChat.adminTopOffset = parseInt(styles.adminTopOffset);
AppointmentJoinChat.providerTopOffset = parseInt(styles.providerTopOffset);
