import { Attendee } from '../../features/appointment/interfaces/attendee';
import { UserInfo } from '../../features/appointmentCreate/services/AppointmentCreateDaoService';

export const getFullName = (user?: { prefix?: string | null; firstName?: string | null; lastName?: string | null }) => {
  return [user?.prefix, user?.firstName, user?.lastName].filter(Boolean).join(' ');
};

export const userInfoToAttendees = (users: UserInfo[]): Attendee[] =>
  users.map((user) => ({
    ...user,
    id: '',
    userId: user.id,
    status: 0
  }));

export const unbreakString = (text = '') => text.replaceAll(/\s/g, '\u00A0');
