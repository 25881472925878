import { useTranslate } from '@tolgee/react';
import React from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { getAppointmentReviewer } from '../../../../common/helpers';
import { ActionResultCallback } from '../../../../common/hooks/useActionResultModals';
import { useUserSelector } from '../../../auth/store/authSelectors';
import { Button } from '../../../ui/index';
import { Appointment } from '../../interfaces/appointment';
import { ConfirmAppointmentParams } from '../../services/AppointmentDaoService';
import { useAppointmentActionCreators } from '../../store/appointmentSlice';
import { getAttendeeByRole } from '../../utils/helpers';
import { useAppointmentActionModal } from '../AppointmentActionModal/AppointmentActionModal';

interface Props {
  appointment: Appointment;
  title: string;
  onActionResponse: ActionResultCallback;
  class?: string;
  userId?: number;
}

export const AppointmentConfirmAction: React.FC<Props> = ({
  appointment,
  onActionResponse,
  title,
  class: className,
  userId
}) => {
  const { t } = useTranslate();
  const { AppointmentActionModal, showAppointmentActionModal } = useAppointmentActionModal(appointment);
  const appointmentActions = useAppointmentActionCreators();
  const user = useUserSelector();
  const provider =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Admin);
  const reviewer = getAppointmentReviewer({ user, provider });

  const confirm = async () => {
    const confirmParams: ConfirmAppointmentParams = {
      id: appointment.id,
      userId
    };

    const response = await appointmentActions.confirm(confirmParams);
    onActionResponse(response);
  };

  return (
    <>
      <Button type="primary" size="middle" className={className} onClick={showAppointmentActionModal}>
        {title}
      </Button>
      <AppointmentActionModal
        onOk={confirm}
        title={t('features.AppointmentConfirmAction.title')}
        message={t(`features.AppointmentConfirmAction.${reviewer}.message`)}
      />
    </>
  );
};
