import { SearchOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Input, List, theme } from 'antd';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { IBaseFilterProps } from '../../types';
import styles from './dropdown.module.scss';

const { useToken } = theme;

export const SearchSelect: FC<IBaseFilterProps> = ({ dataSource, filter, onChange, selected, fetchData, label }) => {
  const { token } = useToken();
  const [data, setData] = useState(dataSource);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const searchRef = useRef<string | undefined>();
  const focusRef = useRef(false);

  const containerStyle: CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadius,
    boxShadow: token.boxShadowSecondary,
    width: 320
  };

  const fetchListData = useCallback(async (name?: string) => {
    if (fetchData) {
      setLoading(true);
      searchRef.current = name;
      const items = await fetchData(name);
      if (searchRef.current === name) {
        setData(items);
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (dataSource.length === 0) {
      fetchListData();
    }
  }, []);

  const onInputChange = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      fetchListData(e.target.value);
    }, 500),
    []
  );

  return (
    <Dropdown
      open={open}
      trigger={['click']}
      dropdownRender={() => (
        <List
          dataSource={data}
          className={classNames(styles.list, styles.searchList)}
          style={containerStyle}
          renderItem={(item) => (
            <List.Item>
              <Checkbox
                value={item.key}
                key={item.key}
                checked={selected.some((select) => select.filterKey === filter.filterKey && select.key === item.key)}
                disabled={item.disabled}
                onChange={(e) => {
                  onChange({ ...filter, key: item.key, label: item.label, value: e.target.checked });
                }}
              >
                {item.label}
              </Checkbox>
            </List.Item>
          )}
          loading={loading}
        />
      )}
      onOpenChange={(opened) => {
        if (focusRef.current || opened) return;
        setOpen(false);
      }}
    >
      <div className={styles.search}>
        <Input
          placeholder={label}
          bordered={false}
          onChange={onInputChange}
          suffix={<SearchOutlined className={styles.searchIcon} />}
          allowClear
          onFocus={() => {
            focusRef.current = true;
            setOpen(true);
          }}
          onBlur={() => {
            focusRef.current = false;
          }}
        />
      </div>
    </Dropdown>
  );
};
