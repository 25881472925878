import { Dictionary } from '@reduxjs/toolkit';
import { Appointment, GroupedAppointments } from '../../features/appointment/interfaces/appointment';
import { Attendee } from '../../features/appointment/interfaces/attendee';
import { ClinicServiceEnum } from '../../features/appointmentCreate/enums/clinicService';
import { AppointmentReviewer } from '../enums/appointmentReviewerEnum';
import { UserRoleEnum } from '../enums/userRoleEnum';
import { User } from '../interfaces/user';
import { formatDate } from '../utils/dateFormat';

export type WithRequiredProp<Type, Key extends keyof Type> = Type & Required<Pick<Type, Key>>;

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

const deaultFroupByDateFormat = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
} as const;

const groupByDateReducer = (
  acc: GroupedAppointments,
  appointment: Appointment,
  format: Intl.DateTimeFormatOptions
): GroupedAppointments => {
  const date = formatDate(appointment.startDatetime, format);

  acc[date] = acc[date] || [];
  acc[date].push(appointment);

  return acc;
};

export const groupAppointmentsByDate = (
  appointments: Dictionary<Appointment>,
  format: Intl.DateTimeFormatOptions = deaultFroupByDateFormat
): GroupedAppointments => {
  const appointmentsList: Appointment[] = Object.values(appointments).filter(
    (appointment) => !!appointment
  ) as Appointment[];
  const grouped = appointmentsList.reduce((acc, curr) => groupByDateReducer(acc, curr, format), {});

  return grouped;
};

export const getAppointmentReviewer = ({
  provider,
  user
}: {
  provider: Attendee | undefined;
  user: User | null | undefined;
}): AppointmentReviewer => {
  if (provider?.clinicServiceType === ClinicServiceEnum.PeerNavigator) return AppointmentReviewer.peerNavigator;
  const isAdmin = user?.role.id === UserRoleEnum.Admin;
  if (!isAdmin) return AppointmentReviewer.provider;
  return provider?.userId === user?.id ? AppointmentReviewer.adminProvider : AppointmentReviewer.admin;
};
