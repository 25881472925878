import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { StateStatus } from '../../common/enums/store';
import { ValidateFormStatus } from '../../common/interfaces/validateFormStatus';
import { AuthChangePassword } from '../../features/auth/components/AuthChangePassword/AuthChangePassword';
import { ChangePasswordFormValues } from '../../features/auth/interfaces/authConfig';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { useAuthActionCreators } from '../../features/auth/store/authSlice';
import { Button } from '../../features/ui';
import styles from './change-password.module.scss';

interface FormStatus {
  validateStatus?: ValidateFormStatus;
  help: string | undefined;
}

const initialFormStatus: FormStatus = {
  validateStatus: undefined,
  help: undefined
};

export const ChangePasswordPage = () => {
  const { t } = useTranslate();
  const [formStatus, setFormStatus] = useState<FormStatus>(initialFormStatus);
  const authActions = useAuthActionCreators();
  const { status, oldPassword, error } = useAuthConfigSelector();

  useEffect(() => {
    status === StateStatus.ERROR &&
      setFormStatus({
        validateStatus: ValidateFormStatus.ERROR,
        help: 'message' in error ? error.message : t('pages.ChangePasswordPage.errPassword')
      });
  }, [status]);

  const onSubmit = (values: ChangePasswordFormValues) => {
    if (!formStatus.validateStatus) {
      authActions.changePassword(values);
    }
  };

  const onFinish = () => {
    authActions.completeChangePassword();
  };

  const onChange = () => {
    if (formStatus.validateStatus != null) {
      setFormStatus(initialFormStatus);
    }
  };

  return (
    <div className="centered-content">
      <div className="form-container">
        <h4>{t('pages.ChangePasswordPage.title')}</h4>
        {oldPassword ? (
          <AuthChangePassword
            onFinish={onSubmit}
            onChange={onChange}
            disabled={status === StateStatus.LOADING}
            loading={status === StateStatus.LOADING}
            validateStatus={formStatus.validateStatus}
            help={formStatus.help}
          />
        ) : (
          <>
            <p className={styles.description}>{t('pages.ChangePasswordPage.description')}</p>
            <Button type="primary" className="common-button" size="large" onClick={onFinish}>
              {t('pages.ChangePasswordPage.loginBtn.text')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
