import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const selector = (state: RootState) => state.assignedProviders;

const assignedProvidersSelector = (state: RootState) => {
  const assignedProviders = selector(state);
  return assignedProviders;
};

export const useAssignedProvidersSelector = () => {
  const state = useAppSelector(assignedProvidersSelector);
  return state;
};
