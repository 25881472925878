import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { Lang } from '../../../localization/constants';

export type SessionDetailsPayload = {
  lang: Lang,
}

const patchLanguage = ({lang}: SessionDetailsPayload) => {
  return TelehealthHttpService.url('/session').patch({lang});
};

export const SessionService = {
  patchLanguage,
};
