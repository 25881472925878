import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { AVAILABILITY_TAB_KEY } from '../../../../pages/home/HomePage';
import { ReactComponent as Contact } from '../../assets/icons/contact.svg';
import { NotificationItem } from '../../interfaces/notification';
import { useNotificationListActionCreators } from '../../store/notificationListSlice';
import { NotificationListItemType } from '../NotificationListItemType/NotificationListItemType';
import style from './notification-list-item.module.scss';

interface Props {
  notification: NotificationItem;
}

export const NotificationListAvailabilityItem: React.FC<Props> = (props) => {
  const { t } = useTranslate();
  const notificationActions = useNotificationListActionCreators();

  const { isDesktop } = useWindowSizeType();

  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);

  const getFullName = (firstName: string, lastName: string) => `${firstName} ${lastName}`;
  const getFormatedDate = (date: string) => formatDateWithDayJS(date, 'h:mm a, MMMM DD, YYYY ');

  const allNotifications = props.notification.notifications;
  const notificationDetails = allNotifications[0];
  const isRead = props.notification.isRead;
  const updatedBy = notificationDetails?.updatedBy;
  const updatedByName = getFullName(updatedBy?.firstName, updatedBy?.lastName);
  const formattedNotificationTime = getFormatedDate(props.notification.notificationTime);

  const toggleDetails = () => {
    if (!isRead) {
      notificationActions.markAsRead({ ids: allNotifications.map((n) => n.id), groupId: props.notification.groupId });
    }
    setIsDetailsOpen((prev) => !prev);
  };

  const availabilityLink = (
    <Link to={`/appointments?activeViewKey=${AVAILABILITY_TAB_KEY}`} className={style.availabilityLink}>
      {t('features.NotificationListAvailabilityItem.detailsLink.text')}
    </Link>
  );

  return (
    <div
      className={classNames({
        [style.notification]: true,
        [style.read]: isRead
      })}
    >
      <div className={style.generalInfo} onClick={toggleDetails}>
        <div className={style.unreadDotContainer}>
          <div
            className={classNames({
              [style.active]: true,
              [style.unreadDot]: true
            })}
          ></div>
        </div>

        <div className={style.title}>{t('features.NotificationListAvailabilityItem.title')}</div>
        <div className={style.availabilityInfo}>
          <Contact className={style.contactIcon} />
          {updatedByName}, {formattedNotificationTime}
        </div>
        <div className={style.actions}>
          {isDesktop && availabilityLink}
          <ArrowDown className={classNames('notification-icon', style.toggleDetails)} />
        </div>
      </div>
      {!isDesktop && (
        <div className={style.smallerScreenStatus}>
          <span></span>
          {availabilityLink}
        </div>
      )}
      <div className={style.time}>{formatDateWithDayJS(props.notification.notificationTime, 'h:mm a')}</div>
      {isDetailsOpen &&
        allNotifications.map((notification) => {
          return (
            <>
              <div className={style.divider}></div>
              <div className={style.details}>
                <div className={style.notificationLine}>
                  <NotificationListItemType type={notification.type} />
                  <span className={style.notificationBody}>
                    {t('features.NotificationListAvailabilityItem.body', {
                      body: notification.body,
                      updatedByName: getFullName(notification.updatedBy?.firstName, notification.updatedBy?.lastName),
                      time: getFormatedDate(notification.createdAt)
                    })}
                  </span>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};
