import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import { useCallback } from 'react';
import useModal from '..';
import { ReactComponent as Failed } from '../../../assets/icons/error.svg';
import styles from './modal.module.scss';

const useErrorModal = () => {
  const { t } = useTranslate();
  const { Modal, hideModal, showModal } = useModal({});

  const ErrorModal = useCallback(
    ({ type }: { type: 'overlaps' | 'error' }) => (
      <Modal>
        <div className={styles.icon}>
          <Failed />
        </div>
        {type === 'overlaps' ? (
          <>
            <p className={styles.title}>{t('features.ErrorModal.errOverlaps.title')}</p>
            <p className={styles.text}>{t('features.ErrorModal.errOverlaps.message')}</p>
          </>
        ) : (
          <>
            <p className={styles.title}>{t('features.ErrorModal.err.title')}</p>
            <p className={styles.text}>{t('features.ErrorModal.err.message')}</p>
          </>
        )}
        <div className={styles.buttonsBlock}>
          <Button type="primary" className={styles.buttonPrimary} onClick={hideModal}>
            {t('features.ErrorModal.confirmBtn.text')}
          </Button>
        </div>
      </Modal>
    ),
    [Modal]
  );

  return {
    ErrorModal,
    showErrorModal: showModal
  };
};

export default useErrorModal;
