export const BIO_PREVIEW_LENGTH = 500;

export const getBioText = (bio: string | null, showAll: boolean) => {
  if (!bio) {
    return '-';
  }
  if (!showAll && bio.length > BIO_PREVIEW_LENGTH) {
    return bio.substring(0, BIO_PREVIEW_LENGTH) + '...';
  }
  return bio;
};
