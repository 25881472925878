import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';
import { UserRoleEnum } from '../../../common/enums/userRoleEnum';
import  { TelehealthHttpService } from '../../../common/services/http/http.service';
import { buildQueryString } from '../../../common/utils/buildQueryString';
import { Appointment, SeriesConfig } from '../../appointment/interfaces/appointment';
import { ClinicServiceEnum } from '../enums/clinicService';

export interface GetUsersParams {
  page: number;
  limit: number;
  includeRequester?: boolean;
  [key: string]: number | number[] | string | boolean | undefined;
}

export interface UserListResponse {
  count: number;
  items: UserInfo[];
}

const create = (data: {
  startDatetime: string;
  endDatetime: string;
  type: AppointmentTypeEnum;
  userIds: number[];
  comment?: string;
  seriesConfig?: SeriesConfig;
}) => {
  return TelehealthHttpService.url('/appointment').post<Omit<Appointment, 'attendees'>>(data);
};

const getPatientsList = async (params: GetUsersParams) => {
  const queryString = buildQueryString(params);
  const response = await TelehealthHttpService.url(`/users/my-users?${queryString}`).get<UserListResponse>();
  return response;
};

const getUsers = async (params: GetUsersParams) => {
  const queryString = buildQueryString(params);
  const response = await TelehealthHttpService.url(`/users?${queryString}`).get<UserListResponse>();
  return response;
};

const getOverlaps = (data: { startDatetime: string; endDatetime: string; userIds: number[], seriesConfig?: SeriesConfig; }) => {
  return TelehealthHttpService.url('/appointment/overlaps').post<Appointment[]>(data);
};

export const AppointmentCreateDaoService = {
  getOverlaps,
  getPatientsList,
  getUsers,
  create
};

export interface ClinicServiceProvider {
  id: number;
  firstName: string | null;
  lastName: string | null;
  prefix: string;
}

export interface UserInfo {
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: null | string;
  bio: null | string;
  uniqueCode?: string | null;
  role: UserRoleEnum;
  assignedClinician?: ClinicServiceProvider;
  assignedCaseManager?: ClinicServiceProvider;
  assignedPsychologist?: ClinicServiceProvider;
  assignedContactInvestigator?: ClinicServiceProvider;
  lastVisitedPharmacist?: ClinicServiceProvider;
  clinicServiceType?: ClinicServiceEnum;
  clinicServiceTitle?: string;
  ssiId?: string;
  userId?: number;
}
