import { useTranslate } from '@tolgee/react';
import { Col, Pagination, Select } from 'antd';

interface TableFooterProps {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
  totalRecords: number;
}

export default function PatientsTableFooter<D>({
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  totalRecords
}: TableFooterProps) {
  const { t } = useTranslate();

  const selectOptions = [
    {
      value: 8,
      label: '8'
    },
    {
      value: 16,
      label: '16'
    },
    {
      value: 24,
      label: '24'
    },
    {
      value: 48,
      label: '48'
    }
  ];

  const handlePageSizeChange = (value: number) => {
    setCurrentPage(1);
    setPageSize(value);
  };

  return (
    <div className="tableFooter">
      <Col className="tableSelect">
        <span>{t('features.PatientsTableFooter.setSpan.text')}</span>
        <Select defaultValue={pageSize} onChange={handlePageSizeChange} options={selectOptions} />
        <span>{t('features.PatientsTableFooter.itemsPerPageSpan.text')}</span>
      </Col>
      <Col className="tablePagination">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          onChange={(page) => setCurrentPage(page)}
          total={totalRecords}
        />
      </Col>
    </div>
  );
}
