import { Tabs, TabsProps } from 'antd';
import { type FC } from 'react';
import styles from './tabs.module.scss';

export interface StickyTabBarProps extends TabsProps {}

export const StickyTabBar: FC<StickyTabBarProps> = (props) => {
  const items = props.items?.filter((item) => item.active) || [];
  return (<Tabs className={styles.tabsContainer} tabBarGutter={34} {...props} items={items} />);
};
