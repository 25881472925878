import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Next } from '../../../../assets/icons/next.svg';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { ActionResultCallback } from '../../../../common/hooks/useActionResultModals';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { getFullName } from '../../../../common/utils/helpers';
import { ClinicServiceEnum } from '../../../appointmentCreate/enums/clinicService';
import { ProfileAvatar } from '../../../profile/components/ProfileAvatar/ProfileAvatar';
import { AppointmentStatusEnum } from '../../enums/appointmentEnums';
import { Appointment } from '../../interfaces/appointment';
import { getAttendeeByRole } from '../../utils/helpers';
import { AppointmentActions } from '../AppointmentActions/AppointmentActions';
import { AppointmentStatus } from '../AppointmentStatus/AppointmentStatus';
import { AppointmentType } from '../AppointmentType/AppointmentType';
import style from './appointment-list-item.module.scss';
import { AppointmentTime } from './components/AppointmentTime';

interface Props {
  appointment: Appointment;
  onActionResponse: ActionResultCallback;
}

export const AppointmentListItemAdmin: React.FC<Props> = ({ appointment, onActionResponse }) => {
  const { t } = useTranslate();
  const patient = getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
  const provider =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Admin);
  const isTesterAppointment = provider?.clinicServiceType === ClinicServiceEnum.PeerNavigator;

  const providerName = getFullName(provider);
  const patientName = patient
    ? getFullName(patient)
    : getAttendeeByRole(appointment.attendees, UserRoleEnum.Tester)?.ssiId;

  const { isDesktop, isMobile } = useWindowSizeType();
  const avatarSize = isDesktop ? 40 : 58;

  return (
    <div
      className={classNames(style.appointmentItemAdmin, {
        [style.completed]: appointment.status === AppointmentStatusEnum.COMPLETED,
        [style.tester]: isTesterAppointment
      })}
    >
      <div className={style.info}>
        <ProfileAvatar uniqueCode={provider?.uniqueCode} size={avatarSize} className={style.avatar} />
        <div className={style.mainInfo}>
          <div className={style.attendeesInfo}>
            <span className={style.date}>
              {appointment.seriesConfig && (
                <span className={style.reccurent}>
                  <Next className={'rbc-event-type-icon'} />
                </span>
              )}
              <AppointmentTime start={appointment.startDatetime} end={appointment.endDatetime} />
            </span>
            {isMobile && (
              <div className={style.appointmentDetails}>
                <span className={style.status}>
                  <AppointmentType type={appointment.type} isPeerNavigatorAppointment={isTesterAppointment} />
                  <AppointmentStatus status={appointment.status} isPeerNavigatorAppointment={isTesterAppointment} />
                </span>
              </div>
            )}
            <span className={style.providerName}>
              <Link to={`/providers/${provider?.userId}`} className={`${style.name} ${style.accentText}`}>
                {providerName}
              </Link>
              {provider?.clinicServiceType ? ` - ${provider?.clinicServiceTitle || provider.clinicServiceType}` : ''}
            </span>
            <div className={style.patientLine}>
              {t('features.AppointmentListItemAdmin.patientSection.text') + ' '}
              {patient ? (
                <Link to={`/patients/${patient?.userId}`} className={style.accentText}>
                  {patientName}
                </Link>
              ) : (
                <span className={style.name}>{patientName}</span>
              )}
            </div>
          </div>
          {!isMobile && (
            <div className={style.appointmentDetails}>
              <span className={style.status}>
                <AppointmentType type={appointment.type} isPeerNavigatorAppointment={isTesterAppointment} />
                <AppointmentStatus status={appointment.status} isPeerNavigatorAppointment={isTesterAppointment} />
              </span>
            </div>
          )}
        </div>
      </div>
      <AppointmentActions appointment={appointment} onActionResponse={onActionResponse} />
    </div>
  );
};
