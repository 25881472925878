import { useTranslate } from '@tolgee/react';
import { Button, ConfigProvider, Radio, RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import { FC, useCallback, useState } from 'react';
import useModal from '../../../ui';
import styles from './appointment-recurrence.module.scss';
import { useTheme } from '../../../../common/providers/ThemeProvider';

export const useAppointmentRecurrenceCancelModal = () => {
  const { Modal, hideModal, showModal } = useModal({});

  const AppointmentRecurrenceCancelModal: FC<{ onOk: (series: boolean) => void }> = useCallback(
    ({ onOk }) => {
      const [series, setSeries] = useState(false);
      const { t } = useTranslate();
      const {colors} = useTheme();

      const onChange = (e: RadioChangeEvent) => {
        setSeries(e.target.value);
      };

      const cancelOptions = [
        { label: t('features.AppointmentRecurrenceCancelModal.option.occurrence'), value: false },
        { label: t('features.AppointmentRecurrenceCancelModal.option.series'), value: true }
      ];

      return (
        <Modal>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: colors.primary
              },
              components: {
                Radio: {
                  dotSize: 8
                }
              }
            }}
          >
            <p className={styles.title}>{t('features.AppointmentRecurrenceCancelModal.title')}</p>
            <Radio.Group onChange={onChange} value={series} options={cancelOptions} className={styles.group} />

            <div className={classNames(styles.content, styles.actions)}>
              <Button
                type="primary"
                className="buttonPrimary"
                block
                onClick={() => {
                  onOk(series);
                  hideModal();
                }}
                size="large"
              >
                {t('features.AppointmentRecurrenceCancelModal.confirmBtn.text')}
              </Button>
              <Button type="primary" className="buttonCancel" onClick={hideModal} size="large" block>
                {t('features.AppointmentRecurrenceCancelModal.cancelBtn.text')}
              </Button>
            </div>
          </ConfigProvider>
        </Modal>
      );
    },
    [Modal]
  );

  return {
    AppointmentRecurrenceCancelModal,
    showAppointmentRecurrenceCancelModal: showModal
  };
};
