import { useTranslate } from '@tolgee/react';
import { Button, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { FC, ReactNode } from 'react';
import { Navigate, ToolbarProps } from 'react-big-calendar';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrow-right.svg';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { View, Views } from '../../../calendar/interfaces';
import styles from '../styles/toolbar.module.scss';
import { CalendarEvent } from '../useCalendarEvent';
import ViewSelect from './ViewSelect';
import { useTheme } from '../../../../common/providers/ThemeProvider';

type ToolbarCustomProps = {
  switcher: ReactNode;
  startDate?: string;
  endDate?: string;
};

export const Toolbar: FC<ToolbarProps<CalendarEvent> & ToolbarCustomProps> = ({
  label,
  onNavigate,
  view,
  switcher,
  date,
  startDate,
  endDate
}) => {
  const { t } = useTranslate();
  const { isDesktop } = useWindowSizeType();
  const {colors} = useTheme();

  let prevDisabled = false;
  let nextDisabled = false;
  const firstDay = getFirstDay(date, view as View);
  const lastDay = getLastDay(date, view as View);

  if ((startDate || !endDate) && firstDay.isSameOrBefore(startDate, 'day')) {
    prevDisabled = true;
  }
  if (endDate && lastDay.isSameOrAfter(endDate, 'day')) {
    nextDisabled = true;
  }

  const inner = (
    <>
      <Button icon={<ArrowLeft />} type="link" onClick={() => onNavigate(Navigate.PREVIOUS)} disabled={prevDisabled} />
      <Button icon={<ArrowRight />} type="link" onClick={() => onNavigate(Navigate.NEXT)} disabled={nextDisabled} />
      <span className={styles.label}>{label}</span>
      <ViewSelect />
    </>
  );

  return (
    <ConfigProvider
      theme={{
        token: { colorLink: colors.primary },
        components: {
          Button: {
            fontWeight: 600
          }
        }
      }}
    >
      <div className={styles.toolbar}>
        <Button type="link" onClick={() => onNavigate(Navigate.TODAY)}>
          {t('features.EventCalendar.toolbar.today.text')}
        </Button>
        {isDesktop && inner}
        {switcher}
      </div>
      {!isDesktop && <div className={styles.toolbar}>{inner}</div>}
    </ConfigProvider>
  );
};

export const getToolbar = (customProps: ToolbarCustomProps) => {
  const ToolbarRender: FC<ToolbarProps<CalendarEvent>> = (props) => <Toolbar {...props} {...customProps} />;
  return ToolbarRender;
};

function getFirstDay(date: Date, view: View) {
  return dayjs(date).startOf(toUnit(view));
}

function getLastDay(date: Date, view: View) {
  return dayjs(date).endOf(toUnit(view));
}

function toUnit(view: View) {
  switch (view) {
    case Views.MONTH:
    case Views.WORK_MONTH:
      return 'month';
    case Views.WEEK:
    case Views.WORK_WEEK:
      return 'isoWeek';
    default:
      return 'day';
  }
}
