import { Select } from 'antd';
import { useEffect } from 'react';
import './filter-select.module.scss';

interface Props {
  options: { label: string | JSX.Element; value: string }[];
  filterKey: string;
  defaultValue?: string | string[];
  mode?: 'multiple' | 'tags';
  maxTagCount?: number;
  onChange: (filterKey: string, filterValue: string | string[]) => void;
}

export const FilterSelect = (props: Props) => {
  const { filterKey, onChange, options, defaultValue, mode, maxTagCount } = props;

  const handleChange = (value: string | string[]) => {
    onChange(filterKey, value);
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue);
    }
  }, []);

  return (
    <Select
      mode={mode}
      maxTagCount={maxTagCount}
      defaultValue={defaultValue}
      onChange={handleChange}
      style={{ width: 200 }}
      options={options}
      className="filter-select"
    />
  );
};
