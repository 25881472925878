import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { Patient } from '../../../../common/interfaces/patient';
import style from './profile-patient-contact-information.module.scss';

export const ProfilePatientContactInformation: FC<{ user: Patient }> = (props) => {
  const { t } = useTranslate();

  const isPatient = props.user.role === UserRoleEnum.User;
  const userId = isPatient ? props.user.uniqueCode : props.user.id;

  const { isDesktop } = useWindowSizeType();

  return (
    <div className={style.personalData}>
      {isDesktop && <h4 className={style.personalDataTitle}>{t('features.ProfilePatientContactInformation.title')}</h4>}
      <p>
        {t('common.patientData.id.label')}: <span>{userId}</span>
      </p>
      <p>
        {t('common.patientData.email.label')}: <span>{props.user.email}</span>
      </p>
      {props.user.phone && (
        <p>
          {t('common.patientData.phone.label')}: <span>{props.user.phone}</span>
        </p>
      )}
    </div>
  );
};
