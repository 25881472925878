import { ConfigProvider, Radio, type RadioChangeEvent } from 'antd';
import { FC, ReactNode } from 'react';
import styles from './radio.module.scss';
import { useTheme } from '../../../common/providers/ThemeProvider';

export type RadioButtonsProps = {
  options: { label: ReactNode; value: any; disabled?: boolean }[];
  onChange?: (value: any) => void;
  defaultValue?: any;
  disabled?: boolean;
};

export const RadioButtons: FC<RadioButtonsProps> = ({ options, defaultValue, onChange, disabled }) => {
  const {colors} = useTheme();
  const onValueChange = (e: RadioChangeEvent) => {
    onChange?.(e.target.value);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary,
          borderRadius: 2
        }
      }}
    >
      <Radio.Group
        onChange={onValueChange}
        defaultValue={defaultValue}
        style={{ marginTop: 0 }}
        size="large"
        options={options}
        optionType="button"
        rootClassName={styles.container}
        disabled={disabled}
      ></Radio.Group>
    </ConfigProvider>
  );
};
