import { Pagination as AntdPagination, ConfigProvider, PaginationProps } from 'antd';
import { useTheme } from '../../../common/providers/ThemeProvider';

export const Pagination = (props: PaginationProps) => {
  const {colors} = useTheme();
  return (
    <ConfigProvider
      componentSize="small"
      theme={{
        token: {
          colorPrimary: colors.textColor,
          fontWeightStrong: 400
        },
        components: {
          Pagination: {
            itemActiveBg: colors.primary
          }
        }
      }}
    >
      <AntdPagination {...props} />
    </ConfigProvider>
  );
};
