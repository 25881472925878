import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { ClinicService } from '../interfaces/clinicService';

const getList = (): Promise<ClinicService[]> => {
  return TelehealthHttpService.url('/clinic-service').get<ClinicService[]>();
};

export const ClinicServiceDaoService = {
  getList
};
