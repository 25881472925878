import { Dictionary, EntityId, PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { AppointmentListState } from '../../../common/interfaces/store';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { AppointmentStatusEnum } from '../enums/appointmentEnums';
import { Appointment } from '../interfaces/appointment';
import { appointmentThunks } from './appointmentActions';

export interface AppointmentListConfig {
  ids: EntityId[];
  entities: Dictionary<Appointment>;
  hasNextPage: boolean;
  chatAppointment?: Appointment | null;
  isListView?: boolean;
}

export const appointmentAdapter = createEntityAdapter<Appointment>();

const initialState: AppointmentListState = appointmentAdapter.getInitialState({
  hasNextPage: true,
  status: StateStatus.INIT,
  isListView: true
});

const slice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setActiveChat(state, action: PayloadAction<Appointment>) {
      state.chatAppointment = action.payload;
    },
    resetActiveChat(state) {
      state.chatAppointment = null;
    },
    toggleListView(state) {
      appointmentAdapter.removeAll(state);
      state.isListView = !state.isListView;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      appointmentActions.getList.fulfilled,
      setSliceSuccess((state, action) => {
        appointmentAdapter.setAll(state, action.payload.data);
        state.hasNextPage = action.payload.hasNextPage;
      })
    );
    builder.addCase(appointmentActions.getList.pending, setSliceLoading);
    builder.addCase(appointmentActions.getList.rejected, setSliceError);

    builder.addCase(
      appointmentActions.cancel.fulfilled,
      setSliceSuccess((state, action) => {
        if (typeof action.payload.seriesId === 'number') {
          state.ids.forEach(id => {
            const appointment = state.entities[id];
            if (appointment && appointment?.seriesConfig?.id === action.payload.seriesId) {
              appointment.status = AppointmentStatusEnum.CANCELED;
            }
          });
        } else {
          appointmentAdapter.updateOne(state, {
            id: action.payload.appointment.id,
            changes: action.payload.appointment
          });
        }
      })
    );
    builder.addCase(appointmentActions.cancel.rejected, setSliceError);

    builder.addCase(
      appointmentActions.confirm.fulfilled,
      setSliceSuccess((state, action) => {
        appointmentAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload
        });
      })
    );
    builder.addCase(appointmentActions.confirm.pending, setSliceLoading);
    builder.addCase(appointmentActions.confirm.rejected, setSliceError);
  }
});

export const appointmentReducer = slice.reducer;

export const appointmentActions = {
  ...slice.actions,
  ...appointmentThunks
};

export const useAppointmentActionCreators = () => {
  const actionCreators = useActionCreators(appointmentActions);
  return actionCreators;
};
