import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import { FC } from 'react';
import { useAddToCalendar } from '../../../../common/hooks/useAddToCalendar';
import { AppointmentStatusEnum } from '../../enums/appointmentEnums';
import { Appointment } from '../../interfaces/appointment';

interface Props {
  appointment: Appointment;
  class?: string;
}

export const AppointmentAddToCalendarAction: FC<Props> = ({ class: classname, appointment }) => {
  const { t } = useTranslate();
  const { addToCalendarButtonRef, onAddToCalendar } = useAddToCalendar(appointment);

  if (appointment.status === AppointmentStatusEnum.CANCELED) return null;
  
  return (
    <Button ref={addToCalendarButtonRef} type="default" className={`${classname} buttonOutlined`} onClick={onAddToCalendar}>
      {t('features.AppointmentAddToCalendarAction.addToCalendarBtn.text')}
    </Button>
  );
};
