import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { Patient } from '../../../common/interfaces/patient';
import { User } from '../../../common/interfaces/user';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { Lang } from '../../../localization/constants';
import { UserListResponse } from '../../appointmentCreate/services/AppointmentCreateDaoService';
import { profileThunks } from './profileActions';

export interface ProfileConfig {
  data: Patient;
  patientList: UserListResponse;
  contactPersons: Array<User>;
  language?: Lang;
  status: StateStatus;
}

const initialState: ProfileConfig = {
  data: {} as Patient,
  patientList: {
    count: 0,
    items: []
  },
  contactPersons: [],
  status: StateStatus.INIT
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    resetPatientsList: (state) => {
      state.patientList = initialState.patientList;
    },
    resetContactPersons: (state) => {
      state.contactPersons = initialState.contactPersons;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      profileActions.getPatientProfile.fulfilled,
      setSliceSuccess((state, action) => {
        state.data = action.payload;
      })
    );
    builder.addCase(profileActions.getPatientProfile.pending, setSliceLoading);
    builder.addCase(profileActions.getPatientProfile.rejected, setSliceError);

    builder.addCase(
      profileActions.getPatientList.fulfilled,
      setSliceSuccess((state, action) => {
        state.patientList = action.payload;
      })
    );
    builder.addCase(profileActions.getPatientList.pending, setSliceLoading);
    builder.addCase(profileActions.getPatientList.rejected, setSliceError);

    builder.addCase(
      profileActions.getContactPersons.fulfilled,
      setSliceSuccess((state, action) => {
        state.contactPersons = action.payload;
      })
    );
    builder.addCase(profileActions.getContactPersons.pending, setSliceLoading);
    builder.addCase(profileActions.getContactPersons.rejected, setSliceError);
  }
});

export const profileReducer = slice.reducer;

export const profileActions = {
  ...slice.actions,
  ...profileThunks
};

export const useProfileActionCreators = () => {
  const actionCreators = useActionCreators(profileActions);
  return actionCreators;
};
