import { atcb_action } from 'add-to-calendar-button';
import { useCallback, useRef } from 'react';
import { Appointment } from '../../features/appointment/interfaces/appointment';
import { getAttendeeByRole } from '../../features/appointment/utils/helpers';
import { UserRoleEnum } from '../enums/userRoleEnum';
import { formatDateWithDayJS } from '../utils/dateFormat';
import { getFullName } from '../utils/helpers';

const options: ('iCal' | 'Apple' | 'Google' | 'Microsoft365' | 'MicrosoftTeams' | 'Outlook.com' | 'Yahoo')[] = [
  'Google',
  'Outlook.com',
  'Apple'
];

export const useAddToCalendar = (appointment?: Appointment) => {
  const addToCalendarButtonRef = useRef<HTMLButtonElement>(null);

  const onAddToCalendar = useCallback(() => {
    if (!addToCalendarButtonRef.current || !appointment) return;
    const patient = getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
    const patientName = getFullName(patient);

    const config = {
      uid: appointment.id,
      name: `Appointment with ${patientName}, ${patient?.uniqueCode}`,
      description: appointment.comment,
      startDate: formatDateWithDayJS(appointment.startDatetime, 'YYYY-MM-DD'),
      startTime: formatDateWithDayJS(appointment.startDatetime, 'HH:mm'),
      endDate: formatDateWithDayJS(appointment.endDatetime, 'YYYY-MM-DD'),
      endTime: formatDateWithDayJS(appointment.endDatetime, 'HH:mm'),
      timeZone: 'currentBrowser',
      options,
      location: `${window.location.origin}/appointments/${appointment.id}`
    };
    atcb_action(config, addToCalendarButtonRef.current);
  }, [appointment]);
  
  return {
    addToCalendarButtonRef,
    onAddToCalendar
  };
};
