import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { NotificationListState } from '../../../common/interfaces/store';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { GetListResponse } from '../services/NotificationListDaoService';
import { notificationListThunks } from './notificationListActions';

export type NotificationListConfig = GetListResponse & { unreadCount: number; page: number };

const initialState: NotificationListState = {
  page: 1,
  unreadCount: 0,
  count: 0,
  notificationList: [],
  status: StateStatus.INIT
};

const slice = createSlice({
  name: 'notificationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      notificationListActions.getList.fulfilled,
      setSliceSuccess((state, action) => {
        state.notificationList = action.payload.notifications.notificationList;
        state.count = action.payload.notifications.count;
        state.page = action.payload.page;
      })
    );
    builder.addCase(notificationListActions.getList.pending, setSliceLoading);
    builder.addCase(notificationListActions.getList.rejected, setSliceError);

    builder.addCase(
      notificationListActions.markAsRead.fulfilled,
      setSliceSuccess((state, action) => {
        const groupId = action.payload.groupId;
        state.unreadCount = action.payload.amount;
        state.notificationList.forEach((item) => {
          if (item.groupId === groupId) {
            item.isRead = true;
          }
        });
      })
    );
    builder.addCase(notificationListActions.markAsRead.pending, setSliceLoading);
    builder.addCase(notificationListActions.markAsRead.rejected, setSliceError);

    builder.addCase(
      notificationListActions.updateUnreadAmount.fulfilled,
      setSliceSuccess((state, action) => {
        state.unreadCount = action.payload;
      })
    );
    builder.addCase(notificationListActions.updateUnreadAmount.pending, setSliceLoading);
    builder.addCase(notificationListActions.updateUnreadAmount.rejected, setSliceError);
  }
});

export const notificationListReducer = slice.reducer;

const notificationListActions = {
  ...slice.actions,
  ...notificationListThunks
};

export const useNotificationListActionCreators = () => {
  const actionCreators = useActionCreators(notificationListActions);
  return actionCreators;
};
