import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { ClinicService } from '../interfaces/clinicService';
import { clinicServiceThunks } from './clinicServiceActions';

export interface ClinicServiceConfig {
  data: ClinicService[];
  status: StateStatus;
}

const initialState: ClinicServiceConfig = {
  data: [] as ClinicService[],
  status: StateStatus.INIT
};

const slice = createSlice({
  name: 'clinicService',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      clinicServiceActions.getClinicServices.fulfilled,
      setSliceSuccess((state, action) => {
        state.data = action.payload;
      })
    );
    builder.addCase(clinicServiceActions.getClinicServices.pending, setSliceLoading);
    builder.addCase(clinicServiceActions.getClinicServices.rejected, setSliceError);
  }
});

export const clinicServiceReducer = slice.reducer;

export const clinicServiceActions = {
  ...slice.actions,
  ...clinicServiceThunks
};

export const useClinicServiceActionCreators = () => {
  const actionCreators = useActionCreators(clinicServiceActions);
  return actionCreators;
};
