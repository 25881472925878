import { useTranslate } from '@tolgee/react';
import { Form } from 'antd';
import type { FC, ReactNode } from 'react';
import { ValidateFormStatus } from '../../../../common/interfaces/validateFormStatus';
import { Button, Input } from '../../../ui';
import { LoginFormValues } from '../../interfaces/authConfig';
import styles from '../../styles/forms.module.scss';

interface LoginProps {
  onFinish: (values: LoginFormValues) => void;
  onChange: (changedValues: any, values: LoginFormValues) => void;
  validateStatus?: ValidateFormStatus;
  help?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const AuthLogin: FC<LoginProps> = ({ onFinish, onChange, validateStatus, help, disabled, loading }) => {
  const { t } = useTranslate();

  return (
    <Form
      data-testid="login-form"
      style={{ width: '100%' }}
      name="login"
      onFinish={onFinish}
      size="large"
      onValuesChange={onChange}
      disabled={disabled}
    >
      <Form.Item
        name="email"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: t('features.AuthLogin.emailInpt.errEmpty')
          }
        ]}
        validateStatus={validateStatus}
        validateFirst
      >
        <Input placeholder={t('features.AuthLogin.emailInpt.placeholder')} />
      </Form.Item>
      <Form.Item
        name="password"
        className={styles.formItem}
        rules={[{ required: true, message: t('features.AuthLogin.passwordInpt.errEmpty') }]}
        validateStatus={validateStatus}
        help={help}
        validateFirst
      >
        <Input placeholder={t('features.AuthLogin.passwordInpt.placeholder')} type="password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block className={styles.button} loading={loading}>
          {t('features.AuthLogin.loginBtn.text')}
        </Button>
      </Form.Item>
    </Form>
  );
};
