import { View, Views } from './interfaces';

export const getNextView = (view: View, showWeekends: boolean): View => {
  if (showWeekends) {
    switch (view) {
      case Views.WORK_WEEK:
        return Views.WEEK;
      case Views.WORK_MONTH:
        return Views.MONTH;
    }
  } else {
    switch (view) {
      case Views.WEEK:
        return Views.WORK_WEEK;
      case Views.MONTH:
        return Views.WORK_MONTH;
    }
  }
  return view;
};
