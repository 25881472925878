import { useTranslate } from '@tolgee/react';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAssignedProvidersSelector } from '../../store/assignedProvidersSelectors';
import { useAssignedProvidersCreators } from '../../store/assignedProvidersSlice';
import { AssignedProvidersList } from '../AssignedProvidersList/AssignedProvidersList';
import { AssignedProvidersListItem } from '../AssignedProvidersListItem/AssignedProvidersListItem';
import styles from './assigned-providers-container.module.scss';

export const AssignedProvidersContainer: FC = () => {
  const { t } = useTranslate();
  const params = useParams<{ id: string }>();
  const assignedProvidersActions = useAssignedProvidersCreators();
  const { status, assignedProviders, pharmacist, error } = useAssignedProvidersSelector();

  useEffect(() => {
    assignedProvidersActions.getAssignedProviders(params.id!);
  }, []);

  return (
    <div>
      <AssignedProvidersList providers={assignedProviders} status={status} error={error} />
      {pharmacist && (
        <>
          <p className={styles.lastVisitedLabel}>
            {t('features.AssignedProvidersContainer.lastPharmacistBlock.title')}
          </p>
          <AssignedProvidersListItem {...pharmacist} />
        </>
      )}
      {/* <div className={styles.bookProviderButtonWrapper}>
        <SoonTooltip>
          <Button className={`${styles.button} buttonOutlined`} size="large">
            {t('features.AssignedProvidersContainer.bookProviderBtn.text')}
          </Button>
        </SoonTooltip>
      </div> */}
    </div>
  );
};
