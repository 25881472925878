import { FC } from 'react';
import AvailabilityIcon from '../../assets/icons/availability.svg';
import CanceledIcon from '../../assets/icons/canceled.svg';
import CompletedIcon from '../../assets/icons/completed.svg';
import ConfirmedIcon from '../../assets/icons/confirmed.svg';
import PendingIcon from '../../assets/icons/pending-confirmation.svg';
import RequestedFromPatientIcon from '../../assets/icons/requested-from-parient.svg';
import RequestedFromProviderIcon from '../../assets/icons/requested-from-provider.svg';
import StartIcon from '../../assets/icons/start.svg';
import UpcomingIcon from '../../assets/icons/upcoming-appointment.svg';
import { NotificationTypesEnum } from '../../enums/notification-types';

interface Props {
  type: string;
}

export const NotificationListItemType: FC<Props> = ({ type }) => {
  return (
    <>
      {NotificationTypesEnum.REQUESTED_BY_PROVIDER === type && <img src={RequestedFromProviderIcon} />}
      {NotificationTypesEnum.REQUESTED_BY_PATIENT === type && <img src={RequestedFromPatientIcon} />}
      {NotificationTypesEnum.REQUESTED_BY_TESTER === type && <img src={RequestedFromPatientIcon} />}
      {NotificationTypesEnum.APPOINTMENT_CONFIRMED === type && <img src={ConfirmedIcon} />}
      {NotificationTypesEnum.START_CALL === type && <img src={StartIcon} />}
      {NotificationTypesEnum.APPOINTMENT_CANCELED === type && <img src={CanceledIcon} />}
      {NotificationTypesEnum.PENDING_CONFIRMATION === type && <img src={PendingIcon} />}
      {NotificationTypesEnum.UPCOMING_APPOINTMENT === type && <img src={UpcomingIcon} />}
      {NotificationTypesEnum.APPOINTMENT_COMPLETED === type && <img src={CompletedIcon} />}
      {NotificationTypesEnum.AVAILABILITY_CHANGED === type && <img src={AvailabilityIcon} />}
    </>
  );
};
