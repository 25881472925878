import { T } from '@tolgee/react';
import * as yup from 'yup';
import { enumsRegions } from '../../../localization/data';
import { LATIN_NUMBERS_WITH_SPECIFIC_SYMBOLS_REGEX } from '../../../common/constants';
import { FeatureFlags } from '../../../config/featureFlags';

const NAME_REGEX = /^[a-zA-Z]*[a-zA-Z][a-zA-Z][a-zA-Z .,-]*$/;
const LATIN_AND_SPECIAL_SYMBOLS_REGEX = /^[A-Za-z.,!@£$%^&*()_\-+=§±`~:;'"\\|/'><*\s]*$/;
const LATIN_AND_NUMBERS_REGEX = /^[A-Za-z0-9.,!@£$%^&*()_\-+=§±`~:;'"\\|/'><*\s]*$/;
const NUMBERS_REGEX = /^([0-9]{7}|)$/;
const EMAIL_REGEX = /^\S+@\S+\.[a-zA-Z]{2,}$/;
const INVALID_EMAIL_MESSAGE = <T keyName='features.ProfileProvider.errInvalidEmail' /> as unknown as string;
const ENTER_EMAIL_MESSAGE = <T keyName='features.ProfileProvider.errEmptyEmail' /> as unknown as string;
const ENTER_FIRST_NAME_MESSAGE = <T keyName='features.ProfileProvider.errEmptyFirstName' /> as unknown as string;
const INVALID_FIRST_NAME_MESSAGE = <T keyName='features.ProfileProvider.errInvalidFirstName' /> as unknown as string;
const ENTER_LAST_NAME_MESSAGE = <T keyName='features.ProfileProvider.errEmptyLastName' /> as unknown as string;
const INVALID_LAST_NAME_MESSAGE = <T keyName='features.ProfileProvider.errInvalidLastName' /> as unknown as string;
export const ENTER_VALID_PHONE_MESSAGE = <T keyName='features.ProfileProvider.errInvalidPhone' /> as unknown as string;
const ONLY_LATIN_AND_SPECIAL_SYMBOLS_MESSAGE = <T keyName='features.ProfileProvider.errLatin&Special' /> as unknown as string;
const ONLY_LATIN_NUMBERS_AND_SPECIAL_SYMBOLS_MESSAGE = <T keyName='features.ProfileProvider.errLatin&Numbers' /> as unknown as string;

export const MAX_NAME_SYMBOLS = 20;
export const MAX_SURNAME_SYMBOLS = 40;
export const MAX_PREFIX_SYMBOLS = 6;
export const MAX_50_SYMBOLS = 50;
export const MAX_60_SYMBOLS = 60;
export const MAX_BIO_SYMBOLS = 1000;
export const REGION_OPTIONS = Object.values(enumsRegions[FeatureFlags.REACT_APP_CLIENT])
  .reduce((result, value) => {
    result.push({
      value: value,
      label: value
    });

    return result;
  }, [{
    value: '',
    label: <T keyName='common.select.noneOption.label' /> as unknown as string,
  }]);

const getMaximumLimitMessage = (limit: number) => <T keyName='features.ProfileProvider.errMaxSymbols' params={{ limit }} /> as unknown as string;
export const profileProviderValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(ENTER_FIRST_NAME_MESSAGE)
    .max(MAX_NAME_SYMBOLS, getMaximumLimitMessage(MAX_NAME_SYMBOLS))
    .matches(LATIN_AND_SPECIAL_SYMBOLS_REGEX, {message: ONLY_LATIN_AND_SPECIAL_SYMBOLS_MESSAGE })
    .matches(NAME_REGEX, {message:INVALID_FIRST_NAME_MESSAGE}),
  lastName: yup
    .string()
    .required(ENTER_LAST_NAME_MESSAGE)
    .max(MAX_SURNAME_SYMBOLS, getMaximumLimitMessage(MAX_SURNAME_SYMBOLS))
    .matches(LATIN_AND_SPECIAL_SYMBOLS_REGEX, {message:ONLY_LATIN_AND_SPECIAL_SYMBOLS_MESSAGE})
    .matches(NAME_REGEX, {message:INVALID_LAST_NAME_MESSAGE}),
  typeOfProvider:  yup
    .string()
    .nullable()
    .matches(LATIN_AND_NUMBERS_REGEX, {message:ONLY_LATIN_NUMBERS_AND_SPECIAL_SYMBOLS_MESSAGE})
    .max(MAX_60_SYMBOLS, getMaximumLimitMessage(MAX_60_SYMBOLS)),
  email: yup
    .string()
    .required(ENTER_EMAIL_MESSAGE)
    .max(MAX_50_SYMBOLS, getMaximumLimitMessage(MAX_50_SYMBOLS))
    .matches(EMAIL_REGEX, {message:INVALID_EMAIL_MESSAGE})
    .email(INVALID_EMAIL_MESSAGE),
  prefix: yup.string().nullable(),
  phone: yup.string().nullable(),
  region: yup.string().nullable(),
  address: yup
    .string()
    .nullable()
    .matches(LATIN_AND_NUMBERS_REGEX, {message:ONLY_LATIN_NUMBERS_AND_SPECIAL_SYMBOLS_MESSAGE})
    .max(MAX_60_SYMBOLS, getMaximumLimitMessage(MAX_60_SYMBOLS)),
  company: yup
    .string()
    .nullable()
    .matches(LATIN_AND_NUMBERS_REGEX, {message:ONLY_LATIN_NUMBERS_AND_SPECIAL_SYMBOLS_MESSAGE})
    .max(MAX_60_SYMBOLS, getMaximumLimitMessage(MAX_60_SYMBOLS)),
  bio: yup
    .string()
    .nullable()
    .matches(LATIN_NUMBERS_WITH_SPECIFIC_SYMBOLS_REGEX, {message:ONLY_LATIN_NUMBERS_AND_SPECIAL_SYMBOLS_MESSAGE})
    .max(MAX_BIO_SYMBOLS, getMaximumLimitMessage(MAX_BIO_SYMBOLS))
});
