import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useActionCreators } from '../../../common/hooks/store';
import { View, Views } from '../interfaces';
import { getNextView } from '../utils';

export interface CalendarState {
  showWeekends: boolean;
  view: View;
  date?: Date | string | undefined;
}

const initialState: CalendarState = {
  showWeekends: true,
  view: Views.MONTH,
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    changeDate(state, action: PayloadAction<Date | string | undefined>) {
      state.date = action.payload;
    },
    changeView(state, action: PayloadAction<View>) {
      state.view = getNextView(action.payload, state.showWeekends);
    },
    toggleShowWeekends(state) {
      state.showWeekends = !state.showWeekends;
      state.view = getNextView(state.view, state.showWeekends);
    },
  },
});

export const calendarReducer = slice.reducer;

export const useCalendarActionCreators = () => {
  const actionCreators = useActionCreators(slice.actions);
  return actionCreators;
};
