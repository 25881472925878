import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { ActionResultCallback } from '../../../../common/hooks/useActionResultModals';
import { ConfirmAsEnum, useConfirm } from '../../../../common/hooks/useConfirm';
import { Button } from '../../../ui/index';
import { AppointmentStatusEnum } from '../../enums/appointmentEnums';
import { Appointment } from '../../interfaces/appointment';
import { getAttendeeByRole } from '../../utils/helpers';
import { AppointmentCancelAction } from './AppointmentCancelAction';
import { AppointmentConfirmAction } from './AppointmentConfirmAction';
import { AppointmentJoinAction } from './AppointmentJoinAction';
import style from './appointment-actions.module.scss';
import { currentTheme } from '../../../../common/constants';

interface Props {
  appointment: Appointment;
  onActionResponse: ActionResultCallback;
}

export const AppointmentActions: React.FC<Props> = ({
  appointment,
  onActionResponse
}) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const goToDetails = () => navigate(`/appointments/${appointment.id}`);

  const shouldConfirm = useConfirm(appointment);
  const isJoinBtnActive =
    appointment.status === AppointmentStatusEnum.CONFIRMED || appointment.status === AppointmentStatusEnum.ACTIVE;

  return (
    <div className={style.actions}>
      {isJoinBtnActive && (
        <AppointmentJoinAction
          appointment={appointment}
          title={t('common.joinBtn.label')}
          class={classNames(style.button, 'buttonPrimary')}
        />
      )}
      {shouldConfirm === ConfirmAsEnum.attendee ? (
        <AppointmentConfirmAction
          appointment={appointment}
          title={t('features.AppointmentActions.confirmBtn.label')}
          onActionResponse={onActionResponse}
          class={`${style.button} buttonSuccessOutlined`}
        />
      ) : shouldConfirm === ConfirmAsEnum.provider ? (
        <AppointmentConfirmAction
          appointment={appointment}
          title={t('common.confirmAsProviderBtn.label')}
          onActionResponse={onActionResponse}
          class={`${style.button} buttonSuccessOutlined`}
          userId={getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider)?.userId}
        />
      ) : shouldConfirm === ConfirmAsEnum.peerNavigator ? (
        <AppointmentConfirmAction
          appointment={appointment}
          title={t('common.confirmAsNavigatorBtn.label')}
          onActionResponse={onActionResponse}
          class={`${style.button} buttonSuccessOutlined`}
          userId={getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator)?.userId}
        />
      ) : shouldConfirm === ConfirmAsEnum.patient ? (
        <AppointmentConfirmAction
          appointment={appointment}
          title={t('common.confirmAsPatientBtn.label')}
          onActionResponse={onActionResponse}
          class={`${style.button} buttonSuccessOutlined`}
          userId={getAttendeeByRole(appointment.attendees, UserRoleEnum.User)?.userId}
        />
      ) : null}
      <Button type="primary" size="middle" className={`${style.button} buttonOutlined buttonView${currentTheme}`} onClick={goToDetails}>
        {t('common.viewBtn.label')}
      </Button>
      <AppointmentCancelAction
        title={t('features.AppointmentActions.cancelBtn.label')}
        onActionResponse={onActionResponse}
        class={`${style.button} buttonOutlined buttonCancel${currentTheme}`}
        appointment={appointment}
      />
    </div>
  );
};
