import { useTranslate } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../features/ui';
import styles from './error.module.scss';

export const ErrorPage = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  return (
    <div className="centered-content">
      <div className={styles.container}>
        <h4>{t('pages.ErrorPage.errAccessDenied.title')}</h4>
        <p>{t('pages.ErrorPage.errAccessDenied.subTitle')}</p>
        <p>{t('pages.ErrorPage.errAccessDenied.description')}</p>
        <Button
          type="primary"
          className="common-button"
          size="large"
          onClick={() => {
            navigate('/', { replace: true });
          }}
        >
          {t('common.goHomeBtn.text')}
        </Button>
      </div>
    </div>
  );
};
