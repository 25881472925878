import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSizeType } from '../../common/hooks/useWindowSizeType';
import { useAuthActionCreators } from '../../features/auth/store/authSlice';
import { ProfileProvider } from '../../features/profile/components/ProfileProvider/ProfileProvider';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';

export const PersonalInformationPage = () => {
  const authActions = useAuthActionCreators();
  const { user } = useAuthConfigSelector();

  const { isDesktop } = useWindowSizeType();
  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktop) {
      navigate('/appointments');
    }
  }, [isDesktop]);

  return user ? <ProfileProvider endAction={authActions.getUser} showEditIcon user={user} isMyProfile /> : null;
};
