import { useEffect, useRef, useState } from 'react';
import * as deviceDetect from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';
import styles from './mail-redirect.module.scss';

const isTablet = deviceDetect.isTablet;
const isMobile = deviceDetect.isMobile;
const isDesktop = !isTablet && !isMobile;
const os = deviceDetect.osName.toLowerCase();
const isApple = os === 'mac os' || os === 'ios';
const isAndroid = os === 'android';

// http://localhost:3000/proxy/email-redirect?appointmentId=16b03da4-9b3b-49af-954e-f3bcf97c5ef9
export const MailRedirect = () => {
  const [wasAppOpened, setWasAppOpened] = useState<boolean | null>(null);
  const [searchParams] = useSearchParams();
  const buttonRef = useRef<HTMLButtonElement>(null);
  let timeout: NodeJS.Timeout | null = null;
  const appointmentId = searchParams.get('appointmentId');
  const link = `telehealth://appointment-details/${appointmentId}`;

  const redirect = () => {
    window.location.replace(link);
    timeout = setTimeout(() => {
      setWasAppOpened(false);
    }, 1000);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', (event) => {
      if (!document.hidden) {
        setWasAppOpened(true);
        timeout && clearTimeout(timeout);
      }
    });
  }, []);

  useEffect(() => {
    if (appointmentId && (isMobile || isTablet)) {
      if (isApple) {
        redirect();
      }
      if (isAndroid) {
        buttonRef.current?.addEventListener('click', redirect);
        buttonRef.current?.click();
      }
    }
    return () => {
      buttonRef.current?.removeEventListener('click', redirect);
    };
  }, [appointmentId]);

  const AppStoreLink = () => <a href='https://www.apple.com/app-store/' onClick={() => setWasAppOpened(false)}>App store</a>;
  const PlayMarketLink = () => <a href='https://play.google.com/store/apps' onClick={() => setWasAppOpened(false)}>Play market</a>;

  return (
    <div className={styles.container}>
      <div className={styles.linesContainer}>
        <div className={styles.blueLine}></div>
        <div className={styles.yellowLine}></div>
      </div>
      <div className={styles.content}>
        {isDesktop ? (
          <>
            <p>Appointment details could not be open.</p>
            <p>Please follow the link from your email on your mobile device or open appointment details directly from the app.</p>
          </>
        ): ''}
        <p>
          {typeof wasAppOpened === 'boolean' && !wasAppOpened && !isDesktop ? 
            'If you do not have the app installed on this device, please, folow the link: ' : ''}
          {!isDesktop && !wasAppOpened && isApple ? <AppStoreLink /> : ''}
          {!isDesktop && !wasAppOpened && isAndroid ? <PlayMarketLink /> : ''}

          {wasAppOpened && !isDesktop ? 'Please refresh the page to open the app' : ''}
        </p>
      </div>
      <div className={styles.linesContainer}>
        <div className={styles.yellowLine}></div>
        <div className={styles.blueLine}></div>
      </div>
      <button className={styles.hidden} ref={buttonRef}></button>
    </div>
  );
};