import { ClockCircleOutlined } from '@ant-design/icons';
import { T, useTranslate } from '@tolgee/react';
import { Button, Checkbox, DatePicker, Input, Space, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { customDateFormat } from '../../common/constants';
import { AppointmentTypeEnum } from '../../common/enums/appointmentTypeEnum';
import { StateStatus } from '../../common/enums/store';
import { UserRoleEnum } from '../../common/enums/userRoleEnum';
import { useAddToCalendar } from '../../common/hooks/useAddToCalendar';
import { useWindowSizeType } from '../../common/hooks/useWindowSizeType';
import { disabledPastDate, getDuration, getLTFormat, getLimitedNow } from '../../common/utils/dateFormat';
import { useAppointmentActionModal } from '../../features/appointment/components/AppointmentActionModal/AppointmentActionModal';
import { AppointmentRecurrenceDescription } from '../../features/appointment/components/AppointmentRecurrence/AppointmentRecurrenceDescription';
import { useAppointmentRecurrenceSetupModal } from '../../features/appointment/components/AppointmentRecurrence/AppointmentRecurrenceSetupModal';
import { SeriesConfig } from '../../features/appointment/interfaces/appointment';
import { AppointmentCreateAddUser, AppointmentCreateSelectedUser } from '../../features/appointmentCreate/components';
import { ClinicServiceEnum } from '../../features/appointmentCreate/enums/clinicService';
import { UserInfo } from '../../features/appointmentCreate/services/AppointmentCreateDaoService';
import { useAppointmentCreateSelector } from '../../features/appointmentCreate/store/appointmentCreateSelectors';
import { useAppointmentCreateActionCreators } from '../../features/appointmentCreate/store/appointmentCreateSlice';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { BackButton, RadioButtons } from '../../features/ui';
import useConfirmModal from '../../features/ui/Modal/useConfirmModal';
import useErrorModal from '../../features/ui/Modal/useErrorModal';
import { RadioButtonsProps } from '../../features/ui/Radio/RadioButtons';
import styles from './appointment-create.module.scss';

const options: RadioButtonsProps['options'] = [
  {
    label: <T keyName="pages.AppointmentCreatePage.contactMethod.video" />,
    value: AppointmentTypeEnum.video
  },
  {
    label: <T keyName="pages.AppointmentCreatePage.contactMethod.audio" />,
    value: AppointmentTypeEnum.audio
  },
  {
    label: <T keyName="pages.AppointmentCreatePage.contactMethod.chat" />,
    value: AppointmentTypeEnum.text
  },
  {
    label: <T keyName="common.appointmentContactMethod.hologram.text" />,
    value: AppointmentTypeEnum.hologram
  },
];

const MAX_COMMENT_LENGTH = 500;

const { TextArea } = Input;

export const AppointmentCreatePage = () => {
  const { t } = useTranslate();

  const { user } = useAuthConfigSelector();
  const [date, setDate] = useState<Dayjs | null>();
  const [startTime, setStartTime] = useState<Dayjs | null>();
  const [endTime, setEndTime] = useState<Dayjs | null>();
  const [method, setMethod] = useState<AppointmentTypeEnum>(() => options[0].value);
  const [comment, setComment] = useState<string>('');
  const [isCommentError, setIsCommentError] = useState(false);
  const [isPatientsModalOpen, setIsPatientsModalOpen] = useState(false);
  const [isProvidersModalOpen, setIsProvidersModalOpen] = useState(false);
  const [seriesConfig, setSeriesConfig] = useState<SeriesConfig | undefined>();

  const location = useLocation();
  const navigate = useNavigate();
  const navigateToPreviousOrAppointmentsPage = () => {
    if (location.key === 'default') {
      navigate('/appointments');
    } else {
      navigate(-1);
    }
  };

  const appointmentCreateActions = useAppointmentCreateActionCreators();
  const { status, error, patients, providers, appointment } = useAppointmentCreateSelector();

  const { ErrorModal, showErrorModal } = useErrorModal();
  const { AppointmentActionModal, showAppointmentActionModal } = useAppointmentActionModal(appointment);
  const { addToCalendarButtonRef, onAddToCalendar } = useAddToCalendar(appointment);
  const { AppointmentRecurrenceSetupModal, showAppointmentRecurrenceSetupModal } = useAppointmentRecurrenceSetupModal();
  const onChangeRecurrent = seriesConfig ? () => setSeriesConfig(undefined) : showAppointmentRecurrenceSetupModal;

  const userRole = user?.role.id;
  const isAdmin = userRole === UserRoleEnum.Admin;
  const patient: UserInfo | undefined = patients[0];
  const provider: UserInfo | undefined = providers[0];
  const isMethodChanged = method !== options[0].value;
  const isDataChanged = [date, startTime, endTime, comment, patient, provider, seriesConfig].some((item) => !!item);
  const { ConfirmModal } = useConfirmModal((isMethodChanged || isDataChanged) && !appointment);
  const createPatientListRoles = [UserRoleEnum.User];
  const createPatientListProviderId = provider?.id;
  const createProviderListRoles = !patient ? [UserRoleEnum.Provider, UserRoleEnum.PeerNavigator] : undefined;
  const createProviderListPatientId = patient?.id;

  const allDisabled = status === StateStatus.LOADING || status === StateStatus.SUCCESS;
  const isAllSelectedAcceptProvider = method && date && startTime && endTime && patient;
  const isAllSelected = isAllSelectedAcceptProvider && provider;
  const isImportantFieldsSelected = userRole === UserRoleEnum.Provider ? isAllSelectedAcceptProvider : isAllSelected;
  const saveDisabled = allDisabled || !isImportantFieldsSelected || isCommentError;

  const { isDesktop } = useWindowSizeType();

  const onSave = () => {
    if (date!.isBefore(dayjs(), 'days')) {
      setDate(undefined);
      return;
    }
    const startDate = dayjs(date).hour(startTime!.hour()).minute(startTime!.minute());
    if (startDate.isBefore(dayjs(), 'minutes')) {
      setStartTime(undefined);
      return;
    }
    const endDate = dayjs(startDate).add(endTime!.diff(startTime));
    appointmentCreateActions.create({
      startDatetime: startDate.toISOString(),
      endDatetime: endDate.toISOString(),
      type: method,
      comment,
      seriesConfig
    });
  };

  const handleStartTime = (newStartTime: Dayjs) => {
    const now = getLimitedNow();
    if (newStartTime.isBefore(now)) {
      setStartTime(now);
      setEndTime(dayjs(now).add(30, 'minutes'));
    } else {
      setStartTime(newStartTime);
      setEndTime(dayjs(newStartTime).add(30, 'minutes'));
    }
  };

  const onDateChange = (time: Dayjs | null) => {
    setDate(time);
    if (time) {
      if (startTime) {
        const newStartTime = dayjs(time).hour(startTime.hour()).minute(startTime.minute());
        handleStartTime(newStartTime);
      } else if (endTime) {
        const now = getLimitedNow(5);
        const newEndTime = dayjs(time).hour(endTime.hour()).minute(endTime.minute());
        if (newEndTime.isBefore(now)) {
          setEndTime(dayjs(now).add(5, 'minutes'));
        } else {
          setEndTime(dayjs(newEndTime));
        }
      }
    }
  };

  const onStartTimeChange = (time: Dayjs | null) => {
    if (date && time) {
      const newStartTime = dayjs(date).hour(time.hour()).minute(time.minute());
      handleStartTime(newStartTime);
    } else if (time) {
      setStartTime(time);
      setEndTime(dayjs(time).add(30, 'minutes'));
    } else {
      setStartTime(undefined);
      setEndTime(undefined);
    }
  };

  const onEndTimeChange = (time: Dayjs | null) => {
    if (time) {
      let newEndTime = dayjs(startTime || date || time)
        .hour(time.hour())
        .minute(time.minute());
      if (startTime) {
        const startTimeLimit = dayjs(startTime).add(5, 'minutes');
        if (newEndTime.isBefore(startTimeLimit)) {
          newEndTime = newEndTime.add(1, 'days');
        }
        setEndTime(newEndTime);
        return;
      } else if (date) {
        const now = getLimitedNow(5);
        if (newEndTime.isBefore(now)) {
          newEndTime = now;
        }
        setEndTime(newEndTime);
        return;
      }
    }
    setEndTime(time);
  };

  const onCommentChange = (message: string) => {
    setComment(message);
    if (message.length > MAX_COMMENT_LENGTH) {
      setIsCommentError(true);
    } else {
      setIsCommentError(false);
    }
  };

  useEffect(() => {
    return () => {
      appointmentCreateActions.reset();
    };
  }, []);

  useEffect(() => {
    patient && isPatientsModalOpen && setIsPatientsModalOpen(false);
  }, [patient]);

  useEffect(() => {
    providers[0] && isProvidersModalOpen && setIsProvidersModalOpen(false);
  }, [provider]);

  useEffect(() => {
    if (status === StateStatus.SUCCESS) {
      showAppointmentActionModal();
    } else if (status === StateStatus.ERROR) {
      showErrorModal();
    }
  }, [status]);

  return (
    <div className={styles.container}>
      <BackButton label={t('common.backBtn.label')} onClick={navigateToPreviousOrAppointmentsPage} />
      <div className={styles.card}>
        <div className={styles.inputs}>
          <div>
            <div className={styles.dateAndTime}>
              <DatePicker
                value={date}
                onChange={onDateChange}
                disabledDate={disabledPastDate}
                format={customDateFormat}
                disabled={allDisabled}
                className={styles.datePicker}
                inputReadOnly={true}
                placeholder={t('pages.AppointmentCreatePage.selectDate.label')}
                suffixIcon={!date && <ClockCircleOutlined />}
                allowClear={!!date}
                style={{
                  minWidth: isDesktop ? '144px' : 'auto'
                }}
              />
              <div className={styles.timePickerContainer}>
                <TimePicker
                  value={startTime}
                  onChange={onStartTimeChange}
                  format={getLTFormat()}
                  minuteStep={5}
                  disabled={allDisabled}
                  className={styles.timePicker}
                  inputReadOnly={true}
                  placeholder={t('pages.AppointmentCreatePage.selectTime.label')}
                  showNow={false}
                  needConfirm={false}
                  suffixIcon={!startTime && <ClockCircleOutlined />}
                  allowClear={!!startTime}
                  style={{
                    minWidth: isDesktop ? '117px' : 'auto'
                  }}
                />
                {'-'}
                <TimePicker
                  onChange={onEndTimeChange}
                  format={getLTFormat()}
                  minuteStep={5}
                  value={endTime}
                  disabled={allDisabled}
                  className={styles.timePicker}
                  inputReadOnly={true}
                  placeholder={t('pages.AppointmentCreatePage.selectTime.label')}
                  showNow={false}
                  needConfirm={false}
                  suffixIcon={!endTime && <ClockCircleOutlined />}
                  allowClear={!!endTime}
                  style={{
                    minWidth: isDesktop ? '117px' : 'auto'
                  }}
                />
                <span className={styles.duration}>{getDuration(startTime, endTime)}</span>
              </div>
            </div>
            <div className={styles.checkbox}>
              <Checkbox checked={!!seriesConfig} onChange={onChangeRecurrent}>
                {t('pages.AppointmentCreatePage.recurrentChbx.label')}
              </Checkbox>
              <AppointmentRecurrenceDescription seriesConfig={seriesConfig} showCheckbox={false} />
            </div>
          </div>
          <Space size={8} direction="vertical">
            <h3 className={styles.title}>{t('pages.AppointmentCreatePage.contactMethod.title')}</h3>
            <RadioButtons options={options} defaultValue={method} disabled={allDisabled} onChange={setMethod} />
          </Space>
          <Space size={8} direction="vertical">
            <h3 className={styles.title}>{t('pages.AppointmentCreatePage.commentTxt.title')}</h3>
            <TextArea
              value={comment}
              className={styles.commentField}
              rows={4}
              placeholder={t('pages.AppointmentCreatePage.commentTxt.placeholder')}
              autoSize={{ minRows: 4, maxRows: 6 }}
              onChange={(e) => onCommentChange(e.target.value)}
            />
            {isCommentError && (
              <span className={styles.validationMessage}>{t('pages.AppointmentCreatePage.commentTxt.errLimit')}</span>
            )}
          </Space>
        </div>
        {!isDesktop && (
          <div className={styles.participantsTitle}>{t('pages.AppointmentCreatePage.participants.title')}</div>
        )}
        <div className={styles.participantsContainer}>
          {isAdmin && isDesktop && (
            <div className={styles.participantsTitle}>{t('pages.AppointmentCreatePage.participants.title')}</div>
          )}
          <div className={styles.participants}>
            {isAdmin && provider && (
              <AppointmentCreateSelectedUser
                user={provider}
                onDelete={() => {
                  appointmentCreateActions.deleteProvider(provider.id);
                }}
                closeBtnDisabled={allDisabled}
                isAdmin={isAdmin}
              />
            )}
            {isAdmin && !provider && (
              <AppointmentCreateAddUser
                type="provider"
                setIsPopupOpen={setIsProvidersModalOpen}
                isPopupOpen={isProvidersModalOpen}
                disabled={allDisabled}
                roles={createProviderListRoles}
                patientId={createProviderListPatientId}
              />
            )}
            {patient ? (
              <AppointmentCreateSelectedUser
                user={patient}
                onDelete={() => {
                  appointmentCreateActions.deletePatient(patient.id);
                }}
                closeBtnDisabled={allDisabled}
                isAdmin={isAdmin}
              />
            ) : (
              <AppointmentCreateAddUser
                type={provider?.clinicServiceType === ClinicServiceEnum.PeerNavigator ? 'tester' : 'patient'}
                setIsPopupOpen={setIsPatientsModalOpen}
                isPopupOpen={isPatientsModalOpen}
                disabled={allDisabled}
                roles={createPatientListRoles}
                providerId={createPatientListProviderId}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonsBlock}>
        <Button type="primary" className={styles.buttonPrimary} onClick={onSave} disabled={saveDisabled}>
          {t('pages.AppointmentCreatePage.saveBtn.text')}
        </Button>
        <Button type="primary" className={styles.buttonPrimaryLow} onClick={navigateToPreviousOrAppointmentsPage}>
          {t('pages.AppointmentCreatePage.cancelBtn.text')}
        </Button>
      </div>
      <ConfirmModal onOk={() => {}} />
      <AppointmentActionModal
        onOk={() => {
          onAddToCalendar();
          navigateToPreviousOrAppointmentsPage();
        }}
        okLabel={t('pages.AppointmentCreatePage.appointmentActionModal.label')}
        okRef={addToCalendarButtonRef}
        onNo={navigateToPreviousOrAppointmentsPage}
        title={t('pages.AppointmentCreatePage.appointmentActionModal.title')}
        message={t('pages.AppointmentCreatePage.appointmentActionModal.message')}
        noLabel={t('pages.AppointmentCreatePage.appointmentActionModal.noLabel')}
      />
      <ErrorModal type={error?.name === 'OverlapsError' ? 'overlaps' : 'error'} />
      <AppointmentRecurrenceSetupModal onDone={setSeriesConfig} />
    </div>
  );
};
