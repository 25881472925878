import { useTranslate } from '@tolgee/react';
import { Empty } from 'antd';
import { FC, useMemo } from 'react';
import { StateStatus } from '../../../../common/enums/store';
import { groupAppointmentsByDate } from '../../../../common/helpers';
import { useActionResultModals } from '../../../../common/hooks/useActionResultModals';
import { useChangeLanguageReaction } from '../../../../common/hooks/useChangeLanguageReaction';
import { useLocales } from '../../../../common/hooks/useLocales';
import { useAppointmentListSelector } from '../../store/appointmentSelectors';
import { AppointmentListItemAdmin } from '../AppointmentListItem/AppointmentListItemAdmin';
import { AppointmentListItemProvider } from '../AppointmentListItem/AppointmentListItemProvider';
import style from './appointment-list.module.scss';

interface Props {
  isAdmin?: boolean;
  fetchData: (refreshing?: boolean) => void;
}

export const AppointmentList: FC<Props> = ({ isAdmin, fetchData }) => {
  const { t } = useTranslate();
  useChangeLanguageReaction({ callback: fetchData });
  const { status, entities: appointments, error } = useAppointmentListSelector();
  useLocales();

  const { SuccessModal, ErrorModal, onActionResponse } = useActionResultModals(fetchData);

  const groupedList = useMemo(() => {
    const grouped = groupAppointmentsByDate(appointments);
    return Object.entries(grouped);
  }, [appointments]);

  return (
    <>
      {groupedList.map(([date, groupList]) => (
        <div key={date}>
          <p className={style.date}>{date}</p>
          {groupList.map((item) => {
            if (isAdmin) {
              return <AppointmentListItemAdmin appointment={item} key={item.id} onActionResponse={onActionResponse} />;
            }
            return <AppointmentListItemProvider appointment={item} key={item.id} onActionResponse={onActionResponse} />;
          })}
        </div>
      ))}

      <SuccessModal />
      <ErrorModal type="error" />

      <div>{status === StateStatus.LOADING && t('features.AppointmentList.loadingBar.text')}</div>
      {status === StateStatus.SUCCESS && groupedList.length === 0 && (
        <Empty description={t('common.icon.noData')} image={Empty.PRESENTED_IMAGE_SIMPLE} className={style.empty} />
      )}
      <div>{error && t('features.AppointmentList.errFetch')}</div>
    </>
  );
};
