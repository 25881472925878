import dayjs from 'dayjs';
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Calendar } from 'react-big-calendar';
import close from '../../../assets/icons/close';
import { RBCView } from '../../calendar/interfaces';
import { useCalendarSelector } from '../../calendar/store/calendarSelectors';
import { useCalendarActionCreators } from '../../calendar/store/calendarSlice';
import { DayOffResponse } from '../../profile/services/ProfileDaoService';
import { EventCell } from './components/EventCell';
import { getToolbar } from './components/Toolbar';
import { WeekDayHeader } from './components/WeekDayHeader';
import WorkMonth from './components/WorkMonth';
import { dayjsLocalizer } from './dayjsLocalizer';
import './styles/event-calendar.scss';
import { useCalendarEvents } from './useCalendarEvent';

type Props = {
  switcher: ReactNode;
  startDate?: string;
  endDate?: string;
  dayoffs: DayOffResponse[];
};

export const EventCalendar: FC<Props> = ({ switcher, startDate, endDate, dayoffs }) => {
  const events = useCalendarEvents(dayoffs);
  const { view, date } = useCalendarSelector();
  const calendarActions = useCalendarActionCreators();

  const dayPropGetter = useCallback(
    (date: Date) => {
      let className = '';
      const dayDate = dayjs(date);
      if (
        ((startDate || !endDate) && dayDate.isBefore(startDate, 'day')) ||
        (endDate && dayDate.isAfter(endDate, 'day'))
      ) {
        className = 'rbc-day-off-current-range';
      }
      return {
        className
      };
    },
    [dayoffs, startDate, endDate]
  );

  useEffect(() => {
    // patch popup, it is not customizable
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutation) {
        mutation.addedNodes.forEach(function (added_node) {
          const added = added_node as HTMLElement;
          if (added.classList.contains('rbc-overlay')) {
            const closeButton = document.createElement('div');
            closeButton.className = 'rbc-overlay-close';
            closeButton.insertAdjacentHTML('afterbegin', close());
            added.prepend(closeButton);
            closeButton.onclick = () => {
              added.hidden = true;
            };

            const links = added.querySelectorAll('a.rbc-event-popup');
            const wrapper = document.createElement('div');
            wrapper.className = 'rbc-overlay-list';
            added.insertBefore(wrapper, links[0]);
            wrapper.append(...(links as any));
          }
        });
      });
    });
    observer.observe(document.body, { subtree: false, childList: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  const { components, views } = useMemo(
    () => ({
      views: {
        month: true,
        work_month: WorkMonth,
        week: true,
        work_week: true,
        day: true
      },
      components: {
        week: { header: WeekDayHeader },
        eventWrapper: EventCell as any,
        toolbar: getToolbar({ switcher, startDate, endDate })
      }
    }),
    [startDate, endDate]
  );

  return (
    <Calendar
      localizer={dayjsLocalizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 610 }}
      views={views}
      view={view as RBCView}
      onView={calendarActions.changeView as (view: RBCView) => void}
      popup
      showMultiDayTimes={false}
      components={components}
      dayPropGetter={dayPropGetter}
      enableAutoScroll
      scrollToTime={new Date()}
      date={date}
      onNavigate={calendarActions.changeDate}
    />
  );
};
