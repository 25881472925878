import { Dictionary, EntityId, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { AppointmentsByPatientState } from '../../../common/interfaces/store';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { Appointment } from '../../appointment/interfaces/appointment';
import { appointmentsByPatientThunks } from './appointmentsByPatientActions';

export interface AppointmentsByPatientConfig {
  ids: EntityId[];
  entities: Dictionary<Appointment>;
  hasNextPage: boolean;
}

const appointmentsByPatientAdapter = createEntityAdapter<Appointment>();

const initialState: AppointmentsByPatientState = appointmentsByPatientAdapter.getInitialState({
  hasNextPage: true,
  status: StateStatus.INIT
});

const slice = createSlice({
  name: 'appointmentsByPatient',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      appointmentsByPatientActions.getProviderAppointmentsByPatient.fulfilled,
      setSliceSuccess((state, action) => {
        const isInitPage = action.payload.page === 1;
        isInitPage
          ? appointmentsByPatientAdapter.setAll(state, action.payload.data)
          : appointmentsByPatientAdapter.upsertMany(state, action.payload.data);
        state.hasNextPage = action.payload.hasNextPage;
      })
    );
    builder.addCase(appointmentsByPatientActions.getProviderAppointmentsByPatient.pending, setSliceLoading);
    builder.addCase(appointmentsByPatientActions.getProviderAppointmentsByPatient.rejected, setSliceError);

    builder.addCase(
      appointmentsByPatientActions.getAppointmentByPationDetails.fulfilled,
      setSliceSuccess((state, action) => {
        appointmentsByPatientAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
    );
    builder.addCase(appointmentsByPatientActions.getAppointmentByPationDetails.pending, setSliceLoading);
    builder.addCase(appointmentsByPatientActions.getAppointmentByPationDetails.rejected, setSliceError);
  }
});

export const appointmentsByPatientReducer = slice.reducer;

export const appointmentsByPatientActions = {
  ...slice.actions,
  ...appointmentsByPatientThunks
};

export const useAppointmentsByPatientActionCreators = () => {
  const actionCreators = useActionCreators(appointmentsByPatientActions);
  return actionCreators;
};
