import { AppointmentStatusEnum, AttendeeStatusEnum } from '../../features/appointment/enums/appointmentEnums';
import { Appointment } from '../../features/appointment/interfaces/appointment';
import { Attendee } from '../../features/appointment/interfaces/attendee';
import { getAttendeeByRole } from '../../features/appointment/utils/helpers';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { UserRoleEnum } from '../enums/userRoleEnum';

export enum ConfirmAsEnum {
  attendee,
  provider,
  patient,
  peerNavigator,
}

const shouldConfirmAsAttendee = (userId: number, attendees: Attendee[]): boolean =>
  attendees.find((attendee) => attendee.userId === userId)?.status === AttendeeStatusEnum.REQUESTED;

const isRequested = (status: AppointmentStatusEnum): boolean =>
  [AppointmentStatusEnum.REQUESTED_BY_PROVIDER, AppointmentStatusEnum.REQUESTED_BY_PATIENT, AppointmentStatusEnum.REQUESTED_BY_TESTER].includes(status);

const shouldConfirmAsPeerNavigator = (appointment: Appointment): boolean => {
  const requested =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator)?.status === AttendeeStatusEnum.REQUESTED;
  return isRequested(appointment.status) && requested;
};

const shouldConfirmAsProvider = (appointment: Appointment): boolean => {
  const requested =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider)?.status === AttendeeStatusEnum.REQUESTED;
  return isRequested(appointment.status) && requested;
};

const shouldConfirmAsPatient = (appointment: Appointment): boolean => {
  const requested =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.User)?.status === AttendeeStatusEnum.REQUESTED;
  return isRequested(appointment.status) && requested;
};

export const useConfirm = (appointment: Appointment): ConfirmAsEnum | void => {
  const { user } = useAuthConfigSelector();
  if (!isRequested(appointment.status)) return;

  if (shouldConfirmAsAttendee(user?.id as number, appointment.attendees)) {
    return ConfirmAsEnum.attendee;
  }
  const isAdmin = user?.role?.id === UserRoleEnum.Admin;
  if (!isAdmin) {
    return;
  }
  if (shouldConfirmAsProvider(appointment)) {
    return ConfirmAsEnum.provider;
  }
  if (shouldConfirmAsPeerNavigator(appointment)) {
    return ConfirmAsEnum.peerNavigator;
  }
  if (shouldConfirmAsPatient(appointment)) {
    return ConfirmAsEnum.patient;
  }
};
