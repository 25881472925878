import { useTranslate } from '@tolgee/react';
import style from './notification-list-count.module.scss';

interface NotificationListCountProps {
  count: number;
}

export const NotificationListCount = ({ count }: NotificationListCountProps) => {
  const { t } = useTranslate();

  const transformedCount = count > 999 ? '999+' : count;

  return (
    <div className={style.container}>
      {t('features.NotificationListCount.label')} {count ? <span className={style.count}>{transformedCount}</span> : null}
    </div>
  );
};
