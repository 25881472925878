import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { AuthConfigState } from '../../../common/interfaces/store';
import { UnauthorizedError } from '../../../common/services/http/errors.helper';
import { getTokenFromStorage } from '../../../common/utils/storage';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { isInstanceOf } from '../../../common/utils/typeUtils';
import { checkTokenExpires } from '../utils';
import { authThunks } from './authActions';

const initialTokenValues = getTokenFromStorage();

const initialState: AuthConfigState = {
  authorized: checkTokenExpires(initialTokenValues.tokenExpires),
  token: initialTokenValues.token,
  tokenExpires: initialTokenValues.tokenExpires,
  status: StateStatus.INIT,
  user: null
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    completeChangePassword(state) {
      state.oldPassword = null;
      state.email = null;
      state.changePassword = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      authActions.login.fulfilled,
      setSliceSuccess((state, action) => {
        state.authorized = !action.payload.changePassword && !!action.payload.token;
        state.token = action.payload.token;
        state.tokenExpires = action.payload.tokenExpires;
        state.user = action.payload.user;
        state.email = action.payload.email;
        state.changePassword = action.payload.changePassword;
        state.oldPassword = action.payload.oldPassword;
      })
    );

    builder.addCase(
      authActions.logout.fulfilled,
      setSliceSuccess((state) => {
        state.authorized = false;
        state.token = null;
        state.tokenExpires = null;
        state.user = null;
        state.email = null;
      })
    );
    builder.addCase(authActions.logout.rejected, setSliceError);

    builder.addCase(
      authActions.changePassword.fulfilled,
      setSliceSuccess((state, action) => {
        state.oldPassword = action.payload.oldPassword;
        state.email = action.payload.email;
        state.token = action.payload.token;
        state.tokenExpires = action.payload.tokenExpires;
      })
    );
    builder.addCase(authActions.login.pending, setSliceLoading);
    builder.addCase(authActions.login.rejected, setSliceError);
    builder.addCase(authActions.changePassword.pending, setSliceLoading);
    builder.addCase(authActions.changePassword.rejected, setSliceError);

    builder.addCase(
      authActions.getUser.fulfilled,
      setSliceSuccess((state, action) => {
        state.user = action.payload;
      })
    );
    builder.addCase(authActions.getUser.rejected, setSliceError);

    builder.addMatcher(
      (action) => {
        return isInstanceOf(action.payload, UnauthorizedError) || isInstanceOf(action.error, UnauthorizedError);
      },
      (state) => {
        state.authorized = false;
        state.token = null;
        state.tokenExpires = null;
        state.user = null;
        state.email = null;
        state.changePassword = false;
        state.oldPassword = null;
      }
    );
  }
});

export const authReducer = slice.reducer;

export const authActions = {
  ...slice.actions,
  ...authThunks
};

export const useAuthActionCreators = () => {
  const authActionCreators = useActionCreators(authActions);
  return authActionCreators;
};
