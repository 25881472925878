import { CheckOutlined } from '@ant-design/icons';
import { T } from '@tolgee/react';
import { Button, ConfigProvider, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import updateLocale from 'dayjs/plugin/updateLocale';
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import { useCallback } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg';
import { customDateFormat } from '../../../../../common/constants';
import { useLocales } from '../../../../../common/hooks/useLocales';
import { useTheme } from '../../../../../common/providers/ThemeProvider';
import { disabledPastDate } from '../../../../../common/utils/dateFormat';
import { IBaseFilterProps } from '../../types';
import styles from './range-picker.module.scss';

dayjs.extend(updateLocale);
dayjs.extend(isoWeek);

export type PickerViewType = 'week' | 'month' | 'date';

const fixFrom = (date: Dayjs | null, picker: PickerViewType): Dayjs | null => {
  if (!date) return null;
  if (picker === 'week') {
    return date.startOf('isoWeek').startOf('day');
  }
  if (picker === 'month') {
    return date.startOf('month').startOf('day');
  }
  return date.startOf('day');
};

const fixTo = (date: Dayjs | null, picker: PickerViewType): Dayjs | null => {
  if (!date) return null;
  if (picker === 'week') {
    return date.endOf('isoWeek').endOf('day');
  }
  if (picker === 'month') {
    return date.endOf('month').endOf('day');
  }
  return date.endOf('day');
};

const toISOString = (date: Dayjs | null): string => {
  return date?.toISOString() || '';
};

const toString = (date: Dayjs | null): string => {
  return date?.format(customDateFormat) || '';
};

const pickerOptions = [
  { label: <T keyName="common.day.label" />, value: 'date' },
  { label: <T keyName="common.week.label" />, value: 'week' },
  { label: <T keyName="common.month.label" />, value: 'month' }
];

const DateRangePicker = ({ dataSource, selected, onChange, filter, extra }: IBaseFilterProps) => {
  const [start, end] = dataSource;
  const startFilterKey = start?.key as string;
  const endFilterKey = end?.key as string;
  const { calendarLocale } = useLocales();
  const { colors } = useTheme();

  const onDateChange = useCallback(
    (dates: NoUndefinedRangeValueType<Dayjs>) => {
      const [fromValue, toValue] = dates || [null, null];
      const fromDate = fixFrom(fromValue, extra.picker);
      const toDate = fixTo(toValue, extra.picker);

      onChange({
        ...filter,
        key: toISOString(fromDate),
        label: toString(fromDate),
        value: !!fromValue,
        filterKey: startFilterKey,
        radio: true
      });
      onChange({
        ...filter,
        key: toISOString(toDate),
        label: toString(toDate),
        value: !!toValue,
        filterKey: endFilterKey,
        radio: true
      });
    },
    [extra.picker]
  );

  const selectedStart = selected.find((s) => s.filterKey === startFilterKey);
  const selectedEnd = selected.find((s) => s.filterKey === endFilterKey);
  const values: [Dayjs | null, Dayjs | null] = [
    selectedStart ? dayjs(selectedStart.key) : null,
    selectedEnd ? dayjs(selectedEnd.key) : null
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: colors.primary,
          colorBgContainerDisabled: colors.background
        },
        components: {
          Button: {
            colorLink: colors.primary,
            defaultGhostColor: colors.primary,
            defaultBorderColor: 'transparent',
            paddingInline: 14
          }
        }
      }}
    >
      <DatePicker.RangePicker
        size={'small'}
        format={customDateFormat}
        picker={extra.picker}
        bordered={false}
        placeholder={[start?.label, end?.label]}
        separator={'-'}
        suffixIcon={!values?.every(Boolean) && <ArrowDown className={styles.icon} />}
        allowClear={values?.every(Boolean)}
        className={styles.picker}
        allowEmpty={[true, true]}
        onChange={onDateChange}
        value={values}
        disabledDate={disabledPastDate}
        inputReadOnly
        popupClassName={styles.popup}
        locale={calendarLocale}
        renderExtraFooter={() => (
          <>
            {pickerOptions.map(({ label, value }) => (
              <Button
                key={value}
                ghost={extra.picker === value}
                icon={<CheckOutlined />}
                onClick={() => {
                  extra.onPickerChange(value);
                }}
              >
                {label}
              </Button>
            ))}
          </>
        )}
      />
    </ConfigProvider>
  );
};

export default DateRangePicker;
