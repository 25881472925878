import { T, useTranslate } from '@tolgee/react';
import { Col, Row, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SortOrder, SorterResult } from 'antd/es/table/interface';
import { useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styles from '../../../../assets/styles/table.module.scss';
import { ascDescSortDirections } from '../../../../common/constants';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { ColumnKeysEnum } from '../../../../common/enums/usersTableEnum';
import { useUserDataSource } from '../../../../common/hooks/useUserDataSource';
import { RegionsEnum } from '../../../../localization/data';
import { Button } from '../../../ui/Button/Button';
import { FilterInput } from '../../../ui/FilterInput/FilterInput';
import { FilterSelect } from '../../../ui/FilterSelect/FilterSelect';
import TableFooter from '../../../ui/TableFooter/TableFooter';
import { AvailablilityOptionsEnum } from '../../enums/availabilityOptions';
import { ClinicServiceEnum } from '../../enums/clinicService';
import { UserInfo } from '../../services/AppointmentCreateDaoService';
import { useAppointmentCreateActionCreators } from '../../store/appointmentCreateSlice';
import { useGetListOfProvince } from '../../../../common/hooks/useGetListOfProvince';

type AppointmentCreateProviderListTypes = {
  isSelect?: boolean;
  includeRequester?: boolean;
  roles?: UserRoleEnum[];
  patientId?: number;
  skipSavingFilters?: boolean;
};

const clinicalTypeOptionsTemplate =
  Object.entries(ClinicServiceEnum).map(([key, value]) => ({
    label: <T keyName={`common.clinicServiceTypes.${key}.name` as any} />, value: value }))
;

const todayAvailabilityOptions = [
  { label: <T keyName="features.AppointmentCreateProviderList.todayAvailabilityOptions.all" />, value: '' },
  {
    label: <T keyName="features.AppointmentCreateProviderList.todayAvailabilityOptions.available" />,
    value: `${AvailablilityOptionsEnum.Available}`
  },
  {
    label: <T keyName="features.AppointmentCreateProviderList.todayAvailabilityOptions.notAvailable" />,
    value: `${AvailablilityOptionsEnum.NotAvailable}`
  }
];

const futureAvailabilityOptions = [
  { label: <T keyName="features.AppointmentCreateProviderList.futureAvailabilityOptions.all" />, value: '' },
  {
    label: <T keyName="features.AppointmentCreateProviderList.futureAvailabilityOptions.available" />,
    value: `${AvailablilityOptionsEnum.Available}`
  },
  {
    label: <T keyName="features.AppointmentCreateProviderList.futureAvailabilityOptions.notAvailable" />,
    value: `${AvailablilityOptionsEnum.NotAvailable}`
  }
];

export const AppointmentCreateProviderList = (props: AppointmentCreateProviderListTypes) => {
  const { t } = useTranslate();
  const { isSelect, skipSavingFilters, ...initialParams } = props;
  const appointmentCreateActions = useAppointmentCreateActionCreators();
  const [searchParams, setSearchParams] = useSearchParams();
  const {listLocations} = useGetListOfProvince();
  const { page, limit, sortBy, sortDirection, ...defaultFilters } = useMemo(() => {
    const params: { [key: string]: string | string[] } = {};

    if (!skipSavingFilters) {
      searchParams.forEach((value: string, key: string) => {
        if (value) {
          const existedValue = params[key];
          const newValue = existedValue
            ? [...(Array.isArray(existedValue) ? existedValue : [existedValue]), value]
            : value;
          params[key] = newValue;
        }
      });
    }

    return params;
  }, []);

  const initialPage = page ? +page : undefined;
  const initialPageSize = limit ? +limit : undefined;
  const initialSorting = sortBy && sortDirection ? ([sortBy, sortDirection] as [ColumnKeysEnum, SortOrder]) : undefined;

  const {
    dataSource,
    filters,
    onFilterChange,
    setPageSize,
    totalRecords,
    currentPage,
    pageSize,
    sorting,
    setCurrentPage,
    onSortingChange,
    clinicalTypeOptions
  } = useUserDataSource({ initialParams, initialPage, initialPageSize, initialSorting });

  useEffect(() => {
    if (!skipSavingFilters) {
      const [sortBy = '', sortDirection = ''] = sorting || [];
      const paramsEntries = Object.entries({
        ...filters,
        page: `${currentPage}`,
        limit: `${pageSize}`,
        sortBy: `${sortBy}`,
        sortDirection: `${sortDirection}`
      });
      const filteredParams = paramsEntries.filter(([_, value]) => value);
      setSearchParams(Object.fromEntries(filteredParams));
    }
  }, [filters, currentPage, pageSize, sorting]);

  const columns: ColumnsType<UserInfo> = [
    ...(isSelect
      ? [
        {
          title: '',
          dataIndex: '',
          key: '',
          render: (_: any, record: any) => (
            <Button
              className={`${styles.button} buttonOutlined`}
              onClick={() => {
                appointmentCreateActions.setProvider(record);
              }}
            >
              {t('features.AppointmentCreatePatientList.selectBtn.text')}
            </Button>
          )
        }
      ]
      : []),
    {
      title: t('common.providerData.id.label'),
      key: ColumnKeysEnum.uniqueCode,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'uniqueCode' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: <FilterInput filterKey="uniqueCode" onChange={onFilterChange} />,
          dataIndex: 'uniqueCode',
          key: 'uniqueCode',
          render: (text, record) => <Link to={`/providers/${record.id}`}>{text}</Link>,
          fixed: 'left'
        }
      ]
    },
    {
      title: t('common.providerData.firstName.label'),
      key: ColumnKeysEnum.firstName,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'firstName' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterInput
              filterKey="firstName"
              defaultValue={`${defaultFilters.firstName || ''}`}
              onChange={onFilterChange}
            />
          ),
          dataIndex: 'firstName',
          key: 'firstName',
          render: (_, record) => <p>{record.firstName}</p>
        }
      ]
    },
    {
      title: t('common.providerData.lastName.label'),
      key: ColumnKeysEnum.lastName,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'lastName' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterInput
              filterKey="lastName"
              defaultValue={`${defaultFilters.lastName || ''}`}
              onChange={onFilterChange}
            />
          ),
          dataIndex: 'lastName',
          key: 'lastName',
          render: (_, record) => <p>{record.lastName}</p>
        }
      ]
    },
    {
      title: t('common.providerData.email.label'),
      key: ColumnKeysEnum.email,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'email' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterInput filterKey="email" defaultValue={`${defaultFilters.email || ''}`} onChange={onFilterChange} />
          ),
          dataIndex: 'email',
          key: 'email',
          render: (_, record) => <p>{record.email}</p>
        }
      ]
    },
    {
      title: t('common.providerData.phone.label'),
      key: ColumnKeysEnum.phone,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'phone' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterInput filterKey="phone" defaultValue={`${defaultFilters.phone || ''}`} onChange={onFilterChange} />
          ),
          key: 'phone',
          dataIndex: 'phone',
          render: (text) => <p>{text || ''}</p>
        }
      ]
    },
    {
      title: t('common.providerData.type.label'),
      key: ColumnKeysEnum.clinicServiceType,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'clinicServiceType' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterSelect
              mode="multiple"
              maxTagCount={1}
              filterKey="clinicServiceType"
              defaultValue={defaultFilters.clinicServiceType}
              onChange={onFilterChange}
              options={clinicalTypeOptions ? clinicalTypeOptions : clinicalTypeOptionsTemplate}
            />
          ),
          key: 'clinicServiceType',
          dataIndex: 'clinicServiceType',
          render: (text) => <p>{text}</p>
        }
      ]
    },
    {
      title: t('features.AppointmentCreateProviderList.locationColumn.title'),
      key: ColumnKeysEnum.region,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'region' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterSelect
              mode="multiple"
              maxTagCount={1}
              filterKey="region"
              defaultValue={defaultFilters.region}
              onChange={onFilterChange}
              options={listLocations}
            />
          ),
          key: 'region',
          dataIndex: 'region',
          render: (text) => <p>{text}</p>
        }
      ]
    },
    {
      title: t('common.providerData.address.label'),
      key: ColumnKeysEnum.address,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'address' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterInput
              filterKey="address"
              defaultValue={`${defaultFilters.address || ''}`}
              onChange={onFilterChange}
            />
          ),
          key: 'address',
          dataIndex: 'address',
          render: (text) => <p>{text}</p>
        }
      ]
    },
    {
      title: t('features.AppointmentCreateProviderList.todayAvailabilityColumn.title'),
      key: ColumnKeysEnum.todayAvailability,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'todayAvailability' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterSelect
              filterKey="todayAvailability"
              defaultValue={defaultFilters.todayAvailability}
              onChange={onFilterChange}
              options={todayAvailabilityOptions}
            />
          ),
          key: 'todayAvailability',
          dataIndex: 'todayAvailability',
          render: (isAvailable) => <p>{isAvailable ? t('common.yes.label') : t('common.no.label')}</p>
        }
      ]
    },
    {
      title: t('features.AppointmentCreateProviderList.futureAvailabilityColumn.title'),
      key: ColumnKeysEnum.futureAvailability,
      sorter: true,
      sortDirections: ascDescSortDirections,
      sortOrder: sorting?.[0] === 'futureAvailability' ? (sorting?.[1] as SortOrder) : undefined,
      children: [
        {
          title: (
            <FilterSelect
              filterKey="futureAvailability"
              defaultValue={defaultFilters.futureAvailability}
              onChange={onFilterChange}
              options={futureAvailabilityOptions}
            />
          ),
          key: 'futureAvailability',
          dataIndex: 'futureAvailability',
          render: (isAvailable) => <p>{isAvailable ? t('common.yes.label') : t('common.no.label')}</p>
        }
      ]
    }
  ];

  const onTableStateChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserInfo> | SorterResult<UserInfo>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { columnKey, order } = sorter;
      onSortingChange(columnKey as ColumnKeysEnum, order);
    }
  };

  return (
    <Row className="container">
      <Col className="tableContainer">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={{
            pageSize: pageSize,
            current: currentPage,
            style: { display: 'none' }
          }}
          locale={{ emptyText: t('common.noData.label') }}
          scroll={{ x: 'max-content' }}
          rowKey={(record) => record.id}
          onChange={onTableStateChange}
        />
      </Col>
      <TableFooter
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalRecords={totalRecords}
      />
    </Row>
  );
};
