import enUS from 'antd/es/calendar/locale/en_US';
import frCa from 'antd/es/calendar/locale/fr_CA';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr-ca';
import { useEffect } from 'react';
import { tolgee } from '../../app/App';
import { Locales } from '../../localization/constants';
import { updateLocaleData } from '../../localization/date';

const AntCalendarLocaleMap = {
  [Locales['en-Jm']]: enUS,
  [Locales['en-Us']]: enUS,
  [Locales['fr-Ca']]: frCa
};

export const useLocales = () => {
  const locale = tolgee.getLanguage() as Locales;

  useEffect(() => {
    Object.entries(updateLocaleData).forEach(([locale, localeData]) => {
      dayjs.updateLocale(locale, localeData);
    });

    dayjs.locale(locale);
  }, [locale]);

  return {
    calendarLocale: AntCalendarLocaleMap[locale] || AntCalendarLocaleMap['en-Jm']
  };
};
