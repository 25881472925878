import { HeaderProps } from 'react-big-calendar';

export const WeekDayHeader = ({ label }: HeaderProps) => {
  const [day, date] = label.split(' ');
  return (
    <>
      <div>{day}</div>
      <div>{date}</div>
    </>
  );
};
