import { AnyAction, createAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { useActionCreators } from '../hooks/store';
import { RootState } from '../interfaces/store';
import { UnauthorizedError } from '../services/http/errors.helper';
import { isInstanceOf } from '../utils/typeUtils';

export const listenerMiddleware = createListenerMiddleware<RootState>();

export const errorAction = createAction<unknown, 'error'>('error');

const excludeUrls = ['/appointment/connect'];

listenerMiddleware.startListening({
  actionCreator: errorAction,
  effect: async (action: AnyAction, listenerApi) => {
    const error = action.payload;    
    if (!isInstanceOf(error, UnauthorizedError) && !excludeUrls.includes(error.metadata?.config?.url)) {
      alert(action.payload.message);
    }
  }
});

export const useErrorActionCreators = () => {
  const errorActions = useActionCreators({error: errorAction});
  return errorActions;
};
