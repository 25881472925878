import { Input } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import style from './controlled-input.module.scss';

interface Props {
  type: 'input' | 'textarea';
  control: Control<any>;
  name: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  classes?: string;
  maxLength?: number;
  count?: {
    show: boolean;
    max: number;
  };
}

const { TextArea } = Input;

export const ControlledInput: FC<Props> = (props) => {
  const InputComponent = props.type === 'input' ? Input : TextArea;

  return (
    <div
      className={classNames(
        {
          [style.controlledInput]: true,
          [style.error]: props.error
        },
        props.classes
      )}
    >
      {props.label && <label className={style.label}>{props.label}</label>}
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <InputComponent
            {...field}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            className={style.input}
            count={props.count}
          />
        )}
      />
      {props.error && <p className={style.validationMessage}>{props.error.message}</p>}
    </div>
  );
};
