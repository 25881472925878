import Icon from '@ant-design/icons';
import { Checkbox, List, Select } from 'antd';
import { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg';
import { IBaseFilterProps } from '../../types';
import styles from './dropdown.module.scss';

export const MultiSelect = ({ filter, dataSource, selected, onChange, label }: IBaseFilterProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Select
      open={open}
      bordered={false}
      value={label}
      suffixIcon={<Icon component={ArrowDown} rotate={open ? 180 : 0} className={styles.arrow} />}
      onDropdownVisibleChange={setOpen}
      className={styles.select}
      popupMatchSelectWidth={false}
      placement='bottomRight'
      popupClassName={styles.popup}
      dropdownRender={() => (
        <List
          dataSource={dataSource}
          className={styles.selectList}
          renderItem={(item) => (
            <List.Item>
              <Checkbox
                value={item.key}
                key={item.key}
                checked={selected.some((select) => select.filterKey === filter.filterKey && select.key === item.key)}
                disabled={item.disabled}
                onChange={(e) => {
                  onChange({ ...filter, key: item.key, label: item.label, value: e.target.checked});
                }}
              >
                {item.label}
              </Checkbox>
            </List.Item>
          )}
        />
      )}
    />
  );
};
