import { Outlet } from 'react-router-dom';
import { useWindowSizeType } from '../common/hooks/useWindowSizeType';
import { IncomingNotificationContainer } from '../features/IncomingNotification/components/IncomingNotificationContainer/IncomingNotificationContainer';
import { useAuthConfigSelector } from '../features/auth/store/authSelectors';
import { RoutedTabs } from '../features/routedTabs/RoutedTabs';
import { MobileMenu } from '../features/ui/MobileMenu/MobileMenu';
import { UserActions } from '../features/ui/UserActions/UserActions';

function Layout() {
  const { authorized } = useAuthConfigSelector();
  const { isDesktop } = useWindowSizeType();

  return (
    <>
      {isDesktop ? <RoutedTabs /> : <MobileMenu />}
      {authorized && <UserActions />}
      <div className="Content">
        <Outlet />
        <IncomingNotificationContainer />
      </div>
    </>
  );
}

export default Layout;
