import {
  RemoteVideo,
  RosterAttendeeType,
  useActiveSpeakersState,
  useRemoteVideoTileState
} from 'amazon-chime-sdk-component-library-react';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { getFullName } from '../../../../common/utils/helpers';
import { Appointment } from '../../../../features/appointment/interfaces/appointment';
import { Attendee } from '../../../appointment/interfaces/attendee';
import { AppointmentDaoService } from '../../../appointment/services/AppointmentDaoService';
import { ProfileAvatar } from '../../../profile/components/ProfileAvatar/ProfileAvatar';
import useAttendeeAudioStatuses from '../../hooks/useAttendeeAudioStatuses';
import Microphone from '../svg/Microphone';
import styles from './appointment-join-page-content.module.scss';

export const AttendeeTiles = ({
  appointment,
  attendees
}: {
  appointment: Appointment;
  attendees: RosterAttendeeType[];
}) => {
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const activeSpeakers = useActiveSpeakersState();
  const statuses = useAttendeeAudioStatuses(attendees);
  const [externalAttendees, setExternalAttendees] = useState<Record<string, Attendee>>({});

  useEffect(() => {
    const fetchExternalAttendee = async (id: string) => {
      try {
        const data = await AppointmentDaoService.getExternalAttendee(id);
        setExternalAttendees((externals) => ({ ...externals, [id]: data }));
      } catch (error) {
        console.log('Fecth external attendee', error);
      }
    };
    const appointmentAttendeesIds = appointment.attendees.map(({ id }) => id);
    attendees.forEach(({ externalUserId }) => {
      const id = externalUserId as string;
      !appointmentAttendeesIds.includes(id) && !externalAttendees[id] && fetchExternalAttendee(id);
    });
  }, [attendees]);

  return (
    <div className={styles.tiles}>
      {attendees.map((attendee, i) => {
        const isMuted = statuses.get(attendee.chimeAttendeeId)?.muted ?? true;
        const isSpeaker = !isMuted && activeSpeakers.includes(attendee.chimeAttendeeId);
        const tile = attendeeIdToTileId[attendee.chimeAttendeeId];
        const current =
          externalAttendees[attendee.externalUserId as string] ||
          appointment.attendees.find((att) => {
            return att.id === attendee.externalUserId;
          });

        const name = getFullName(current) || current?.ssiId;
        return (
          <div
            className={classnames(styles.tile, {
              [styles.activeSpeaker]: tile !== undefined && isSpeaker,
              [styles.singleTile]: attendees.length === 1
            })}
            key={attendee.externalUserId}
          >
            {tile !== undefined ? (
              <div className={styles.tileVideoBlock}>
                <RemoteVideo tileId={tile} className={styles.tileVideo} />
                <div className={styles.remoteStatus}>
                  <div>{name}</div>
                  <Microphone fill="#CCCCCC" disabled={isMuted} />
                </div>
              </div>
            ) : (
              <div className={classnames(styles.tileInfo, { [styles.activeSpeaker]: isSpeaker })}>
                <p>{name}</p>
                <ProfileAvatar uniqueCode={current?.uniqueCode} />
                <div style={{ height: '18px' }}>
                  <Microphone fill="#CCCCCC" disabled={isMuted} />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
