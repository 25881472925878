import { EntityId } from '@reduxjs/toolkit';
import { NotificationInstance } from 'antd/es/notification/interface';
import { ReactNode } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { store } from '../../../app/rootReducer';
import { AVAILABILITY_TAB_KEY } from '../../../pages/home/HomePage';
import { useNotificationListActionCreators } from '../../notificationList/store/notificationListSlice';
import { IncomingNotificationTypesEnum } from '../enums/incomingNotificationTypesEnum';
import { incomingNotificationActions, useIncomingNotificationCreators } from '../store/IncomingNotificationSlice';

export type NotificationDetailsData = {
  appointmentId?: string;
  appointmentStartDatetime: string;
  notificationId: string;
};

export type IncomingNotification = {
  id: string;
  title: string;
  body: string;
  type: IncomingNotificationTypesEnum;
  additionalData: NotificationDetailsData;
};

type ShowNotificationParams = {
  api: NotificationInstance;
  icon: ReactNode;
  notifications: IncomingNotification[];
  navigate: NavigateFunction;
  message: (
    title: string,
    body: string,
    type: IncomingNotificationTypesEnum,
    details: NotificationDetailsData
  ) => JSX.Element;
  incommingNotificationActions: ReturnType<typeof useIncomingNotificationCreators>;
  notificationsListActions: ReturnType<typeof useNotificationListActionCreators>;
};

type ClickListenerParams = {
  navigate: NavigateFunction;
  api: NotificationInstance;
  notification: IncomingNotification;
  notificationsListActions?: ReturnType<typeof useNotificationListActionCreators>;
};

const toDetails = ({ navigate, api, notification, notificationsListActions }: ClickListenerParams) => {
  const appointmentId = notification?.additionalData?.appointmentId;

  if (appointmentId) {
    navigate(`/appointments/${appointmentId}`);
  }

  notificationsListActions && notificationsListActions.markAsRead({ ids: [notification.id] });
  destroyOne(api, notification.id);
};

const toNotifications = ({ navigate, api, notification }: ClickListenerParams) => {
  navigate('/notifications');
  destroyOne(api, notification.id);
};

const toAvailability = ({ navigate, api, notification, notificationsListActions }: ClickListenerParams) => {
  navigate(`/appointments?activeViewKey=${AVAILABILITY_TAB_KEY}`);
  notificationsListActions && notificationsListActions.markAsRead({ ids: [notification.id] });
  destroyOne(api, notification.id);
};

const interactionListeners = {
  [IncomingNotificationTypesEnum.START_CALL]: toDetails,
  [IncomingNotificationTypesEnum.REQUESTED_BY_PATIENT]: toDetails,
  [IncomingNotificationTypesEnum.REQUESTED_BY_PROVIDER]: toDetails,
  [IncomingNotificationTypesEnum.APPOINTMENT_CONFIRMATION]: toDetails,
  [IncomingNotificationTypesEnum.APPOINTMENT_CANCELED]: toDetails,
  [IncomingNotificationTypesEnum.PENDING_CONFIRMATION]: toDetails,
  [IncomingNotificationTypesEnum.UPCOMING_APPOINTMENT]: toDetails,
  [IncomingNotificationTypesEnum.APPOINTMENT_COMPLETED]: toDetails,
  // [IncomingNotificationTypesEnum.APPOINTMENT_CANCELED_BY_TESTER]: toDetails,
  [IncomingNotificationTypesEnum.REQUESTED_BY_TESTER]: toDetails,
  [IncomingNotificationTypesEnum.AVAILABILITY_CHANGED]: toAvailability,
  [IncomingNotificationTypesEnum.STACK]: toNotifications
};

const destroyOne = (api: NotificationInstance, id: EntityId | string) => {
  api.destroy(id);
};

const destroyAll = (api: NotificationInstance, ids: EntityId[] | string[]) => {
  ids.forEach((id) => destroyOne(api, id));
};

const showNotifications = ({
  notifications,
  api,
  icon,
  navigate,
  message,
  incommingNotificationActions,
  notificationsListActions
}: ShowNotificationParams) => {
  notifications.forEach((notification) => {
    const clickListener = interactionListeners[notification.type];

    api.open({
      icon,
      message: message(notification.title, notification.body, notification.type, notification.additionalData),
      key: notification?.id,
      duration: 5,
      onClick: clickListener ? () => clickListener({ navigate, api, notification, notificationsListActions }) : () => {
        console.log('No click listener for => ', notification.type);
      },
      onClose: () => incommingNotificationActions.removeOne(notification.id),
      className: 'notification'
    });

    notificationsListActions.updateUnreadAmount();
  });
};

const saveNotification = (notification: IncomingNotification) => {
  store.dispatch(incomingNotificationActions.addOne(notification));
};

export const IncomingNotificationService = {
  showNotifications,
  destroyAll,
  saveNotification
};
