import { ConfigProvider, Tabs } from 'antd';
import { FC, ReactNode } from 'react';
import { useTheme } from '../../../common/providers/ThemeProvider';

interface ViewSwitchProps {
  items: { key: string; label: ReactNode; disabled?: boolean }[];
  activeView: string;
  onChange: (activeKey: string) => void;
}

export const ViewSwitch: FC<ViewSwitchProps> = ({ items, onChange, activeView }) => {
  const {colors} = useTheme();
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSizeLG: 14,
          colorPrimary: colors.black,
          colorText: colors.primary
        },
        components: {
          Tabs: {
            inkBarColor: colors.primary
          }
        }
      }}
    >
      <Tabs activeKey={activeView} items={items} onChange={onChange} />
    </ConfigProvider>
  );
};
