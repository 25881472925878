import { CaretDownOutlined } from '@ant-design/icons';
import { useTolgee, useTranslate } from '@tolgee/react';
import { ConfigProvider, Select } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { tolgee } from '../../../app/App';
import { useWindowSizeType } from '../../../common/hooks/useWindowSizeType';
import { LANGS, localesTranslationsMap } from '../../../localization/constants';
import { getTypedKey } from '../../../localization/utils';
import styles from './language-switcher.module.scss';

export const LanguageSwitcher = () => {
  const tolgeee = useTolgee(['language']);
  const [value, setValue] = useState(tolgee.getLanguage() || LANGS[0]);
  const { isDesktop } = useWindowSizeType();
  const { t } = useTranslate();
  const classes = classNames({
    [styles.mobile]: !isDesktop,
    [styles.desktop]: isDesktop
  });

  return LANGS.length > 1 ? (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            selectorBg: 'none'
          }
        }
      }}
    >
      <Select
        onChange={async (value: string) => {
          await tolgeee.changeLanguage(value);
          setValue(value);
        }}
        variant={'borderless'}
        labelRender={({ label }) => {
          return <span className={classes}>{label || t(getTypedKey(localesTranslationsMap[value]))}</span>;
        }}
        suffixIcon={<CaretDownOutlined className={classes} />}
        defaultValue={value}
        value={value}
        options={LANGS.map((lang) => ({
          value: lang,
          label: t(getTypedKey(`common.lang.${lang}`))
        }))}
      />
    </ConfigProvider>
  ) : null;
};
