import { FC } from 'react';
import { ReactComponent as Chat } from '../../../../assets/icons/chat.svg';
import { ReactComponent as Next } from '../../../../assets/icons/next.svg';
import { ReactComponent as Audio } from '../../../../assets/icons/phone.svg';
import { ReactComponent as VideoCam } from '../../../../assets/icons/videocam.svg';
import { ReactComponent as Hologram } from '../../../../assets/icons/hologram.svg';
import { AppointmentTypeEnum } from '../../../../common/enums/appointmentTypeEnum';

interface Props {
  type: AppointmentTypeEnum;
  isRecurrent?: boolean;
}

export const AppointmentType: FC<Props> = ({ type = false, isRecurrent }) => {
  return (
    <>
      {type === AppointmentTypeEnum.video && <VideoCam className="rbc-event-type-icon" />}
      {type === AppointmentTypeEnum.audio && <Audio className="rbc-event-type-icon" />}
      {type === AppointmentTypeEnum.text && <Chat className="rbc-event-type-icon" />}
      {type === AppointmentTypeEnum.hologram && <Hologram className="rbc-event-type-icon"/>}
      {isRecurrent && <Next className="rbc-event-type-icon" />}
    </>
  );
};
