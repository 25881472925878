import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { AppointmentCreateState } from '../../../common/interfaces/store';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { UserInfo } from '../services/AppointmentCreateDaoService';
import { appointmentCreateThunks } from './appointmentCreateActions';

const initialState: AppointmentCreateState = {
  patients: [],
  providers: [],
  status: StateStatus.INIT
};

const slice = createSlice({
  name: 'appointment-create',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setPatient(state, action: PayloadAction<UserInfo>) {
      state.patients.push(action.payload);
    },
    deletePatient(state, action: PayloadAction<UserInfo['id']>) {
      state.patients = state.patients.filter((patient) => patient.id !== action.payload);
    },
    setProvider(state, action: PayloadAction<UserInfo>) {
      state.providers.push(action.payload);
    },
    deleteProvider(state, action: PayloadAction<UserInfo['id']>) {
      state.providers = state.providers.filter((provider) => provider.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(appointmentCreateThunks.create.fulfilled, setSliceSuccess((state, action) => {
      state.appointment = action.payload;
    }));
    builder.addCase(appointmentCreateThunks.create.pending, setSliceLoading);
    builder.addCase(appointmentCreateThunks.create.rejected, setSliceError);
  }
});

export const appointmentCreateReducer = slice.reducer;

export const appointmentCreateActions = {
  ...slice.actions,
  ...appointmentCreateThunks
};

export const useAppointmentCreateActionCreators = () => {
  const appointmentCreateActionCreators = useActionCreators(appointmentCreateActions);
  return appointmentCreateActionCreators;
};
