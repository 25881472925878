import { Modal } from 'antd';
import classNames from 'classnames';
import { ReactNode, useCallback, useState } from 'react';
import styles from './modal.module.scss';

interface ModalProps {
  children: ReactNode;
  onCancel?: () => void;
  preventHideOnCancel?: boolean;
  classes?: string;
}

const useModal = ({ width = 423, initialOpened = false }: { width?: number; initialOpened?: boolean }) => {
  const [open, setOpen] = useState(initialOpened);

  const showModal = useCallback(() => {
    setOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    setOpen(false);
  }, []);

  const BaseModal = useCallback(
    ({ children, onCancel, preventHideOnCancel, classes }: ModalProps) => {
      const onCancelHandler = () => {
        onCancel?.();
        if (preventHideOnCancel) {
          return;
        }
        hideModal();
      };

      return (
        <Modal
          wrapClassName={classNames(styles.wrap, classes)}
          width={width}
          open={open}
          onCancel={onCancelHandler}
          footer={null}
          maskClosable={false}
        >
          {children}
        </Modal>
      );
    },
    [open]
  );

  return {
    Modal: BaseModal,
    showModal,
    hideModal
  };
};

export default useModal;
