import { useEffect, useState } from 'react';

const tablet = 680;
const tabletL = 760;
const tabletLL = 960;
const desktop = 1100;
const desktopL = 1300;
const desktopLL = 1400;
const desktopLLL = 1600;

type WindowExtraSizeType = 'mobile' | 'tablet' | 'desktop' | 'tabletL' | 'tabletLL' | 'desktopL' | 'desktopLL' | 'desktopLLL';

export const useWindowExtraSizeType = (): WindowExtraSizeType => {
  const [device, setDevice] = useState<WindowExtraSizeType>('mobile');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < tablet) {
        setDevice('mobile');
      } else if (window.innerWidth >= tablet && window.innerWidth < tabletL) {
        setDevice('tablet');
      } else if (window.innerWidth >= tabletL && window.innerWidth < tabletLL) {
        setDevice('tabletL');
      } else if (window.innerWidth >= tabletLL && window.innerWidth < desktop) {
        setDevice('tabletLL');
      } else if (window.innerWidth >= desktop && window.innerWidth < desktopL) {
        setDevice('desktop');
      } else if (window.innerWidth >= desktopL && window.innerWidth < desktopLL) {
        setDevice('desktopL');
      } else if (window.innerWidth >= desktopLL && window.innerWidth < desktopLLL) {
        setDevice('desktopLL');
      } else {
        setDevice('desktopLLL');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return device;
};
