import { Patient } from '../../../common/interfaces/patient';
import { IAmReadyHttpService, TelehealthHttpService } from '../../../common/services/http/http.service';
import { buildQueryString } from '../../../common/utils/buildQueryString';
import { Locations } from '../../../common/interfaces/locations';

export type DayOffResponse = {
  startDate: string;
  endDate: string
}

const getPatientInfoByProvider = (patientId: string): Promise<Patient> => {
  return TelehealthHttpService.url(`/users/my-users/${patientId}`).get<Patient>();
};

const getPatientInfoByAdmin = <T>(patientId: string): Promise<T> => {
  return TelehealthHttpService.url(`/users/${patientId}`).get<T>();
};

const updateProviderProfile = (data: Record<string, string | number | null>): Promise<any> => {
  const { id, ...rest } = data;
  return TelehealthHttpService.url(`/provider/${id}`).patch<Record<string, string | null>>(rest);
};

const getProviderDayoffs = ({id, ...rest}: {id: number; startDate?: string; endDate?: string}) => {
  const queryString = buildQueryString(rest);
  return TelehealthHttpService.url(`/provider/${id}/day-offs?${queryString}`).get<DayOffResponse[]>();
};

const getContactPersons = (userId: number) => {
  return TelehealthHttpService.url(`/provider/${userId}/assigned-managers`).get();
};

const getListLocations = (): Locations[] => {
  return [{  id: 1,
    name: 'Remote',
    provinceCode: '1',
    active: true}];
};

export const ProfileDaoService = {
  getPatientInfoByProvider,
  getPatientInfoByAdmin,
  updateProviderProfile,
  getProviderDayoffs,
  getContactPersons,
  getListLocations
};
