import { useEffect, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { SocketService } from '../services/socket/socket.service';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';

enum AppointmentsEventsEnum {
  UPDATE = 'update-appointments'
}

export const useLiveRefresh = ({ listener }: { listener: () => void }) => {
  const socketRef = useRef<Socket | null>(null);
  const { authorized, token } = useAuthConfigSelector();
  useEffect(() => {
    if(authorized && token) {
      const socket = SocketService.connect([
        {
          name: AppointmentsEventsEnum.UPDATE,
          listener
        }
      ]);
      socketRef.current = socket;
      return () => {
        if (socketRef.current) {
          socketRef.current.disconnect();
        }
      };
    }
  }, [authorized, token]);
};
