import { useTranslate } from '@tolgee/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicServiceListSelector } from '../../store/clinicServiceSelectors';
import { useClinicServiceActionCreators } from '../../store/clinicServiceSlice';
import style from './clinic-service-details.module.scss';

export const ClinicServiceDetails = () => {
  const { t } = useTranslate();
  const { id } = useParams();
  const { data } = useClinicServiceListSelector();
  const clinicActions = useClinicServiceActionCreators();

  const currentService = data.find((service) => service.id === Number(id));

  useEffect(() => {
    if (!data.length) {
      clinicActions.getClinicServices();
    }
  }, []);

  return (
    <>
      {currentService ? (
        <div className={style.card}>
          <p>
            <span className={style.label}>{t('features.ClinicServiceDetails.id.label')}</span>
            {currentService.id}
          </p>
          <p>
            <span className={style.label}>{t('features.ClinicServiceDetails.name.label')}</span>
            {currentService.title || currentService.type}
          </p>
          <p>
            <span className={style.label}>{t('features.ClinicServiceDetails.duration.label')}</span>
            {currentService.slotDuration} {t('features.ClinicServiceDetails.duration.unit')}
          </p>

          <p className={style.label}>{t('features.ClinicServiceDetails.briefDescription.label')}</p>
          <div className={style.description}>{currentService.briefDescription}</div>

          <p className={style.label}>{t('features.ClinicServiceDetails.fullDescription.label')}</p>
          <div className={style.description}>{currentService.fullDescription}</div>
        </div>
      ) : (
        <div>{t('features.ClinicServiceDetails.errNotFound')}</div>
      )}
    </>
  );
};
