const tokenKey = 'token';
const tokenExpiresKey = 'tokenExpires';

export function getTokenFromStorage() {
  return {
    token: localStorage.getItem(tokenKey),
    tokenExpires: Number(localStorage.getItem(tokenExpiresKey)),
  };
}

export function setTokenToStorage(token: string, tokenExpires: number) {
  localStorage.setItem(tokenKey, String(token));
  localStorage.setItem(tokenExpiresKey, String(tokenExpires));
}

export function removeTokenInStorage() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(tokenExpiresKey);
}
