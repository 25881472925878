import { Dictionary, EntityId, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { IncomingNotificationState } from '../../../common/interfaces/store';
import { defaultStackNotificationId } from '../components/IncomingNotificationContainer/IncomingNotificationContainer';
import { IncomingNotification } from '../services/IncomingNotificationService';

export interface IncomingNotificationConfig {
  ids: EntityId[];
  entities: Dictionary<IncomingNotification>;
}

export const incomingNotificationAdapter = createEntityAdapter<IncomingNotification>();

const initialState: IncomingNotificationState = incomingNotificationAdapter.getInitialState({
  status: StateStatus.INIT
});

const slice = createSlice({
  name: 'incomingNotification',
  initialState,
  reducers: {
    addOne: (state, action) => {
      if (!state.ids.includes(defaultStackNotificationId)) {
        incomingNotificationAdapter.addOne(state, action);
      }
    },
    addOneAsStack: (state, action) => {
      incomingNotificationAdapter.removeAll(state);
      incomingNotificationAdapter.addOne(state, action);
    },
    removeOne: (state, action) => {
      incomingNotificationAdapter.removeOne(state, action.payload);
    },
    reset: (state) => {
      state = initialState;
    }
  },
});

export const incomingNotificationReducer = slice.reducer;

export const incomingNotificationActions = {
  ...slice.actions,
};

export const useIncomingNotificationCreators = () => {
  const actionCreators = useActionCreators(incomingNotificationActions);
  return actionCreators;
};
