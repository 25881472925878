import { useTranslate } from '@tolgee/react';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Event } from 'react-big-calendar';
import { second } from '../../../common/constants';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';
import { UserRoleEnum } from '../../../common/enums/userRoleEnum';
import { formatDateWithDayJS } from '../../../common/utils/dateFormat';
import { getFullName } from '../../../common/utils/helpers';
import { AppointmentStatusEnum } from '../../appointment/enums/appointmentEnums';
import { Appointment } from '../../appointment/interfaces/appointment';
import { useAppointmensSelector } from '../../appointment/store/appointmentSelectors';
import { getAttendeeByRole } from '../../appointment/utils/helpers';
import { DayOffResponse } from '../../profile/services/ProfileDaoService';

export interface CalendarEvent extends Event {
  start: Date;
  end: Date;
  type: AppointmentTypeEnum | 'dayoff';
  status?: AppointmentStatusEnum;
  patient?: string;
  appointmentId?: string;
  shortTitle?: string;
  isRecurrent?: boolean;
  isPeerNavigatorAppointment?: boolean;
}

export const useCalendarEvents = (dayoffsSet: DayOffResponse[]) => {
  const { t } = useTranslate();
  const appointments = useAppointmensSelector();
  const [updateCount, setUpdateCount] = useState(0);

  const {events, schedule} = useMemo(() => {
    const schedule: CalendarEvent[] = [];
    const events = appointments.map(appointment => {
      const event = transformAppointmentToCalendarEvent(appointment);
      const startTime = event.start.getTime();
      const endTime = event.end.getTime();
      const currentTime = Date.now();

      if (endTime > currentTime && event.status === AppointmentStatusEnum.CONFIRMED) {
        if (startTime <= currentTime) {
          event.status = AppointmentStatusEnum.ACTIVE;
        }
        schedule.push(event);
      }
      return event;
    });    
    return {events, schedule};
  }, [appointments, updateCount]);

  useEffect(() => {
    if (schedule.length) {
      const interval = setInterval(() => {
        const outdate = schedule.some(event => {
          const startTime = event.start.getTime();
          const endTime = event.end.getTime();
          const currentTime = Date.now();
          return endTime < currentTime || startTime < currentTime && event.status === AppointmentStatusEnum.CONFIRMED;
        });  
        if (outdate) {
          setUpdateCount(s => s + 1);
        }
      }, second * 5);
      return () => clearInterval(interval);
    }
  }, [schedule]);

  const dayoffs: CalendarEvent[] = useMemo(() => dayoffsSet.map((dayOff: DayOffResponse) => {
    const isOneDay = new Date(dayOff.endDate).getDate() === new Date(dayOff.startDate).getDate();
    if (isOneDay) {
      return transformDayoffToCalendarEvent(dayOff, t('features.EventCalendar.dayOff.title'));
    }

    let current = new Date(dayOff.endDate);
    const res: DayOffResponse[] = [];

    while (new Date(dayOff.startDate).getDate() !== current.getDate()) {
      const prevDay = dayjs(current).add(-1, 'day').toDate();

      res.push({
        startDate: dayjs(current).startOf('day').toDate().toISOString(),
        endDate: current.toISOString()
      });
      current = dayjs(prevDay).endOf('day').toDate();
    }

    res.unshift({
      startDate: new Date(dayOff.startDate).toISOString(),
      endDate: dayjs(current).endOf('day').toDate().toISOString(),
    });

    return res.map((dayOff) => transformDayoffToCalendarEvent(dayOff, t('features.EventCalendar.dayOff.title')));
    
  }).flat(), [dayoffsSet]);

  const eventsWithDayoffs = useMemo(() => [...events, ...dayoffs], [dayoffs, events]);

  return eventsWithDayoffs;
};

const transformAppointmentToCalendarEvent = (appointment: Appointment): CalendarEvent => {
  const timeLabel = `${formatDateWithDayJS(appointment.startDatetime, 'h:mm a')} - ${formatDateWithDayJS(
    appointment.endDatetime,
    'h:mm a'
  )}`;
  const patient = getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
  const patientName = getFullName(patient);
  const isPeerNavigatorAppointment = !!getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator);

  return {
    title: timeLabel,
    shortTitle: timeLabel.replaceAll(' ', ''),
    start: new Date(appointment.startDatetime),
    end: new Date(appointment.endDatetime),
    type: appointment.type,
    status: appointment.status,
    patient: patientName,
    appointmentId: appointment.id,
    isRecurrent: !!appointment.seriesConfig,
    isPeerNavigatorAppointment
  };
};

const transformDayoffToCalendarEvent = (dayoff: DayOffResponse, title: string): CalendarEvent => {
  return {
    title,
    start: new Date(dayoff.startDate),
    end: new Date(dayoff.endDate),
    type: 'dayoff',
  };
};
