interface PhoneProps {
  fill?: string
  className?: string
}

const Phone = (props: PhoneProps) => {
  return (
    <svg className={props.className} width="100%" height="100%" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.7676 10.2518C14.7995 9.12362 11.5291 8.97186 8.45887 9.7997L8.30793 12.9073C8.28941 13.2887 8.05366 13.6241 7.71382 13.7705C6.63574 14.249 5.60525 14.914 4.68693 15.7473C4.27961 16.1168 3.64397 16.086 3.27438 15.6787L0.929204 13.094C0.55962 12.6867 0.590493 12.0511 0.99781 11.6815C7.95183 5.37168 18.7013 5.89378 25.0111 12.8478C25.3807 13.2551 25.3498 13.8908 24.9425 14.2603L22.3505 16.6122C21.9432 16.9818 21.3075 16.951 20.938 16.5436C20.098 15.6179 19.1435 14.8636 18.1169 14.2828C17.8002 14.0975 17.5974 13.7549 17.6166 13.3594L17.7676 10.2518Z" fill={ props.fill || '#FF0000' }/>
    </svg>
  );
};

export default Phone;