import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useActionResultModals } from '../../../../common/hooks/useActionResultModals';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { formatDateWithDayJS, getLTFormat } from '../../../../common/utils/dateFormat';
import { getFullName } from '../../../../common/utils/helpers';
import { AppointmentActions } from '../../../appointment/components/AppointmentActions/AppointmentActions';
import { AppointmentComment } from '../../../appointment/components/AppointmentComment/AppointmentComment';
import { AppointmentStatus } from '../../../appointment/components/AppointmentStatus/AppointmentStatus';
import { AppointmentType } from '../../../appointment/components/AppointmentType/AppointmentType';
import { Appointment } from '../../../appointment/interfaces/appointment';
import { Attendee } from '../../../appointment/interfaces/attendee';
import { getAttendeeByRole } from '../../../appointment/utils/helpers';
import { useAuthConfigSelector } from '../../../auth/store/authSelectors';
import { useAppointmentsByPatientActionCreators } from '../../store/appointmentsByPatientSlice';
import styles from './appointments-by-patient-list-item.module.scss';

type Props = {
  isHistoryItem: boolean;
  appointment: Appointment;
};

const timeFormat: string = getLTFormat();
const dateFormat = 'MMMM DD, YYYY';

export const AppointmentsByPatientListItem: FC<Props> = ({ appointment, isHistoryItem }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { user: me } = useAuthConfigSelector();
  // if appointment was created by provider or by admin for provider and a patient
  const provider: Attendee | undefined = getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider);
  // if appointment was created by admin
  const admin: Attendee | undefined = getAttendeeByRole(appointment.attendees, UserRoleEnum.Admin);
  const appointmentsByPatientActions = useAppointmentsByPatientActionCreators();
  const person: Attendee | undefined = admin || provider;
  const isIAdmin = me?.role.id === UserRoleEnum.Admin;

  const isPeerNavigatorAppointment = !!getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator);

  const { isTablet } = useWindowSizeType();

  const fetchDetails = useCallback(() => {
    appointmentsByPatientActions.getAppointmentByPationDetails(appointment.id);
  }, [appointment.id]);

  const { SuccessModal, ErrorModal, onActionResponse } = useActionResultModals(fetchDetails);

  const ProviderInfo = () => {
    return (
      <span>
        {isIAdmin ? (
          <Link to={`/providers/${person?.userId}`} className={classNames(styles.name, styles.accentText)}>
            {getFullName(person)}
          </Link>
        ) : (
          <span className={styles.name}>{getFullName(person)}</span>
        )}
        <span>{' - '}</span>
        <span className={styles.clinicType}>{person?.clinicServiceTitle || person?.clinicServiceType}</span>
      </span>
    );
  };

  return (
    <div className={styles.appointmentByPatientCard}>
      <header className={styles.cardHeader}>
        <span className={styles.appointmentSchedule}>
          {`${formatDateWithDayJS(appointment.startDatetime, timeFormat)} - ${formatDateWithDayJS(
            appointment.endDatetime,
            timeFormat
          )}, ${formatDateWithDayJS(appointment.startDatetime, dateFormat)}`}
        </span>
        {!isTablet && <ProviderInfo />}
        <div className={styles.appointmentMeta}>
          <AppointmentType type={appointment.type} isPeerNavigatorAppointment={isPeerNavigatorAppointment} />
          <AppointmentStatus status={appointment.status} isPeerNavigatorAppointment={isPeerNavigatorAppointment} />
        </div>
      </header>
      {isTablet && <ProviderInfo />}
      {appointment.comment && (
        <AppointmentComment
          ownerRole={appointment.owner.role}
          text={appointment.comment}
          isShortenedText={true}
          className={styles.appointmentComment}
        />
      )}
      <footer className={styles.cardFooter}>
        {isHistoryItem ? (
          <Button
            type="primary"
            size="middle"
            className={styles.historyViewButton}
            onClick={() => navigate(`/appointments/${appointment.id}`)}
          >
            {t('features.AppointmentsByPatientListItem.viewBtn.text')}
          </Button>
        ) : (
          <AppointmentActions appointment={appointment} onActionResponse={onActionResponse} />
        )}
      </footer>

      <SuccessModal />
      <ErrorModal type="error" />
    </div>
  );
};
