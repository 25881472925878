import { Select } from 'antd';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { Controller, FieldError } from 'react-hook-form';
import style from './controlled-select.module.scss';

interface Props {
  control: any;
  name: string;
  label?: string;
  defaultValue?: string;
  options: { value: string; label: ReactNode }[];
  error?: FieldError;
  classes?: string;
}

export const ControlledSelect: FC<Props> = (props) => {
  const defaultValue = props.defaultValue;
  return (
    <div
      className={classNames(
        {
          [style.controlledSelect]: true,
          [style.error]: props.error
        },
        props.classes
      )}
    >
      {props.label && <label className={style.label}>{props.label}</label>}
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <Select {...field} defaultValue={defaultValue} className={style.select}>
            {props.options.map((option) => (
              <Select.Option key={Math.random()} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )}
      />
      {props.error && <p>{props.error.message}</p>}
    </div>
  );
};
