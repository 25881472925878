import { useTranslate } from '@tolgee/react';
import { Button, Collapse } from 'antd';
import classNames from 'classnames';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { StateStatus } from '../../../../common/enums/store';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { useAuthConfigSelector } from '../../../auth/store/authSelectors';
import { useProfileSelector } from '../../store/profileSelectors';
import { useProfileActionCreators } from '../../store/profileSlice';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar';
import { ProfilePatientContactInformation } from '../ProfilePatientContactInformation/ProfilePatientContactInformation';
import style from './profile-patient.module.scss';

const { Panel } = Collapse;

export const ProfilePatient: FC<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslate();
  const { user: me } = useAuthConfigSelector();
  const profileActions = useProfileActionCreators();
  const { data: profile, status, error } = useProfileSelector();
  const navigate = useNavigate();
  const { isDesktop, isTablet } = useWindowSizeType();

  const patientName = profile?.firstName ? `${profile?.firstName} ${profile?.lastName}` : '';

  useEffect(() => {
    if (me && userId) {
      profileActions.getPatientProfile({
        role: me.role.id,
        userId
      });
    }
  }, [userId, me]);

  return (
    <div className={style.profileInfo}>
      {isDesktop && (
        <>
          <div className={style.profilePhotoContainer}>
            <ProfileAvatar uniqueCode={profile.uniqueCode} className={style.profileImage} />
            <p className={style.profileName}>
              {profile?.firstName} {profile?.lastName}
            </p>
          </div>

          <ProfilePatientContactInformation user={profile} />
        </>
      )}
      {!isDesktop && (
        <>
          {isTablet && (
            <Button
              type="primary"
              className={style.button}
              onClick={() => navigate('/appointments/appointment-create')}
            >
              {t('common.bookAppointmentBtn.text')}
            </Button>
          )}
          <Collapse
            className={style.collapse}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <ArrowDown className={classNames(style.arrowDown, { [style.active]: isActive })} />
            )}
          >
            <Panel
              header={patientName}
              key={patientName}
            >
              <ProfilePatientContactInformation user={profile} />
            </Panel>
          </Collapse>
        </>
      )}
      <div>{status === StateStatus.LOADING && t('features.ProfilePatient.loadingBar.text')}</div>
      <div>{error && t('features.ProfilePatient.errFetch')}</div>
    </div>
  );
};
