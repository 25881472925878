import { T, useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import { FC, useCallback } from 'react';
import useModal from '..';
import { ReactComponent as Checked } from '../../../assets/icons/checked.svg';
import styles from './modal.module.scss';

const defaultMessage = <T keyName='features.SuccessModal.defaultMsg' />;

const useSuccessModal = (message?: string) => {
  const { t } = useTranslate();
  const { Modal, hideModal, showModal } = useModal({});
  const successMessage = message || defaultMessage;

  const SuccessModal: FC<{ onOk?: () => void }> = useCallback(
    ({ onOk }) => (
      <Modal onCancel={onOk}>
        <div className={styles.icon}>
          <Checked />
        </div>
        <p className={styles.title}>{successMessage}</p>
        <div className={styles.buttonsBlock}>
          <Button
            type="primary"
            className={styles.buttonPrimary}
            onClick={() => {
              onOk?.();
              hideModal();
            }}
          >
            {t('features.SuccessModal.confirmBtn.text')}
          </Button>
        </div>
      </Modal>
    ),
    [Modal]
  );

  return {
    SuccessModal,
    showSuccessModal: showModal
  };
};

export default useSuccessModal;
