import { useTranslate } from '@tolgee/react';
import { Checkbox } from 'antd';
import { FC } from 'react';
import { ReactComponent as Next } from '../../../../assets/icons/next.svg';
import { formatDateWithDayJS, getWeekDayName } from '../../../../common/utils/dateFormat';
import {
  AppointmentSerieEndOptionEnum,
  AppointmentSeriesIntervalUnitEnum,
  AppointmentSeriesMonthWeekEnum
} from '../../enums/appointmentEnums';
import { AppointmentSerieEnd, SeriesConfig } from '../../interfaces/appointment';
import styles from './appointment-recurrence.module.scss';

export const AppointmentRecurrenceDescription: FC<{ seriesConfig?: SeriesConfig; showCheckbox?: boolean }> = ({ seriesConfig, showCheckbox = true }) => {
  const { t } = useTranslate();

  if (!seriesConfig) return null;
  const { intervalUnit, end, weekDays, interval, monthDay, monthWeek } = seriesConfig;

  const monthWeekMap = {
    [AppointmentSeriesMonthWeekEnum.FIRST]: t('features.AppointmentRecurrenceDescription.monthly.monthWeek.first'),
    [AppointmentSeriesMonthWeekEnum.SECOND]: t('features.AppointmentRecurrenceDescription.monthly.monthWeek.second'),
    [AppointmentSeriesMonthWeekEnum.THIRD]: t('features.AppointmentRecurrenceDescription.monthly.monthWeek.third'),
    [AppointmentSeriesMonthWeekEnum.FOURTH]: t('features.AppointmentRecurrenceDescription.monthly.monthWeek.fourth'),
    [AppointmentSeriesMonthWeekEnum.LAST]: t('features.AppointmentRecurrenceDescription.monthly.monthWeek.last')
  };

  const formatMonthly = (): string => {
    const pre =
      interval < 2
        ? t('features.AppointmentRecurrenceDescription.monthly.title')
        : t('features.AppointmentRecurrenceDescription.monthly.interval', { interval });
    const days = monthDay
      ? t('features.AppointmentRecurrenceDescription.monthly.day', { day: monthDay })
      : t('features.AppointmentRecurrenceDescription.monthly.weekday', {
        weekday: weekDays?.[0] ? getWeekDayName(weekDays[0]) : '',
        week: monthWeekMap[monthWeek as AppointmentSeriesMonthWeekEnum]
      });
    return pre + days;
  };

  const formatWeekly = (): string => {
    const pre =
      interval < 2
        ? t('features.AppointmentRecurrenceDescription.weekly.title')
        : t('features.AppointmentRecurrenceDescription.weekly.interval', { interval });
    let days = t('features.AppointmentRecurrenceDescription.weekly.allDays');
    if (weekDays && weekDays.length < 7) {
      days = t('features.AppointmentRecurrenceDescription.weekly.days', {
        days: weekDays.map(getWeekDayName).join(', ')
      });
    }
    return pre + days;
  };

  const description = intervalUnit === AppointmentSeriesIntervalUnitEnum.WEEK ? formatWeekly() : formatMonthly();

  const formatEnd = ({ option, value }: AppointmentSerieEnd): string => {
    switch (option) {
      case AppointmentSerieEndOptionEnum.ON:
        return t('features.AppointmentRecurrenceDescription.end.on.text', {
          date: formatDateWithDayJS(value!, 'D MMMM YYYY')
        });
      case AppointmentSerieEndOptionEnum.AFTER:
        return t('features.AppointmentRecurrenceDescription.end.after.text', { count: value });
      default:
        return '';
    }
  };

  return (
    <>
      {showCheckbox && <div className={styles.checkbox}>
        <Checkbox checked>{t('features.AppointmentRecurrenceDescription.checkbox.label')}</Checkbox>
      </div>}
      <div className={styles.description}>
        <Next className={styles.icon} />
        <span>{description + formatEnd(end)}</span>
      </div>
    </>
  );
};
