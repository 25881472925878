import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';
import { RootState } from '../../../common/interfaces/store';
import { userInfoToAttendees } from '../../../common/utils/helpers';
import { Appointment, SeriesConfig } from '../../appointment/interfaces/appointment';
import { AppointmentCreateDaoService } from '../services/AppointmentCreateDaoService';

const createThunk = createAsyncThunk<
  Appointment,
  {
    startDatetime: string;
    endDatetime: string;
    type: AppointmentTypeEnum;
    comment?: string;
    seriesConfig?: SeriesConfig;
  },
  { state: RootState }
>('appointment-create/create', async (values, thunkApi) => {
  const { patients, providers } = thunkApi.getState().appointmentCreate;
  const patientsIds = patients.map((patient) => patient.id);
  const providersIds = providers.map((provider) => provider.id);
  const userIds = [...providersIds, ...patientsIds];

  const overlaps = await AppointmentCreateDaoService.getOverlaps({
    startDatetime: values.startDatetime,
    endDatetime: values.endDatetime,
    userIds,
    seriesConfig: values.seriesConfig,
  });

  if (overlaps.length) {
    throw new OverlapsError();
  }

  const appointment = await AppointmentCreateDaoService.create({
    ...values,
    userIds
  });
  return { ...appointment, attendees: userInfoToAttendees([...patients, ...providers]) };
});

export const appointmentCreateThunks = {
  create: createThunk
};

export class OverlapsError extends Error {
  public constructor() {
    super('OverlapsError');
    this.name = 'OverlapsError';
  }
}
