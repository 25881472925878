// import dayjs plugins
// it is customized clone of dayjsLocalizer from react-big-calendar
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import { DateLocalizer } from 'react-big-calendar';
import { defaultLocale } from '../../../localization/date';

// load dayjs plugins
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);
dayjs.extend(utc);
dayjs.extend(isoWeek);

dayjs.locale(defaultLocale);

const weekRangeFormat = ({ start, end }: any) =>
  dayjs(start).format(eq(start, end, 'month') ? 'DD' : 'DD MMMM') + ' – ' + dayjs(end).format('DD MMMM, YYYY');

const timeRangeFormat = (
  { start, end }: any,
  culture: any,
  local: { format: (arg0: any, arg1: string, arg2: any) => string }
) => local.format(start, 'LT', culture) + ' – ' + local.format(end, 'LT', culture);

const timeRangeStartFormat = (
  { start }: any,
  culture: any,
  local: { format: (arg0: any, arg1: string, arg2: any) => string }
) => local.format(start, 'LT', culture) + ' – ';

const timeRangeEndFormat = (
  { end }: any,
  culture: any,
  local: { format: (arg0: any, arg1: string, arg2: any) => string }
) => ' – ' + local.format(end, 'LT', culture);

export const formats = {
  dateFormat: 'D',
  dayFormat: 'ddd D',
  weekdayFormat: 'ddd',

  selectRangeFormat: timeRangeFormat,
  eventTimeRangeFormat: timeRangeFormat,
  eventTimeRangeStartFormat: timeRangeStartFormat,
  eventTimeRangeEndFormat: timeRangeEndFormat,

  timeGutterFormat: 'h:mm A',

  monthHeaderFormat: 'MMMM YYYY',
  dayHeaderFormat: 'MMMM D, dddd',
  dayRangeHeaderFormat: weekRangeFormat
};

function fixUnit(unit: any) {
  let datePart = unit ? unit.toLowerCase() : unit;
  if (datePart === 'FullYear') {
    datePart = 'year';
  } else if (datePart === 'week') {
    datePart = 'isoWeek';
  } else if (!datePart) {
    datePart = undefined;
  }
  return datePart;
}

const locale = (dj: { locale: (arg0: any) => any }, c: string | undefined) => (c ? dj.locale(c) : dj);

function getTimezoneOffset(date: any) {
  // ensures this gets cast to timezone
  return dayjs(date).toDate().getTimezoneOffset();
}

function getDstOffset(start: any, end: any) {
  // convert to dayjs, in case
  const st = dayjs(start);
  const ed = dayjs(end);
  return st.toDate().getTimezoneOffset() - ed.toDate().getTimezoneOffset();
}

function getDayStartDstOffset(start: any) {
  const dayStart = dayjs(start).startOf('day');
  return getDstOffset(dayStart, start);
}

/*** BEGIN localized date arithmetic methods with dayjs ***/
function defineComparators(a: any, b: any, unit: string) {
  const datePart = fixUnit(unit);
  const dtA = datePart ? dayjs(a).startOf(datePart) : dayjs(a);
  const dtB = datePart ? dayjs(b).startOf(datePart) : dayjs(b);
  return [dtA, dtB, datePart];
}

function startOf(date = null, unit: string) {
  const datePart = fixUnit(unit);
  if (datePart) {
    return dayjs(date).startOf(datePart).toDate();
  }
  return dayjs(date).toDate();
}

function endOf(date = null, unit: string) {
  const datePart = fixUnit(unit);
  if (datePart) {
    return dayjs(date).endOf(datePart).toDate();
  }
  return dayjs(date).toDate();
}

// dayjs comparison operations *always* convert both sides to dayjs objects
// prior to running the comparisons
function eq(a: any, b: any, unit?: any) {
  const [dtA, dtB, datePart] = defineComparators(a, b, unit);
  return dtA.isSame(dtB, datePart);
}

function neq(a: any, b: any, unit: any) {
  return !eq(a, b, unit);
}

function gt(a: any, b: any, unit: any) {
  const [dtA, dtB, datePart] = defineComparators(a, b, unit);
  return dtA.isAfter(dtB, datePart);
}

function lt(a: any, b: any, unit: any) {
  const [dtA, dtB, datePart] = defineComparators(a, b, unit);
  return dtA.isBefore(dtB, datePart);
}

function gte(a: any, b: any, unit: any) {
  const [dtA, dtB, datePart] = defineComparators(a, b, unit);
  return dtA.isSameOrBefore(dtB, datePart);
}

function lte(a: any, b: any, unit?: any) {
  const [dtA, dtB, datePart] = defineComparators(a, b, unit);
  return dtA.isSameOrBefore(dtB, datePart);
}

function inRange(day: any, min: any, max: any, unit = 'day') {
  const datePart = fixUnit(unit);
  const djDay = dayjs(day);
  const djMin = dayjs(min);
  const djMax = dayjs(max);
  return djDay.isBetween(djMin, djMax, datePart, '[]');
}

function min(dateA: any, dateB: any) {
  const dtA = dayjs(dateA);
  const dtB = dayjs(dateB);
  const minDt = dayjs.min(dtA, dtB);
  return minDt?.toDate();
}

function max(dateA: any, dateB: any) {
  const dtA = dayjs(dateA);
  const dtB = dayjs(dateB);
  const maxDt = dayjs.max(dtA, dtB);
  return maxDt?.toDate();
}

function merge(date: any, time: any) {
  if (!date && !time) return null;

  const tm = dayjs(time).format('HH:mm:ss');
  const dt = dayjs(date).startOf('day').format('MM/DD/YYYY');
  // We do it this way to avoid issues when timezone switching
  return dayjs(`${dt} ${tm}`, 'MM/DD/YYYY HH:mm:ss').toDate();
}

function add(date: any, adder: number, unit: any) {
  return dayjs(date).add(adder, unit).toDate();
}

function range(start: any, end: any, unit = 'day') {
  const datePart = fixUnit(unit);
  // because the add method will put these in tz, we have to start that way
  let current = dayjs(start).toDate();
  const days = [];

  while (lte(current, end)) {
    days.push(current);
    current = add(current, 1, datePart);
  }

  return days;
}

function ceil(date: null | undefined, unit: string) {
  const datePart = fixUnit(unit);
  const floor = startOf(date, datePart);

  return eq(floor, date) ? floor : add(floor, 1, datePart);
}

function diff(a: any, b: any, unit = 'day') {
  const datePart = fixUnit(unit);
  // don't use 'defineComparators' here, as we don't want to mutate the values
  const dtA = dayjs(a);
  const dtB = dayjs(b);
  return dtB.diff(dtA, datePart);
}

function minutes(date: any) {
  // @ts-expect-error any
  return dayjs(date).minutes();
}

function firstOfWeek() {
  return dayjs.localeData().firstDayOfWeek();
}

function firstVisibleDay(date: any) {
  return dayjs(date).startOf('month').startOf('isoWeek').toDate();
}

function lastVisibleDay(date: any) {
  return dayjs(date).endOf('month').endOf('isoWeek').toDate();
}

function visibleDays(date: any) {
  let current = firstVisibleDay(date);
  const last = lastVisibleDay(date);
  const days = [];

  while (lte(current, last)) {
    days.push(current);
    current = add(current, 1, 'd');
  }

  return days;
}
/*** END localized date arithmetic methods with dayjs ***/

/**
 * Moved from TimeSlots.js, this method overrides the method of the same name
 * in the localizer.js, using dayjs to construct the js Date
 * @param {Date} dt - date to start with
 * @param {Number} minutesFromMidnight
 * @param {Number} offset
 * @returns {Date}
 */
function getSlotDate(dt: any, minutesFromMidnight: any, offset: any) {
  return dayjs(dt)
    .startOf('day')
    .minute(minutesFromMidnight + offset)
    .toDate();
}

// dayjs will automatically handle DST differences in it's calculations
function getTotalMin(start: any, end: any) {
  return diff(start, end, 'minutes');
}

function getMinutesFromMidnight(start: any) {
  const dayStart = dayjs(start).startOf('day');
  const day = dayjs(start);
  return day.diff(dayStart, 'minutes') + getDayStartDstOffset(start);
}

// These two are used by DateSlotMetrics
function continuesPrior(start: any, first: any) {
  const djStart = dayjs(start);
  const djFirst = dayjs(first);
  return djStart.isBefore(djFirst, 'day');
}

function continuesAfter(start: any, end: any, last: any) {
  const djEnd = dayjs(end);
  const djLast = dayjs(last);
  return djEnd.isSameOrAfter(djLast, 'minutes');
}

// These two are used by eventLevels
function sortEvents({ evtA: { start: aStart, end: aEnd }, evtB: { start: bStart, end: bEnd } }: any) {
  const startSort = +startOf(aStart, 'day') - +startOf(bStart, 'day');

  const durA = diff(aStart, ceil(aEnd, 'day'), 'day');

  const durB = diff(bStart, ceil(bEnd, 'day'), 'day');

  return (
    startSort || // sort by start Day first
    Math.max(durB, 1) - Math.max(durA, 1) || // events spanning multiple days go first
    +aStart - +bStart || // then sort by start time *don't need dayjs conversion here
    +aEnd - +bEnd // then sort by end time *don't need dayjs conversion here either
  );
}

function inEventRange({ event: { start, end }, range: { start: rangeStart, end: rangeEnd } }: any) {
  const startOfDay = dayjs(start).startOf('day');
  const eEnd = dayjs(end);
  const rStart = dayjs(rangeStart);
  const rEnd = dayjs(rangeEnd);

  const startsBeforeEnd = startOfDay.isSameOrBefore(rEnd, 'day');
  // when the event is zero duration we need to handle a bit differently
  const sameMin = !startOfDay.isSame(eEnd, 'minutes');
  const endsAfterStart = sameMin ? eEnd.isAfter(rStart, 'minutes') : eEnd.isSameOrAfter(rStart, 'minutes');

  return startsBeforeEnd && endsAfterStart;
}

function isSameDate(date1: any, date2: any) {
  const dt = dayjs(date1);
  const dt2 = dayjs(date2);
  return dt.isSame(dt2, 'day');
}

/**
 * This method, called once in the localizer constructor, is used by eventLevels
 * 'eventSegments()' to assist in determining the 'span' of the event in the display,
 * specifically when using a timezone that is greater than the browser native timezone.
 * @returns number
 */
function browserTZOffset() {
  /**
   * Date.prototype.getTimezoneOffset horrifically flips the positive/negative from
   * what you see in it's string, so we have to jump through some hoops to get a value
   * we can actually compare.
   */
  const dt = new Date();
  const neg = /-/.test(dt.toString()) ? '-' : '';
  const dtOffset = dt.getTimezoneOffset();
  const comparator = Number(`${neg}${Math.abs(dtOffset)}`);
  // dayjs correctly provides positive/negative offset, as expected
  const mtOffset = dayjs().utcOffset();
  return mtOffset > comparator ? 1 : 0;
}

export const dayjsLocalizer = new DateLocalizer({
  //@ts-expect-error any
  formats,

  firstOfWeek,
  firstVisibleDay,
  lastVisibleDay,
  visibleDays,

  format(value, format, culture) {
    return locale(dayjs(value), culture).format(format);
  },

  lt,
  lte,
  gt,
  gte,
  eq,
  neq,
  merge,
  inRange,
  //@ts-expect-error any
  startOf,
  //@ts-expect-error any
  endOf,
  range,
  add,
  diff,
  //@ts-expect-error any
  ceil,
  //@ts-expect-error any
  min,
  //@ts-expect-error any
  max,
  minutes,

  getSlotDate,
  getTimezoneOffset,
  getDstOffset,
  getTotalMin,
  getMinutesFromMidnight,
  continuesPrior,
  continuesAfter,
  //@ts-expect-error any
  sortEvents,
  inEventRange,
  isSameDate,
  browserTZOffset
});
