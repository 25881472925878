import { useMemo } from 'react';
import { AccessConfigEnum, AccessService } from '../../features/access/AccessService';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { useNotificationListSelector } from '../../features/notificationList/store/notificationListSelectors';
import { StickyTabBarProps } from '../../features/ui';

export const useNavigationTabs = (config: StickyTabBarProps['items']) => {
  const activeKey = location.pathname.split('/')[1];
  const { user } = useAuthConfigSelector();
  const { unreadCount } = useNotificationListSelector();

  const tabs = useMemo(() => {
    if (user && config?.some((tab) => tab.key === activeKey)) {
      return config.map((tab) => {
        tab.active = AccessService.checkRouteAccess(tab.key as AccessConfigEnum, user.role.id);
        return tab;
      });
    }
  }, [user, activeKey, unreadCount]);

  return {
    tabs,
    activeKey
  };
};
