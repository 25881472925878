import { FC, createElement } from 'react';
import DateRangePicker from '../components/DateRangePicker/DateRangePicker';
import { MultiSelect } from '../components/Dropdown/MultiSelect';
import RadioSelect from '../components/Dropdown/RadioSelect';
import { SearchSelect } from '../components/Dropdown/SearchSelect';
import { ChangeFunction, FilterSelectItem, IBaseFilterProps, InlineFilter, InlineFilterConfigItem } from '../types';

const componentByTypes: Record<InlineFilter, FC<IBaseFilterProps>> = {
  [InlineFilter.RadioSelect]: RadioSelect,
  [InlineFilter.MultiSelect]: MultiSelect,
  [InlineFilter.DateRangePicker]: DateRangePicker,
  [InlineFilter.SearchSelect]: SearchSelect
};

export const filterFactory = (
  filterConfig: InlineFilterConfigItem,
  selected: FilterSelectItem[],
  onChange: ChangeFunction
) => {
  return createElement(componentByTypes[filterConfig.type], {
    key: filterConfig.filter.filterKey,
    ...filterConfig,
    selected,
    onChange
  });
};
