import io, { Socket as SocketIO } from 'socket.io-client';
import { baseUrl } from '../../constants';

export type Socket = SocketIO;
export type SocketDisconnect = () => void;
export type SocketListener = (...args: any[]) => void;
export type SocketEvent = { name: string; listener: SocketListener };

let Authorization = '';

const config = () => {
  if (!Authorization) {
    throw new Error('SocketService is not initiated with token');
  }
  return {
    extraHeaders: {
      Authorization,
    },
    autoconnect: true,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  };
};

// first, the service must be initiated with a token
const initiate = (token: string) => {
  Authorization = 'Bearer ' + token;
};

const connect = (events: SocketEvent[]): Socket => {
  const socket = io(baseUrl!, config());
  events.forEach(({ name, listener }) => socket.on(name, listener));
  return socket;
};

export const SocketService = {
  connect,
  initiate
};
