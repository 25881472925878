import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { FC } from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import style from './appointment-comment.module.scss';

export const AppointmentComment: FC<{
  ownerRole: UserRoleEnum;
  text: string;
  isShortenedText?: boolean;
  className?: string;
  isTester?: boolean;
}> = ({ text, ownerRole, className, isShortenedText = false, isTester = false }) => {
  const { t } = useTranslate();
  const userRoleName =  t(`common.userRolesTitles.${ownerRole}.name`);
  const label = t('features.AppointmentDetails.comment.label', { userRoleName });
  const shortenedTextClassName = isShortenedText ? style.shortenedComment : '';
  const textClassName = `${style.text} ${shortenedTextClassName}`;

  return (
    <div className={classNames(style.comment, className)}>
      <p className={style.title}>{label}</p>
      <p className={textClassName}>{text}</p>
    </div>
  );
};
