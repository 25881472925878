import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClinicService } from '../interfaces/clinicService';
import { ClinicServiceDaoService } from '../services/ClinicServiceDaoService';

export const getClinicServices = createAsyncThunk<ClinicService[]>('profile/get-clinic-services', async () => {
  const services = await ClinicServiceDaoService.getList();
  return services;
});

export const clinicServiceThunks = {
  getClinicServices
};
