import { useEffect, useState } from 'react';
import { Event } from 'react-big-calendar';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';
import { AppointmentStatusEnum } from '../../appointment/enums/appointmentEnums';
import { useAuthConfigSelector } from '../../auth/store/authSelectors';
import { DayOffResponse, ProfileDaoService } from '../../profile/services/ProfileDaoService';

export interface CalendarEvent extends Event {
  type: AppointmentTypeEnum;
  status: AppointmentStatusEnum;
  patient: string;
  appointmentId: string;
}

export const useDayoffs = (startDate?: string, endDate?: string, providerId?: string) => {
  const [dayoffs, setDayoffs] = useState<DayOffResponse[]>([]);
  const { user } = useAuthConfigSelector();

  useEffect(() => {
    const fetchDayoffs = async () => {
      try {
        const data = await ProfileDaoService.getProviderDayoffs({ id: Number(providerId) || user!.id, startDate, endDate });
        const dayOffsList = data.map(({startDate, endDate}) => {
          return {
            startDate: new Date(startDate).toISOString(),
            endDate: new Date(endDate).toISOString()
          };
        });
        setDayoffs(dayOffsList);
      } catch (error) {
        console.log('useDayoffs#fetchDayoffs', error);
      }
    };
    fetchDayoffs();
  }, [startDate, endDate]);

  return dayoffs;
};
