import { configureStore } from '@reduxjs/toolkit';
import { errorAction, listenerMiddleware } from '../common/middlewares/listener';
import { assignedProvidersReducer } from '../features/AssignedProviders/store/assignedProvidersSlice';
import { incomingNotificationReducer } from '../features/IncomingNotification/store/IncomingNotificationSlice';
import { appointmentReducer } from '../features/appointment/store/appointmentSlice';
import { appointmentCreateReducer } from '../features/appointmentCreate/store/appointmentCreateSlice';
import { appointmentsByPatientReducer } from '../features/appointmentsByPatient/store/appointmentsByPatientSlice';
import { authReducer } from '../features/auth/store/authSlice';
import { calendarReducer } from '../features/calendar/store/calendarSlice';
import { clinicServiceReducer } from '../features/clinicService/store/clinicServiceSlice';
import { notificationListReducer } from '../features/notificationList/store/notificationListSlice';
import { profileReducer } from '../features/profile/store/profileSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appointmentList: appointmentReducer,
    appointmentCreate: appointmentCreateReducer,
    appointmentsByPatient: appointmentsByPatientReducer,
    assignedProviders: assignedProvidersReducer,
    profile: profileReducer,
    incomingNotification: incomingNotificationReducer,
    notificationList: notificationListReducer,
    clinicServiceList: clinicServiceReducer,
    calendar: calendarReducer
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [errorAction.type],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta', 'payload'],
        // Ignore these paths in the state
        ignoredPaths: ['calendar.date'],
      }
    }).concat(listenerMiddleware.middleware)
});
