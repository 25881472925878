interface MicrophoneProps {
  fill?: string
  className?: string
  disabled?: boolean
}

const Microphone = (props: MicrophoneProps) => {
  return (
    props.disabled
      ? <svg className={props.className} width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1589_1200)">
          <path d="M12 14C13.66 14 14.99 12.66 14.99 11L15 5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V11C9 12.66 10.34 14 12 14ZM17.3 11C17.3 14 14.76 16.1 12 16.1C9.24 16.1 6.7 14 6.7 11H5C5 14.41 7.72 17.23 11 17.72V21H13V17.72C16.28 17.24 19 14.42 19 11H17.3Z" fill={props.fill || 'black'}/>
          <path d="M21.0807 21.4916L2.99988 1.50195" stroke="#FF0000" strokeWidth="2"/>
        </g>
        <defs>
          <clipPath id="clip0_1589_1200">
            <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      : <svg className={props.className} width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 14C13.66 14 14.99 12.66 14.99 11L15 5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V11C9 12.66 10.34 14 12 14ZM17.3 11C17.3 14 14.76 16.1 12 16.1C9.24 16.1 6.7 14 6.7 11H5C5 14.41 7.72 17.23 11 17.72V21H13V17.72C16.28 17.24 19 14.42 19 11H17.3Z" fill={props.fill || 'black'}/>
      </svg>
  );
};

export default Microphone;