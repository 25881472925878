import {
  GlobalStyles,
  MeetingProvider,
  darkTheme,
  BackgroundReplacementProvider
} from 'amazon-chime-sdk-component-library-react';
import { useLocation } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { AppointmentJoinPageContent } from '../../features/appointmentJoin/components';

export const AppointmentJoinPage = () => {
  const location = useLocation();
  const appointment = location.state?.appointment;

  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <BackgroundReplacementProvider>
        <MeetingProvider>
          <AppointmentJoinPageContent appointment={appointment} />
        </MeetingProvider>
      </BackgroundReplacementProvider>
    </ThemeProvider>
  );
};
