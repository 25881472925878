import useEvent from 'rc-util/lib/hooks/useEvent';
import raf from 'rc-util/lib/raf';
import { useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

type Updater<T> = T | ((origin: T) => T);

type UpdateCallbackFunc = VoidFunction;

type NotifyEffectUpdate = (callback: UpdateCallbackFunc) => void;

/**
 * Batcher for record any `useEffectState` need update.
 */
export function useBatcher() {
  const updateFuncRef = useRef<UpdateCallbackFunc[]>([]);

  // Notify update
  const notifyEffectUpdate: NotifyEffectUpdate = (callback) => {
    if (!updateFuncRef.current.length) {
      channelUpdate(() => {
        unstable_batchedUpdates(() => {
          updateFuncRef.current.forEach((fn) => {
            fn();
          });
          updateFuncRef.current = [];
        });
      });
    }
    updateFuncRef.current.push(callback);
  };

  return notifyEffectUpdate;
}

/**
 * Trigger state update by `useLayoutEffect` to save perf.
 */
export default function useEffectState<T extends string | number | object>(
  notifyEffectUpdate: NotifyEffectUpdate,
  defaultValue: T
): [T, (value: Updater<T>) => void] {
  const [stateValue, setStateValue] = useState(defaultValue);

  // Set State
  const setEffectVal = useEvent((nextValue: Updater<T>) => {
    notifyEffectUpdate(() => {
      setStateValue(nextValue);
    });
  });

  return [stateValue, setEffectVal];
}

function channelUpdate(callback: VoidFunction) {
  if (typeof MessageChannel === 'undefined') {
    raf(callback);
  } else {
    const channel = new MessageChannel();
    channel.port1.onmessage = () => callback();
    channel.port2.postMessage(undefined);
  }
}
