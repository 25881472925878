import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet';
import { FeatureFlags } from '../../config/featureFlags';

export const HelmetProvider = () => {
  const { t } = useTranslate();
  return (
    <Helmet>
      <link
        id="favicon"
        rel="icon"
        href={`${process.env.PUBLIC_URL}/meta/${FeatureFlags.REACT_APP_CLIENT}/favicon.ico`}
        type="image/x-icon"
      />
      <link
        rel="apple-touch-icon"
        href={`${process.env.PUBLIC_URL}/meta/${FeatureFlags.REACT_APP_CLIENT}/logo192.png`}
      />
      <link rel="manifest" href={`${process.env.PUBLIC_URL}/meta/${FeatureFlags.REACT_APP_CLIENT}/manifest.json`} />
      <title>{t('common.app.title')}</title>
    </Helmet>
  );
};
