import { useTranslate } from '@tolgee/react';
import { FC, useCallback, useEffect, useRef } from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useAdminFilters } from '../../../../common/hooks/useAdminFilters';
import { useLiveRefresh } from '../../../../common/hooks/useLiveRefresh';
import { useScrollObserver } from '../../../../common/hooks/useScrollObserver';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { mapFilterValues } from '../../../../common/utils/filters';
import { FilterSelectItem, FiltersPanel } from '../../../ui/FiltersPanel';
import { useAppointmentListSelector } from '../../store/appointmentSelectors';
import { useAppointmentActionCreators } from '../../store/appointmentSlice';
import { AppointmentList } from '../AppointmentList/AppointmentList';
import style from './appointment-container.module.scss';

export const AppointmentContainerAdmin: FC = () => {
  const { t } = useTranslate();
  const { hasNextPage } = useAppointmentListSelector();
  const appointmentActions = useAppointmentActionCreators();
  const appointmentListPage = useRef(1);
  const observerTarget = useRef<HTMLDivElement>(null);

  const { isDesktop } = useWindowSizeType();

  const { extraFilters, inlineFilters, initialValues } = useAdminFilters();
  const selectedFiltersMap = useRef<Record<string, (string | number)[]>>(mapFilterValues(initialValues));

  const fetchData = useCallback((refreshing?: boolean) => {
    appointmentActions.getList({
      page: appointmentListPage.current,
      limit: 10,
      role: UserRoleEnum.Admin,
      upcoming: true,
      ...selectedFiltersMap.current
    });
    refreshing || appointmentListPage.current++;
  }, []);

  const filtersChangeHandle = useCallback((selected: FilterSelectItem[]) => {
    selectedFiltersMap.current = mapFilterValues(selected);
    appointmentListPage.current = 1;
    fetchData();
  }, []);

  useScrollObserver({
    fetchData,
    observerTarget,
    isListView: true,
    hasNextPage
  });

  useEffect(() => {
    fetchData();
  }, []);

  useLiveRefresh({
    listener: fetchData
  });

  return (
    <div className={`${style.appointmentsContainer} ${style.provider}`}>
      {isDesktop && <p className={style.blockTitle}>{t('features.AppointmentContainerAdmin.title')}</p>}
      <FiltersPanel
        i18n={{
          resetButtonLabel: t('features.AppointmentContainerAdmin.resetBtn.label'),
          extraFiltersButtonLabel: t('features.AppointmentContainerAdmin.extraFiltersBtn.label')
        }}
        inlineFilters={inlineFilters}
        extraFilters={extraFilters}
        initialValues={initialValues}
        onChange={filtersChangeHandle}
      />
      <AppointmentList isAdmin fetchData={fetchData} />
      <div ref={observerTarget} className={style.observerTarget}></div>
    </div>
  );
};
