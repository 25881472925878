import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { NotificationAppointmentItem, NotificationItem } from '../interfaces/notification';

export interface GetListResponse {
  count: number;
  notificationList: (NotificationItem | NotificationAppointmentItem)[];
}

export interface MarkAsReadParams {
  groupId?: string;
  ids?: string[];
}

const getList = (page: number) => {
  return TelehealthHttpService.url(`/notifications/own?page=${page}&limit=10`).get<GetListResponse>();
};

const markAsRead = (params: MarkAsReadParams) => {
  return TelehealthHttpService.url('/notifications/mark-as-read').post(params);
};

const getUnreadAmount = () => {
  return TelehealthHttpService.url('/notifications/unread/amount').get<{ amount: number }>();
};

export const NotificationListDaoService = {
  getList,
  markAsRead,
  getUnreadAmount
};
