import { useTranslate } from '@tolgee/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Next } from '../../../../assets/icons/next.svg';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { ActionResultCallback } from '../../../../common/hooks/useActionResultModals';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { ConditionalWrapper } from '../../../ui/ConditionalWrapper/ConditionalWrapper';
import { Appointment } from '../../interfaces/appointment';
import { getAttendeeByRole } from '../../utils/helpers';
import { AppointmentActions } from '../AppointmentActions/AppointmentActions';
import { AppointmentStatus } from '../AppointmentStatus/AppointmentStatus';
import { AppointmentType } from '../AppointmentType/AppointmentType';
import style from './appointment-list-item.module.scss';
import { AppointmentTime } from './components/AppointmentTime';

interface Props {
  appointment: Appointment;
  onActionResponse: ActionResultCallback;
}

export const AppointmentListItemProvider: React.FC<Props> = ({ appointment, onActionResponse }) => {
  const { t } = useTranslate();
  const patient = getAttendeeByRole(appointment.attendees, UserRoleEnum.User);
  const tester = getAttendeeByRole(appointment.attendees, UserRoleEnum.Tester);
  const isPeerNavigatorAppointment = !!getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator);

  const { isDesktop } = useWindowSizeType();

  return (
    <div className={style.appointmentItemProvider}>
      <div className={style.info}>
        <div className={style.topLine}>
          <span className={style.date}>
            {appointment.seriesConfig && (
              <span className={style.reccurent}>
                <Next className={'rbc-event-type-icon'} />
              </span>
            )}
            <AppointmentTime start={appointment.startDatetime} end={appointment.endDatetime} />
          </span>
          {patient && (
            <Link to={`/patients/${patient.userId}`} className={style.accentText}>
              {patient.firstName} {patient?.lastName}
            </Link>
          )}
          <span className={style.accentText}>
            {tester && `${tester.ssiId}`}
            {!patient && !tester && t('features.AppointmentListItemProvider.noPatient.text')}
          </span>
        </div>
        <div className={style.bottomLine}>
          <AppointmentType type={appointment.type} isPeerNavigatorAppointment={isPeerNavigatorAppointment} />
          <AppointmentStatus status={appointment.status} isPeerNavigatorAppointment={isPeerNavigatorAppointment} />
        </div>
      </div>
      <ConditionalWrapper
        condition={!isDesktop}
        wrapper={(children) => <div className={style.actionsContainer}>{children}</div>}
      >
        <AppointmentActions appointment={appointment} onActionResponse={onActionResponse} />
      </ConditionalWrapper>
    </div>
  );
};
