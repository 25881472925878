import { debounce } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Input } from '../Input/Input';

type FilterInputProps = {
  filterKey: string;
  defaultValue?: string | number
  onChange: (filterKey: string, filterValue: string) => void;
};

export const FilterInput = (props: FilterInputProps) => {
  const { filterKey, defaultValue, onChange } = props;
  const [value, setValue] = useState<string | number>('');
  const debounced = useRef(false);

  useEffect(
    debounce(() => {
      (value || value === '') && onChange(filterKey, value.toString());
      debounced.current = true;
    }, 250),
    [value]
  );
  
  useEffect(() => {
    // need this to set default value for filters
    if (!value && defaultValue && !debounced.current) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };


  return <Input onChange={onChangeInput} value={value}/>;
};
