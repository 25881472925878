import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const appointmentCreateSelector = (state: RootState) => state.appointmentCreate;

const appointmentCreateStateSelector = (state: RootState) => {
  const appointmentCreateState = appointmentCreateSelector(state);
  return appointmentCreateState;
};

export const useAppointmentCreateSelector = () => {
  const appointmentCreateState = useAppSelector(appointmentCreateStateSelector);
  return appointmentCreateState;
};
