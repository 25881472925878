import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import useModal from '../../../ui';
import styles from './appointment-recurrence.module.scss';

export const useAppointmentRecurrenceConfirmModal = () => {
  const { Modal, hideModal, showModal } = useModal({});

  const AppointmentRecurrenceConfirmModal: FC<{ onOk: () => void; day: number }> = useCallback(
    ({ onOk, day }) => {
      const { t } = useTranslate();

      return (
        <Modal>
          <p className={styles.title}>{t('features.AppointmentRecurrenceConfirmModal.title')}</p>
          <div className={styles.message}>{t('features.AppointmentRecurrenceConfirmModal.count', { day })}</div>
          <div className={classNames(styles.message, styles.last)}>
            {t('features.AppointmentRecurrenceConfirmModal.message')}
          </div>

          <div className={classNames(styles.content, styles.actions)}>
            <Button
              type="primary"
              className="buttonPrimary"
              block
              onClick={() => {
                onOk();
                hideModal();
              }}
              size="large"
            >
              {t('features.AppointmentRecurrenceConfirmModal.confirmBtn.text')}
            </Button>
            <Button type="primary" className="buttonCancel" onClick={hideModal} size="large" block>
              {t('features.AppointmentRecurrenceConfirmModal.cancelBtn.text')}
            </Button>
          </div>
        </Modal>
      );
    },
    [Modal]
  );

  return {
    AppointmentRecurrenceConfirmModal,
    showAppointmentRecurrenceConfirmModal: showModal
  };
};
