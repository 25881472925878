import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { minute, second } from '../../../../common/constants';
import { AppointmentTypeEnum } from '../../../../common/enums/appointmentTypeEnum';
import { Button } from '../../../ui/index';
import { Appointment } from '../../interfaces/appointment';
import { useAppointmentListSelector } from '../../store/appointmentSelectors';
import { useAppointmentActionCreators } from '../../store/appointmentSlice';

interface Props {
  appointment: Appointment;
  title: string;
  class?: string;
}

export const AppointmentJoinAction: React.FC<Props> = ({ appointment, title, class: className }) => {
  const navigate = useNavigate();
  const [isJoinBtnEnabled, setIsJoinBtnEnabled] = useState(false);
  const appointmentActions = useAppointmentActionCreators();
  const { chatAppointment} = useAppointmentListSelector();
  const disabled = chatAppointment?.id === appointment.id;

  const joinAppointment = () => {
    if (appointment.type === AppointmentTypeEnum.text) {
      appointmentActions.setActiveChat(appointment);
    } else {
      navigate(`/join/appointments/${appointment.id}`, {
        state: {
          appointment: appointment
        }
      });
    }
  };

  useEffect(() => {
    const startTime = new Date(appointment.startDatetime).getTime();
    const endTime = new Date(appointment.endDatetime).getTime();

    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = startTime - currentTime;

      if (endTime < currentTime) {
        setIsJoinBtnEnabled(false);
        return;
      }

      if (timeDifference < minute * 5) {
        setIsJoinBtnEnabled(true);
        clearInterval(interval);
      }
    }, second);
    return () => clearInterval(interval);
  }, []);

  return (
    <Button type="primary" size="middle" disabled={!isJoinBtnEnabled || disabled} className={className} onClick={joinAppointment}>
      {title}
    </Button>
  );
};
