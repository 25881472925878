import { useTranslate } from '@tolgee/react';
import React from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { getAppointmentReviewer } from '../../../../common/helpers';
import { ActionResultCallback } from '../../../../common/hooks/useActionResultModals';
import { useUserSelector } from '../../../auth/store/authSelectors';
import { Button } from '../../../ui/index';
import { AppointmentStatusEnum } from '../../enums/appointmentEnums';
import { Appointment } from '../../interfaces/appointment';
import { useAppointmentActionCreators } from '../../store/appointmentSlice';
import { getAttendeeByRole } from '../../utils/helpers';
import { useAppointmentActionModal } from '../AppointmentActionModal/AppointmentActionModal';
import { useAppointmentRecurrenceCancelModal } from '../AppointmentRecurrence/AppointmentRecurrenceCancelModal';

interface Props {
  title: string;
  class?: string;
  appointment: Appointment;
  onActionResponse: ActionResultCallback;
}

export const AppointmentCancelAction: React.FC<Props> = ({
  appointment,
  onActionResponse,
  class: className,
  title
}) => {
  const { t } = useTranslate();
  const { AppointmentActionModal, showAppointmentActionModal } = useAppointmentActionModal(appointment);
  const { AppointmentRecurrenceCancelModal, showAppointmentRecurrenceCancelModal } =
    useAppointmentRecurrenceCancelModal();
  const appointmentActions = useAppointmentActionCreators();
  const user = useUserSelector();

  if (appointment.status === AppointmentStatusEnum.CANCELED || appointment.status === AppointmentStatusEnum.COMPLETED) {
    return null;
  }

  const provider =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.PeerNavigator) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Provider) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Admin);
  const reviewer = getAppointmentReviewer({ user, provider });

  const cancel = async (series?: boolean) => {
    const response = await appointmentActions.cancel({
      id: appointment.id,
      seriesId: series ? appointment.seriesConfig?.id : undefined
    });
    onActionResponse(response);
  };

  const openModal = appointment.seriesConfig ? showAppointmentRecurrenceCancelModal : showAppointmentActionModal;

  return (
    <>
      <Button type="primary" size="middle" className={className} onClick={openModal} disabled={appointment.status === AppointmentStatusEnum.ACTIVE}>
        {title}
      </Button>
      <AppointmentActionModal
        onOk={cancel}
        title={t('features.AppointmentCancelAction.title')}
        message={t(`features.AppointmentCancelAction.${reviewer}.message`)}
      />
      <AppointmentRecurrenceCancelModal onOk={cancel} />
    </>
  );
};
