import { useEffect, useState } from 'react';
import { Locations } from '../interfaces/locations';
import { ProfileDaoService } from '../../features/profile/services/ProfileDaoService';

export const useGetListOfProvince = () => {
  const [listLocations, setListLocations] = useState<{ value: string; label: string }[]>([]);
  useEffect(() => {
    const getListLocation = async () => {
      const locations: Locations[] = await ProfileDaoService.getListLocations();
      const locationsArr = locations.map(item => ({
        value: item.name,
        label: item.name
      }));
      setListLocations(locationsArr);
    };
    getListLocation();
  }, []);
  return {
    listLocations
  };
};
