import ReactDOM from 'react-dom/client';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { currentTheme } from './common/constants';

export enum Themes {
  CANADA = 'canada',
  JAMAICA = 'jamaica'
}

document.body.classList.add(currentTheme || Themes.JAMAICA);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
