import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import { FC } from 'react';
import { AppointmentStatusEnum } from '../../enums/appointmentEnums';
import styles from './apointment-status.module.scss';

interface Props {
  status: AppointmentStatusEnum;
  isPeerNavigatorAppointment: boolean;
  disabled?: boolean;
}

export const AppointmentStatus: FC<Props> = ({ status, isPeerNavigatorAppointment, disabled = false }) => {
  const { t } = useTranslate();
  const disabledClass = disabled ? styles.disabled : '';

  return (
    <>
      {status === AppointmentStatusEnum.REQUESTED_BY_PROVIDER && (
        <span className={classNames(styles.status, styles.statusProvider, disabledClass)}>
          {t('common.appointmentStatuses.requestedByProvider.text')}
        </span>
      )}
      {status === AppointmentStatusEnum.CONFIRMED && (
        <span
          className={classNames(
            styles.status,
            isPeerNavigatorAppointment ? styles.statusPeernavigatorAppointment : styles.statusActive,
            disabledClass
          )}
        >
          {t('common.appointmentStatuses.confirmed.text')}
        </span>
      )}
      {status === AppointmentStatusEnum.COMPLETED && (
        <span
          className={classNames(
            styles.status,
            isPeerNavigatorAppointment ? styles.statusPeernavigatorAppointment : styles.statusCompleted,
            disabledClass
          )}
        >
          {t('common.appointmentStatuses.completed.text')}
        </span>
      )}
      {status === AppointmentStatusEnum.CANCELED && (
        <span
          className={classNames(
            styles.status,
            isPeerNavigatorAppointment ? styles.statusPeernavigatorAppointment : styles.statusCanceled,
            disabledClass
          )}
        >
          {t('common.appointmentStatuses.canceled.text')}
        </span>
      )}
      {status === AppointmentStatusEnum.ACTIVE && (
        <span
          className={classNames(
            styles.status,
            isPeerNavigatorAppointment ? styles.statusPeernavigatorAppointment : styles.statusActive,
            disabledClass
          )}
        >
          {t('common.appointmentStatuses.active.text')}
        </span>
      )}
      {status === AppointmentStatusEnum.REQUESTED_BY_PATIENT && (
        <span className={classNames(styles.status, styles.statusPatient, disabledClass)}>
          {t('common.appointmentStatuses.requestedByPatient.text')}
        </span>
      )}
      {status === AppointmentStatusEnum.REQUESTED_BY_TESTER && (
        <span className={classNames(styles.status, styles.statusPeernavigatorAppointment, disabledClass)}>
          {t('common.appointmentStatuses.requestedByTester.text')}
        </span>
      )}
    </>
  );
};
