import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { defaultLocale, localesMap } from '../../localization/date';
import humanizeDuration from 'humanize-duration';
import { tolgee } from '../../app/App';
import { Locales } from '../../localization/constants';

dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localeData);
dayjs.extend(LocalizedFormat);

dayjs.locale(defaultLocale);

export const getDateLocale = (): string => dayjs.locale() || 'en';
export const getLTFormat = (): string => dayjs.Ls[getDateLocale()].formats.LT || 'h:mm a';

export const disabledPastDate = (current: Dayjs) => {
  // Can not select days before today
  return current < dayjs().startOf('day');
};

export const getDuration = (start: Dayjs | null | undefined, end: Dayjs | null | undefined) => {
  if (start && end) {
    const language = tolgee.getLanguage() as Locales;
    const diff = end.diff(start, 'ms');
    return humanizeDuration(diff, { language: localesMap[language] });
  }
  return '';
};

export function formatDate(timestampString: string, format: Intl.DateTimeFormatOptions) {
  return new Date(timestampString).toLocaleDateString(defaultLocale, format);
}

export function formatDateString(start: string, end: string): string {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startHour = startDate.getHours();
  const endHour = endDate.getHours();

  const startMinute = startDate.getMinutes().toString().padStart(2, '0');
  const endMinute = endDate.getMinutes().toString().padStart(2, '0');

  const startAmOrPm = startHour >= 12 ? 'PM' : 'AM';
  const endAmOrPm = endHour >= 12 ? 'PM' : 'AM';

  const formattedStartHour = startHour % 12 || 12;
  const formattedEndHour = endHour % 12 || 12;

  const formattedString = `${formattedStartHour}:${startMinute} ${startAmOrPm} - ${formattedEndHour}:${endMinute} ${endAmOrPm}, 
    ${startDate.toLocaleDateString(defaultLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })}`;

  return formattedString;
}

export const getLimitedNow = (minutes = 0) => {
  let now = dayjs().add(minutes, 'minutes');
  now = now.minute(Math.ceil(now.minute() / 5) * 5);
  return now;
};

export function formatDateWithDayJS(timestamp: string | number | Date, format: string) {
  return dayjs(timestamp).format(format);
}

export const getWeekDayName = (day: number) => dayjs().day(day).format('dddd');

// dateString has format "November 10, 2023"
export const isToday = (dateString: string) => {
  const today = new Date();
  const date = new Date(dateString);

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const transformToSameDate = (date: dayjs.Dayjs) => {
  return date.clone().year(2000).month(0).date(1);
};
