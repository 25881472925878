import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { buildQueryString } from '../../../common/utils/buildQueryString';

export interface DetailsByPatientListParams {
  page: number;
  limit: number;
  patientId: number;
  past: boolean;
}

const getProviderAppointmentsByPatient = ({patientId, ...paginationParams}: DetailsByPatientListParams) => {
  const queryString = buildQueryString(paginationParams);
  return TelehealthHttpService.url(`/appointment/by-patient/${patientId}?${queryString}`).get();
};

export const AppointmentsByPatientDaoService = {
  getProviderAppointmentsByPatient,
};
