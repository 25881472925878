import { createSlice } from '@reduxjs/toolkit';
import { StateStatus } from '../../../common/enums/store';
import { useActionCreators } from '../../../common/hooks/store';
import { AssignedProvidersState } from '../../../common/interfaces/store';
import { setSliceError, setSliceLoading, setSliceSuccess } from '../../../common/utils/store';
import { Attendee } from '../../appointment/interfaces/attendee';
import { assignedProvidersThunks } from './assignedProvidersActions';

export interface AssignedProvider extends Attendee {
  lastVisitedDate: string
}

export interface AssignedProvidersConfig {
  assignedProviders: AssignedProvider[],
  pharmacist: AssignedProvider | null,
  hasNextPage: true,
  status: StateStatus.INIT
}

const initialState: AssignedProvidersState = {
  assignedProviders: [],
  pharmacist: null,
  hasNextPage: true,
  status: StateStatus.INIT
};

const slice = createSlice({
  name: 'assignedProviders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      assignedProvidersActions.getAssignedProviders.fulfilled,
      setSliceSuccess((state, action) => {
        state.assignedProviders = action.payload.assignedProviders;
        state.pharmacist = action.payload.pharmacist;
      })
    );
    builder.addCase(assignedProvidersActions.getAssignedProviders.pending, setSliceLoading);
    builder.addCase(assignedProvidersActions.getAssignedProviders.rejected, setSliceError);
  }
});

export const assignedProvidersReducer = slice.reducer;

export const assignedProvidersActions = {
  ...slice.actions,
  ...assignedProvidersThunks
};

export const useAssignedProvidersCreators = () => {
  const actionCreators = useActionCreators(assignedProvidersActions);
  return actionCreators;
};
