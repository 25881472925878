import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const selector = (state: RootState) => state.clinicServiceList;

const clinicServiceListSelector = (state: RootState) => {
  const services = selector(state);
  return services;
};

export const useClinicServiceListSelector = () => {
  const state = useAppSelector(clinicServiceListSelector);
  return state;
};
