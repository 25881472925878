import { createAsyncThunk } from '@reduxjs/toolkit';
import { Appointment } from '../../appointment/interfaces/appointment';
import { AppointmentDaoService, DetailsListResponse } from '../../appointment/services/AppointmentDaoService';
import { AppointmentsByPatientDaoService, DetailsByPatientListParams } from '../services/AppointmentsByPatientDaoService';

export const getProviderAppointmentsByPatient = createAsyncThunk<DetailsListResponse & { page: number }, DetailsByPatientListParams>(
  'appointmentsByPatient/get-list',
  async (params) => {
    const appointments: DetailsListResponse = await AppointmentsByPatientDaoService.getProviderAppointmentsByPatient(params);
    return {...appointments, page: params.page};
  }
);

export const getAppointmentByPationDetails = createAsyncThunk<Appointment, string>(
  'appointmentsByPatient/get-appointment-details',
  async (appointmentId) => {
    const appointment = await AppointmentDaoService.getDetailedItem(appointmentId);
    return appointment;
  }
);

export const appointmentsByPatientThunks = {
  getProviderAppointmentsByPatient,
  getAppointmentByPationDetails
};
