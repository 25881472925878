import { useEffect, useState } from 'react';
import variables from '../../assets/styles/variables.module.scss';

const tablet = parseInt(variables.tablet);
const desktop = parseInt(variables.desktop);

type WindowSizeType = 'mobile' | 'tablet' | 'desktop';

interface WindowSize {
  device: WindowSizeType | null;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
}

export const useWindowSizeType = (): WindowSize => {
  const [device, setDevice] = useState<WindowSizeType | null>(null);

  const isDesktop = device === 'desktop';
  const isTablet = device === 'tablet';
  const isMobile = device === 'mobile';

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < tablet) {
        setDevice('mobile');
      } else if (window.innerWidth >= tablet && window.innerWidth < desktop) {
        setDevice('tablet');
      } else if (window.innerWidth >= desktop) {
        setDevice('desktop');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { device, isDesktop, isTablet, isMobile };
};
