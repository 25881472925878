export interface FilterLocale {
  resetButtonLabel: string;
  extraFiltersButtonLabel?: string;
}

export interface DataItem {
  key: string | number;
  label: string;
  disabled?: boolean;
}

export interface FilterDescriptor {
  filterKey: string;
  hidden?: boolean;
  sticky?: boolean;
}

export interface FilterConfigItem {
  filter: FilterDescriptor;
  label?: string;
  dataSource: DataItem[];
  fetchData?: (name?: string) => Promise<DataItem[]>;
  extra?: any;
  disabled?: boolean;
  hidden?: boolean;
}


export enum InlineFilter {
  MultiSelect,
  RadioSelect,
  DateRangePicker,
  SearchSelect
}

export interface InlineFilterConfigItem extends FilterConfigItem {
  type: InlineFilter;
}

export interface ExtraFilterConfigItem extends FilterConfigItem {
  label: string;
}

export interface FilterSelectItem extends DataItem, FilterDescriptor {}

export interface ChangeEventItem extends FilterSelectItem {
  value: boolean;
  radio?: boolean;
}

export type ChangeFunction = (item: ChangeEventItem) => void;

export interface IBaseFilterProps extends FilterConfigItem {
  selected: FilterSelectItem[];
  onChange: ChangeFunction;
}
