import { useTranslate } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';
import { ClinicServiceDetails } from '../../features/clinicService/components';
import { BackButton } from '../../features/ui';

export const ServiceDetailsPage = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  return (
    <div>
      <BackButton label={t('common.backBtn.label')} onClick={() => navigate(-1)} />
      <ClinicServiceDetails />
    </div>
  );
};
