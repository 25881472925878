import { useTranslate } from '@tolgee/react';
import { useLocation } from 'react-router-dom';
import { UserRoleEnum } from '../../common/enums/userRoleEnum';
import { AppointmentCreatePatientList } from '../../features/appointmentCreate/components';
import styles from './patients.module.scss';

export const PatientsPage = () => {
  const { t } = useTranslate();
  const location = useLocation();
  const providerDetails = location.state?.providerDetails;
  
  return (
    <div className={styles.patientsContainer}>
      <p className={styles.patientsTitle}>{t('pages.PatientsPage.title')}</p>
      <AppointmentCreatePatientList roles={[UserRoleEnum.User]} provider={providerDetails}/>
    </div>
  );
};
