import { useEffect } from 'react';
import { IncomingNotificationService } from '../../features/IncomingNotification/services/IncomingNotificationService';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { SocketService } from '../services/socket/socket.service';

const notificationEvents = [
  {
    name: 'new-notification',
    listener: IncomingNotificationService.saveNotification
  }
];

export const SocketProvider = () => {
  const { authorized, token } = useAuthConfigSelector();

  useEffect(() => {
    if (token && authorized) {
      SocketService.initiate(token);

      // notifications
      const socket = SocketService.connect(notificationEvents);

      return () => {
        socket.disconnect();
      };
    }
  }, [token, authorized]);

  return <></>;
};
