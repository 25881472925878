import { Button } from 'antd';
import styles from './appointment-join-control-button.module.scss';

interface ControlBarButtonProps {
  icon: JSX.Element,
  onClick?: () => void,
} 

export const ControlBarButton = (props: ControlBarButtonProps) => {
  return (
    <Button
      type="default"
      icon={<div className={styles.controlButtonIcon} >{props.icon}</div>}
      className={styles.controlButton}
      onClick={props.onClick}
    ></Button>
  );
};
