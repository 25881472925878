import { useTranslate } from '@tolgee/react';
import { useCallback, useMemo } from 'react';
import { AppointmentStatusEnum } from '../../features/appointment/enums/appointmentEnums';
import { useCalendarSelector } from '../../features/calendar/store/calendarSelectors';
import { useCalendarActionCreators } from '../../features/calendar/store/calendarSlice';
import { getNextView } from '../../features/calendar/utils';
import { FilterPanelProps, FilterSelectItem } from '../../features/ui/FiltersPanel';
import { PickerViewType } from '../../features/ui/FiltersPanel/components/DateRangePicker/DateRangePicker';
import { UserRoleEnum } from '../enums/userRoleEnum';
import { useFilters } from './useFilters';
import { useWindowSizeType } from './useWindowSizeType';

export const useProviderFilters = (
  isListView: boolean,
  role: UserRoleEnum,
  providerId?: number,
) => {
  const { device, isDesktop, isTablet, isMobile } = useWindowSizeType();
  const { view } = useCalendarSelector();
  const calendarActions = useCalendarActionCreators();
  const {t} = useTranslate();

  const datePikerControler = useMemo(() => {
    const currentView = getNextView(view, true); // => "month" | "week" | "day"
    return {
      picker: currentView === 'day' ? 'date' : (currentView as PickerViewType),
      onPickerChange: (picker: PickerViewType) => {
        calendarActions.changeView(picker === 'date' ? 'day' : picker);
      }
    };
  }, [view]);

  const { filterDateRange, filterPatientName, filterTesterSsiId, filterSort, filterStatus, filterType } =
    useFilters(datePikerControler);

  const isUserPeerNavigator = role === UserRoleEnum.PeerNavigator;

  const filterStatuses = useMemo(
    () =>
      isUserPeerNavigator
        ? {
          ...filterStatus,
          dataSource: filterStatus.dataSource.filter(
            ({ key }) => ![
              AppointmentStatusEnum.REQUESTED_BY_PATIENT,
              AppointmentStatusEnum.REQUESTED_BY_PROVIDER
            ].includes(key)
          )
        }
        : { ...filterStatus, dataSource: filterStatus.dataSource.filter(filter => filter.key !== AppointmentStatusEnum.REQUESTED_BY_TESTER) },
    [filterStatus, isUserPeerNavigator]
  );

  const filterPatient = useMemo(
    () =>
      isUserPeerNavigator
        ? filterTesterSsiId
        : { ...filterPatientName, fetchData: (fullName?: string) => filterPatientName.fetchData(fullName, providerId) },
    [filterPatientName, filterTesterSsiId, isUserPeerNavigator]
  );

  const inlineFilters = useMemo<FilterPanelProps['inlineFilters']>(
    () => [
      { ...filterSort, disabled: !isListView },
      ...(isTablet ? [filterDateRange] : []),
      ...(isDesktop ? [filterDateRange, filterStatuses, filterPatient, filterType] : [])
    ],
    [device, filterDateRange, isListView, filterStatuses]
  );

  const extraFilters = useMemo<FilterPanelProps['extraFilters']>(
    () => [
      ...(isTablet ? [filterStatuses, filterPatient, filterType] : []),
      ...(isMobile ? [filterDateRange, filterStatuses, filterPatient, filterType] : [])
    ],
    [device, filterDateRange, filterStatuses]
  );

  const initialValues: FilterSelectItem[] = [{ filterKey: 'sortBy', label: t('common.sortBy.soonest.text'), key: 'soonest', hidden: true, sticky: true }];

  const onReset = useCallback(() => {
    calendarActions.changeView('month');
  }, []);

  return { inlineFilters, extraFilters, initialValues, onReset };
};
