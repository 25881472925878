import { Dropdown, List, theme } from 'antd';
import { CSSProperties, FC, useCallback } from 'react';
import { FilterSelectItem } from '../../types';
import { FilterTag, ListItemTag } from '../FilterTag';
import Overflow from '../../../Overflow';
import styles from './list.module.scss';

const { useToken } = theme;

function getItemKey(item: FilterSelectItem) {
  return `${item.filterKey}_${item.key}`;
}

interface SelectedItemsListProps {
  values: FilterSelectItem[];
  onItemRemove: (value: FilterSelectItem) => void;
}

export const SelectedItemsList: FC<SelectedItemsListProps> = ({ values, onItemRemove }): JSX.Element => {
  const { token } = useToken();

  const renderItem = useCallback((item: FilterSelectItem, style: React.CSSProperties) => {
    return <FilterTag label={item.label} onDelete={() => onItemRemove(item)} className={styles.item} style={style} />;
  }, []);
  
  const renderRest = useCallback((items: FilterSelectItem[], style: React.CSSProperties) => {
    const containerStyle: CSSProperties = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadius,
      boxShadow: token.boxShadowSecondary,
      height: style.height,
      minWidth: 200
    };
    return (
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        dropdownRender={() => (
          <List
            style={containerStyle}
            dataSource={items}
            className={styles.list}
            renderItem={(item) => <ListItemTag label={item.label} onDelete={() => onItemRemove(item)} />}
          />
        )}
      >
        <div className={styles.tagButton} style={style}>
          <FilterTag key={'counter'} label={`See Other + ${items.length}`} className={styles.counter} />
        </div>
      </Dropdown>
    );
  }, [token]);

  return (
    <Overflow
      data={values}
      getItemKey={getItemKey}
      renderItem={renderItem}
      renderRest={renderRest}
      maxCount={'responsive'}
      className={styles.container}
    />
  );
};
