import { UserRoleEnum } from '../../common/enums/userRoleEnum';
import { FeatureFlags } from '../../config/featureFlags';

export enum AccessConfigEnum {
  providers = 'providers',
  services = 'services',
  patients = 'patients',
  'appointment-create' = 'appointment-create',
  createAppointmentButton = 'createAppointmentButton',
  appoitnemtsPatientsFilter = 'appoitnemtsPatientsFilter'
}


// Define the type for the inner object
type RoleAccess = {
  [key in UserRoleEnum]?: boolean;
};

// Define the type for accessConfig
type AccessConfig = {
  [key in AccessConfigEnum]?: RoleAccess;
};

const ALLOWED = true;

const accessConfig: AccessConfig = {
  [AccessConfigEnum.providers]: {
    [UserRoleEnum.Admin]: ALLOWED, // resolves navigation in header
  },
  [AccessConfigEnum.services]: { // resolves navigation in header
    [UserRoleEnum.Admin]: FeatureFlags.FEATURE_SERVICES_NAV_ON,
  },
  [AccessConfigEnum.patients]: { // resolves navigation in header
    [UserRoleEnum.Admin]: FeatureFlags.FEATURE_PATIENTS_NAV_ON,
    [UserRoleEnum.Provider]: ALLOWED
  },
  [AccessConfigEnum['appointment-create']]: { // resolves navigation in header
    [UserRoleEnum.Admin]: FeatureFlags.FEATURE_CREARE_APPOINTMENT_NAV_ON,
    [UserRoleEnum.Provider]: ALLOWED
  },
  [AccessConfigEnum.createAppointmentButton]: { // resolves button to create appointment on the pages
    [UserRoleEnum.Admin]: FeatureFlags.FEATURE_CREARE_APPOINTMENT_NAV_ON,
    [UserRoleEnum.Provider]: ALLOWED
  },
  [AccessConfigEnum.appoitnemtsPatientsFilter]: { // resolves button to create appointment on the pages
    [UserRoleEnum.Admin]: FeatureFlags.FEATURE_APPOINTMENTS_PATIENTS_FILTERS_ON,
    [UserRoleEnum.Provider]: ALLOWED
  },
};

const isMatchAccessConfig = (key: AccessConfigEnum, role?: UserRoleEnum): boolean => {
  const configItem = accessConfig[key];
  if (!configItem) return ALLOWED;

  if (role) {
    return !!configItem[role];
  }

  return !ALLOWED;
};

const checkRouteAccess = (key: AccessConfigEnum, role: UserRoleEnum | undefined, nestedKey?: AccessConfigEnum): boolean => {
  const isMatchKey = isMatchAccessConfig(key, role);
  const isMatchNestedKey = !nestedKey || isMatchAccessConfig(nestedKey, role);
  return isMatchKey && isMatchNestedKey;
};

export const AccessService = {
  isMatchAccessConfig,
  checkRouteAccess
};