import { useEffect, useRef } from 'react';
import { useProfileSelector } from '../../features/profile/store/profileSelectors';
import { Lang } from '../../localization/constants';

export const useChangeLanguageReaction = ({ callback }: { callback: (...params: any) => void }) => {
  const { language } = useProfileSelector();
  const prevLang = useRef<Lang | undefined>(undefined);

  useEffect(() => {
    const isInitLoading = prevLang.current === undefined;
    if (!isInitLoading && language && language !== prevLang.current) {
      callback();
    }
    prevLang.current = language;
  }, [language, callback]);
};
