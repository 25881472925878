// eslint-disable-next-line
export function buildQueryString<T extends Record<string, string | number | (string | number)[] | boolean | undefined>>(
  queryParams: T
): string {
  const queryString = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined && value !== '')
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .filter((el) => el !== undefined && el !== '')
          .map((el) => `${encodeURIComponent(key)}=${encodeURIComponent(el)}`)
          .join('&');
      }
      if (typeof value === 'boolean') {
        return `${encodeURIComponent(key)}=${value ? 'true' : 'false'}`;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value as string | number)}`;
    })
    .join('&');

  return queryString;
}

export const meetingUrlRewriter = (url: string | null) => {
  const customPort = process.env.REACT_APP_AMAZON_CHIME_PORT;
  if (!customPort) return url;
  let tempUrl = url || '';
  // Signaling
  if (tempUrl.includes('/control')) {
    tempUrl = tempUrl.replace('/control', `:${customPort}/control`);
    return tempUrl;
  }
  // TURN UDP
  if (tempUrl.includes('turn:ice.')) {
    const port = tempUrl.match(/aws:([0-9]*)/)?.[1];
    if (port) {
      tempUrl = tempUrl.replace(port, customPort);
      return tempUrl;
    }
  }
  // Audio host
  if (tempUrl.includes('.k.')) {
    const port = tempUrl.match(/aws:([0-9]*)/)?.[1];
    if (port) {
      tempUrl = tempUrl.replace(port, customPort);
      return tempUrl;
    }
  }
  return url;
};