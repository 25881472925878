import { useTranslate } from '@tolgee/react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { ClinicService } from '../../interfaces/clinicService';
import { useClinicServiceListSelector } from '../../store/clinicServiceSelectors';
import { useClinicServiceActionCreators } from '../../store/clinicServiceSlice';
import style from './clinic-service-list.module.scss';

export const ClinicServiceList = () => {
  const { t } = useTranslate();
  const { data } = useClinicServiceListSelector();
  const clinicActions = useClinicServiceActionCreators();
  const { isMobile } = useWindowSizeType();

  const columns: ColumnsType<ClinicService> = useMemo(
    () => [
      {
        title: t('features.ClinicServiceDetails.id.label'),
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: t('features.ClinicServiceDetails.name.label'),
        dataIndex: 'title',
        key: 'title',
        render: (_, record) => (
          <Link to={`/services/${record.id}`} className={style.link}>
            {record.title || record.type}
          </Link>
        )
      },
      {
        title: t('features.ClinicServiceDetails.briefDescription.label'),
        dataIndex: 'briefDescription',
        key: 'briefDescription',
        render: (text) => (isMobile ? `${text.slice(0, 30)}...` : text)
      }
    ],
    [isMobile]
  );

  useEffect(() => {
    clinicActions.getClinicServices();
  }, []);

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      className={style.clinicServiceTable}
      locale={{ emptyText: t('common.noData.label') }}
    />
  );
};
