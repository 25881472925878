import { useTranslate } from '@tolgee/react';
import { UserRoleEnum } from '../../common/enums/userRoleEnum';
import { AppointmentCreateProviderList } from '../../features/appointmentCreate/components';
import styles from './providers.module.scss';

export const ProvidersPage = () => {
  const { t } = useTranslate();

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t('pages.ProvidersPage.title')}</p>
      <AppointmentCreateProviderList roles={[UserRoleEnum.Provider, UserRoleEnum.Admin, UserRoleEnum.PeerNavigator]} />
    </div>
  );
};
