import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';
import { incomingNotificationAdapter } from './IncomingNotificationSlice';

const selectors = incomingNotificationAdapter.getSelectors();

export const useAllIncomingNotificationSelector = () => {
  const selectAllSelector = ({ incomingNotification }: RootState) => selectors.selectAll(incomingNotification);

  const state = useAppSelector(selectAllSelector);
  return state;
};

export const useIncomingNotificationIdsSelector = () => {
  const selectIdsSelector = ({ incomingNotification }: RootState) => selectors.selectIds(incomingNotification);
  
  const state = useAppSelector(selectIdsSelector);
  return state;
};
