import { useTranslate } from '@tolgee/react';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useLocales } from '../../../../common/hooks/useLocales';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { getFullName } from '../../../../common/utils/helpers';
import { AppointmentStatus } from '../../../appointment/components/AppointmentStatus/AppointmentStatus';
import { AppointmentType } from '../../../appointment/components/AppointmentType/AppointmentType';
import { Attendee } from '../../../appointment/interfaces/attendee';
import { getAttendeeByRole } from '../../../appointment/utils/helpers';
import { useAuthConfigSelector } from '../../../auth/store/authSelectors';
import { ProfileAvatar } from '../../../profile/components/ProfileAvatar/ProfileAvatar';
import { ConditionalWrapper } from '../../../ui/ConditionalWrapper/ConditionalWrapper';
import { NotificationAppointmentItem } from '../../interfaces/notification';
import { useNotificationListActionCreators } from '../../store/notificationListSlice';
import { NotificationListItemType } from '../NotificationListItemType/NotificationListItemType';
import style from './notification-list-item.module.scss';

interface Props {
  notification: NotificationAppointmentItem;
}

const SHOW_MORE_NOTIFICATIONS_COUNT = 4;

export const NotificationListAppointmentItem: React.FC<Props> = (props) => {
  const { t } = useTranslate();
  const notificationActions = useNotificationListActionCreators();
  const { user } = useAuthConfigSelector();
  useLocales();

  const { isDesktop, isTablet, isMobile } = useWindowSizeType();

  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const isCaseManager = user?.role.id === UserRoleEnum.Admin;
  const isRead = props.notification.isRead;
  const appointment = props.notification.appointment;
  const attendees: Attendee[] = appointment.attendees;

  const provider =
    getAttendeeByRole(attendees, UserRoleEnum.Provider) || getAttendeeByRole(attendees, UserRoleEnum.Admin);
  const providerFullName = getFullName(provider);
  const providerClinicalType = `${
    provider?.clinicServiceType
      ? ` ${isDesktop ? '- ' : ''} ${provider?.clinicServiceTitle || provider?.clinicServiceType}`
      : ''
  }`;

  const patient = getAttendeeByRole(attendees, UserRoleEnum.User);
  const patientName = getFullName(patient);
  const tester = getAttendeeByRole(attendees, UserRoleEnum.Tester);

  const isPeerNavigatorAppointment = !!getAttendeeByRole(attendees, UserRoleEnum.PeerNavigator);

  const appointmentTimeSlot = `${formatDateWithDayJS(appointment.startDatetime, 'LT')} - ${formatDateWithDayJS(
    appointment?.endDatetime,
    'LT'
  )}, ${formatDateWithDayJS(appointment?.startDatetime, 'MMMM DD, YYYY')}`;

  const toggleDetails = () => {
    if (!isRead) {
      notificationActions.markAsRead({ groupId: props.notification.groupId });
    }
    setIsDetailsOpen((prev) => !prev);
  };

  const appointmentLink = (
    <Link to={`/appointments/${appointment.id}`} className={style.detailsLink}>
      {t('features.NotificationListAppointmentItem.detailsLink.text')}
    </Link>
  );

  return (
    <div
      className={classNames({
        [style.notification]: true,
        [style.caseManager]: isCaseManager,
        [style.read]: isRead
      })}
    >
      <div className={style.generalInfo} onClick={toggleDetails}>
        <div className={style.unreadDotContainer}>
          <div
            className={classNames({
              [style.active]: true,
              [style.unreadDot]: true
            })}
          ></div>
        </div>

        {isCaseManager && (
          <div className={style.doctorInfo}>
            <ProfileAvatar uniqueCode={provider?.uniqueCode} className={style.profileImage} />
            <span className={style.doctorTitle}>
              <span className={style.userName}>{providerFullName}</span>
              <span className={style.clinicServiceType}>{providerClinicalType}</span>
            </span>
          </div>
        )}
        <ConditionalWrapper
          condition={isCaseManager && !isDesktop}
          wrapper={(children) => <div className={style.infoContainer}>{children}</div>}
        >
          <div className={classNames(style.patientInfo, style.userName)}>
            {tester ? (
              <span>{tester.ssiId}</span>
            ) : (
              <>
                <span>{patientName}, </span>
                <span>{patient?.uniqueCode}</span>
              </>
            )}
          </div>
          <div className={style.appointmentInfo}>
            <div className={style.timeSlot}>
              <AppointmentType
                type={appointment.type}
                disabled={isRead}
                isPeerNavigatorAppointment={isPeerNavigatorAppointment}
              />
              {appointmentTimeSlot}
            </div>
            {isDesktop ? (
              <span className={style.status}>
                <AppointmentStatus
                  status={appointment.status}
                  disabled={isRead}
                  isPeerNavigatorAppointment={isPeerNavigatorAppointment}
                />
              </span>
            ) : null}
          </div>
        </ConditionalWrapper>

        <div className={style.actions}>
          {isDesktop ? appointmentLink : null}
          <ArrowDown className={classNames('notification-icon', style.toggleDetails)} />
        </div>
      </div>
      {isTablet || isMobile ? (
        <div className={style.smallerScreenStatus}>
          <span className={style.status}>
            <AppointmentStatus
              status={appointment.status}
              disabled={isRead}
              isPeerNavigatorAppointment={isPeerNavigatorAppointment}
            />
          </span>
          {appointmentLink}
        </div>
      ) : null}
      <div className={style.time}>{formatDateWithDayJS(props.notification.notificationTime, 'LT')}</div>
      {isDetailsOpen && (
        <>
          <div className={style.divider}></div>
          <div className={style.details}>
            {(showAllNotifications
              ? appointment.notifications
              : appointment.notifications.slice(0, SHOW_MORE_NOTIFICATIONS_COUNT)
            ).map((item) => {
              return (
                <div key={item.id} className={style.notificationLine}>
                  <NotificationListItemType type={item.type} />
                  <span className={style.notificationBody}>
                    {item.body.replace(
                      '#APPOINTMENT_TIME',
                      formatDateWithDayJS(appointment.startDatetime, 'LT, MMMM DD, YYYY ')
                    )}{' '}
                    - {formatDateWithDayJS(item.createdAt, 'LT, MMMM DD, YYYY ')}
                  </span>
                </div>
              );
            })}
            {appointment.notifications.length > SHOW_MORE_NOTIFICATIONS_COUNT && !showAllNotifications && (
              <span onClick={() => setShowAllNotifications(true)} className={style.seeMore}>
                {t('features.NotificationListAppointmentItem.more.text')}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};
