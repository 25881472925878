import { useTranslate } from '@tolgee/react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { useLiveRefresh } from '../../../../common/hooks/useLiveRefresh';
import { useProviderFilters } from '../../../../common/hooks/useProviderFilters';
import { useScrollObserver } from '../../../../common/hooks/useScrollObserver';
import { mapFilterValues } from '../../../../common/utils/filters';
import { useCalendarActionCreators } from '../../../calendar/store/calendarSlice';
import { EventCalendar } from '../../../ui/EventCalendar/EventCalendar';
import { useDayoffs } from '../../../ui/EventCalendar/useDayoffs';
import { FilterSelectItem, FiltersPanel } from '../../../ui/FiltersPanel';
import { useAppointmentListSelector } from '../../store/appointmentSelectors';
import { useAppointmentActionCreators } from '../../store/appointmentSlice';
import { AppointmentList } from '../AppointmentList/AppointmentList';
import { AppointmentViewSwitch } from '../AppointmentViewSwitch/AppointmentViewSwitch';
import style from './appointment-container.module.scss';

type Props = {
  role: UserRoleEnum;
  providerId?: string;
};

export const AppointmentContainerProvider: FC<Props> = ({ role, providerId }) => {
  const { t } = useTranslate();
  const { hasNextPage, isListView = false } = useAppointmentListSelector();
  const appointmentActions = useAppointmentActionCreators();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const calendarActions = useCalendarActionCreators();
  const dayoffs = useDayoffs(startDate, endDate, providerId);

  const { extraFilters, inlineFilters, initialValues, onReset } = useProviderFilters(
    isListView,
    role,
    providerId as any
  );
  const selectedFiltersMap = useRef<Record<string, (string | number)[]>>(mapFilterValues(initialValues));

  const appointmentListPage = useRef(1);
  const observerTarget = useRef<HTMLDivElement>(null);

  const fetchData = useCallback(
    (refreshing?: boolean) => {
      appointmentActions
        .getList({
          page: appointmentListPage.current,
          limit: isListView ? 10 : 10000,
          role,
          upcoming: true,
          providerId: providerId ? [providerId] : [],
          ...selectedFiltersMap.current
        })
        .then(() => {
          if (isListView && !refreshing) appointmentListPage.current++;
        });
    },
    [isListView]
  );

  const filtersChangeHandle = useCallback(
    (selected: FilterSelectItem[]) => {
      selectedFiltersMap.current = mapFilterValues(selected);
      appointmentListPage.current = 1;
      fetchData();
      setStartDate(selectedFiltersMap.current.startDatetime?.[0] as string);
      calendarActions.changeDate(selectedFiltersMap.current.startDatetime?.[0] as string);
      setEndDate(selectedFiltersMap.current.endDatetime?.[0] as string);
    },
    [fetchData]
  );

  useScrollObserver({
    fetchData,
    observerTarget,
    isListView,
    hasNextPage
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useLiveRefresh({
    listener: fetchData
  });

  const switcher = <AppointmentViewSwitch />;

  return (
    <div className={style.provider}>
      <FiltersPanel
        i18n={{
          resetButtonLabel: t('features.AppointmentContainerProvider.resetBtn.label'),
          extraFiltersButtonLabel: t('features.AppointmentContainerProvider.extraFiltersBtn.label')
        }}
        inlineFilters={inlineFilters}
        extraFilters={extraFilters}
        initialValues={initialValues}
        onChange={filtersChangeHandle}
        onReset={onReset}
      />
      {isListView && <div className={style.switch}>{switcher}</div>}
      <div className={style.appointmentsContainer}>
        {isListView ? (
          <>
            <AppointmentList fetchData={fetchData} />
            <div ref={observerTarget} className={style.observerTarget}></div>
          </>
        ) : (
          <EventCalendar switcher={switcher} startDate={startDate} endDate={endDate} dayoffs={dayoffs} />
        )}
      </div>
    </div>
  );
};
