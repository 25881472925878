import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import styles from './tag.module.scss';

export interface FilterTagProps {
  label: string;
  onDelete?: () => void;
  className?: string;
  style?: CSSProperties;
}

export const FilterTag: FC<FilterTagProps> = ({ label, onDelete, className, style }) => {
  return (
    <div className={classNames(styles.tag, className)} style={style}>
      <div className={styles.label}>{label}</div>
      {onDelete && (
        <Button
          type='text'
          icon={<CloseOutlined />}
          className={styles.close}
          onClick={onDelete}
          size='small'
          shape='circle'
        />
      )}
    </div>
  );
};
