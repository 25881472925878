import { useTranslate } from '@tolgee/react';
import { Dropdown } from 'antd';
import { ReactComponent as UserIcon } from '../../../assets/icons/user-icon.svg';
import { useAuthActionCreators } from '../../auth/store/authSlice';
import { LanguageSwitcher } from '../LanguageSwithcer/LanguageSwitcher';
import style from './user-actions.module.scss';

export const UserActions = () => {
  const { t } = useTranslate();
  const authActions = useAuthActionCreators();
  const items = [
    {
      key: '1',
      label: t('features.RoutedTabs.logout.name'),
      onClick: async () => {
        await authActions.logout();
      }
    }
  ];

  return (
    <div className={style.container}>
      <div className={style.rightGap}>
        <LanguageSwitcher />
      </div>
      <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
        <UserIcon className={style.iconUser} />
      </Dropdown>
    </div>
  );
};
