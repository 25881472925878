import { T } from '@tolgee/react';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavigationTabs } from '../../../common/hooks/useNavigationTabs';
import { useAuthActionCreators } from '../../auth/store/authSlice';
import { NotificationListCount } from '../../notificationList/components';
import { useNotificationListSelector } from '../../notificationList/store/notificationListSelectors';
import { NavKeys, ServiceKeys } from '../../routedTabs/RoutedTabs';
import { LanguageSwitcher } from '../LanguageSwithcer/LanguageSwitcher';
import style from './mobile-menu.module.scss';

export const MobileMenu = () => {
  const authActions = useAuthActionCreators();
  const [isNavigationListOpened, setIsNavigationListOpened] = useState(false);
  const { unreadCount } = useNotificationListSelector();
  const navigate = useNavigate();

  const config = [
    {
      label: <T keyName="features.RoutedTabs.appointments.name" />,
      key: NavKeys.appointments
    },
    {
      label: <T keyName="features.RoutedTabs.providers.name" />,
      key: NavKeys.providers
    },
    {
      label: <T keyName="features.RoutedTabs.patients.name" />,
      key: NavKeys.patients
    },
    {
      label: <T keyName="features.RoutedTabs.services.name" />,
      key: NavKeys.services
    },
    {
      label: <NotificationListCount count={unreadCount} />,
      key: NavKeys.notifications
    },
    {
      label: <T keyName="features.RoutedTabs.personalInfo.name" />,
      key: NavKeys.personalInformation
    },
    {
      label: <T keyName="features.RoutedTabs.logout.name" />,
      key: ServiceKeys.layout
    },
    {
      label: <LanguageSwitcher />,
      key: ServiceKeys.langSwitch
    }
  ];

  const { tabs, activeKey } = useNavigationTabs(config);
  const activeItem = tabs?.find((item) => item.key === activeKey);

  const menuItemClickHandler = async (key: string) => {
    switch (key) {
      case ServiceKeys.layout:
        await authActions.logout();
        setIsNavigationListOpened(false);
        break;
      case ServiceKeys.langSwitch:
        break;
      default:
        navigate(`/${key}`);
        setIsNavigationListOpened(false);
    }
  };

  return (
    <>
      <div className={style.topLine}>
        <div
          className={classNames(style.burger, { [style.disabled]: activeKey === 'login' })}
          onClick={() => setIsNavigationListOpened(true)}
        >
          <span className={classNames(style.line, style.line1)}></span>
          <span className={classNames(style.line, style.line2)}></span>
          <span className={classNames(style.line, style.line3)}></span>
        </div>
        <div className={style.activeItem}>{activeItem?.label}</div>
      </div>
      {isNavigationListOpened && (
        <div className={style.navigationList}>
          <div className={style.close} onClick={() => setIsNavigationListOpened(false)}>
            <span className={classNames(style.line, style.line1)}></span>
            <span className={classNames(style.line, style.line2)}></span>
          </div>
          {tabs
            ?.filter((item) => item.active)
            .map((item) => {
              return (
                <div key={item.key} className={style.listItem} onClick={() => menuItemClickHandler(item.key)}>
                  {item.label}
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};
