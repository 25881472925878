import {
  bindActionCreators,
  type ActionCreator,
  type ActionCreatorsMapObject,
  type AsyncThunk
} from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux';
import { AppDispatch, RootState } from '../interfaces/store';

export const useAppDispatch = useDispatch<AppDispatch>;
export const useStateSelector: TypedUseSelectorHook<RootState> = useSelector;

type BoundAsyncThunk<Action extends ActionCreator<any>> = (
  ...args: Parameters<Action>
) => ReturnType<ReturnType<Action>>;

type BoundActions<Actions extends ActionCreatorsMapObject> = {
  [key in keyof Actions]: Actions[key] extends AsyncThunk<any, any, any> ? BoundAsyncThunk<Actions[key]> : Actions[key];
};

export const useActionCreators = <Actions extends ActionCreatorsMapObject = ActionCreatorsMapObject>(
  actions: Actions
): BoundActions<Actions> => {
  const dispatch = useAppDispatch();

  return useMemo(() => bindActionCreators(actions, dispatch), []);
};

export const useAppSelector = <
  V extends any[],
  T extends (state: RootState, ...args: V) => ReturnType<T> = (state: RootState, ...args: V) => any
>(
    selector: T,
    ...args: V
  ): ReturnType<T> => {
  const item = useStateSelector((state) => selector(state, ...args));
  return item;
};
