export enum AppointmentStatusEnum {
  REQUESTED_BY_PROVIDER,
  REQUESTED_BY_PATIENT,
  CONFIRMED,
  CANCELED,
  ACTIVE,
  COMPLETED,
  REQUESTED_BY_TESTER
}

export enum AppointmentKind {
  Clinician = 'Clinician Appointment',
  CaseManager = 'Case Manager Appointment',
  Pharmacist = 'Pharmacist Appointment',
  ContactInvestigator = 'Contact Investigator Appointment'
}

export enum AttendeeStatusEnum {
  OWNER,
  REQUESTED,
  CONFIRMED,
  CANCELED,
  WAITING_CONFIRM,
  GUEST,
}

export enum AppointmentSeriesIntervalUnitEnum {
  WEEK = 'week',
  MONTH = 'month'
}
export type AppointmentSerieEndOption = 'never' | 'after' | 'on';

export enum AppointmentSerieEndOptionEnum {
  NEVER = 'never',
  AFTER = 'after',
  ON = 'on'
}

export enum DayOfTheWeekEnum {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
}

export enum AppointmentSeriesMonthWeekEnum {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  LAST = 5
}
