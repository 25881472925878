import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const selector = (state: RootState) => state.calendar;

const calendarSelector = (state: RootState) => {
  return selector(state);
};

export const useCalendarSelector = () => {
  const calendarState = useAppSelector(calendarSelector);
  return calendarState;
};
