import Icon, { SearchOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Badge, Button, Checkbox, Collapse, CollapseProps, ConfigProvider, Dropdown, Input, List, theme } from 'antd';
import { debounce } from 'lodash';
import { CSSProperties, ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/arrow-down.svg';
import { ReactComponent as Filter } from '../../../../../assets/icons/filter.svg';
import { useWindowSizeType } from '../../../../../common/hooks/useWindowSizeType';
import { ChangeFunction, DataItem, ExtraFilterConfigItem, FilterDescriptor, FilterSelectItem } from '../../types';
import DateRangePicker from '../DateRangePicker/DateRangePicker';
import styles from './dropdown.module.scss';
import { useTheme } from '../../../../../common/providers/ThemeProvider';

const CheckboxList = ({
  dataSource,
  filter,
  onChange,
  selected,
  fetchData
}: {
  filter: FilterDescriptor;
  dataSource: DataItem[];
  onChange: ChangeFunction;
  selected: FilterSelectItem[];
  fetchData?: ExtraFilterConfigItem['fetchData'];
}) => {
  const { t } = useTranslate();
  const [data, setData] = useState(dataSource);
  const [loading, setLoading] = useState(false);
  const searchRef = useRef<string | undefined>();

  const fetchListData = useCallback(async (name?: string) => {
    if (fetchData) {
      setLoading(true);
      searchRef.current = name;
      const items = await fetchData(name);
      if (searchRef.current === name) {
        setData(items);
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (dataSource.length === 0) {
      fetchListData();
    }
  }, []);

  const onInputChange = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      fetchListData(e.target.value);
    }, 500),
    []
  );

  return (
    <>
      {fetchData && (
        <div className={styles.search}>
          <Input
            placeholder={t('common.enterName.label')}
            bordered={false}
            onChange={onInputChange}
            suffix={<SearchOutlined className={styles.searchIcon} />}
            allowClear
          />
        </div>
      )}
      <List
        dataSource={data}
        className={styles.list}
        renderItem={(item) => (
          <List.Item>
            <Checkbox
              value={item.key}
              key={item.key}
              checked={selected.some((select) => select.filterKey === filter.filterKey && select.key === item.key)}
              disabled={item.disabled}
              onChange={(e) => {
                onChange({ ...filter, key: item.key, label: item.label, value: e.target.checked });
              }}
            >
              {item.label}
            </Checkbox>
          </List.Item>
        )}
        loading={loading}
      />
    </>
  );
};

const { useToken } = theme;

export type ButtonDropdownProps = {
  filters: ExtraFilterConfigItem[];
  onChange: ChangeFunction;
  selected: FilterSelectItem[];
  label?: string;
};

export const ButtonDropdown: FC<ButtonDropdownProps> = ({ filters, onChange, selected, label }) => {
  const { token } = useToken();
  const { isMobile } = useWindowSizeType();

  const containerStyle: CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadius,
    boxShadow: token.boxShadowSecondary,
    width: 320,
    padding: 8
  };

  const items = useMemo<CollapseProps['items']>(() => {
    return filters.map(({ filter, label, dataSource, fetchData, extra }) => {
      if (filter.filterKey === 'date-range') {
        return {
          key: filter.filterKey,
          label,
          children: (
            <DateRangePicker
              filter={filter}
              onChange={onChange}
              selected={selected}
              dataSource={dataSource}
              extra={extra}
            />
          )
        };
      } else {
        return {
          key: filter.filterKey,
          label,
          children: (
            <CheckboxList
              filter={filter}
              dataSource={dataSource}
              onChange={onChange}
              selected={selected}
              fetchData={fetchData}
            />
          ),
          className: styles.content
        };
      }
    });
  }, [filters, onChange, selected]);

  const showDot = filters.some(({ filter }) =>
    selected.some((select) => {
      if (filter.filterKey === 'date-range') {
        return select.filterKey === 'startDatetime' || select.filterKey === 'endDatetime';
      }
      return select.filterKey === filter.filterKey;
    })
  );
  const dropdownPlacement = !isMobile ? 'bottomRight' : 'bottomCenter';
  const {colors} = useTheme();

  return (
    <Dropdown
      trigger={['click']}
      placement={dropdownPlacement}
      dropdownRender={() => (
        <Collapse
          accordion
          style={containerStyle}
          items={items}
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <Icon component={ArrowDown} rotate={isActive ? 180 : 0} className={styles.arrow} />
          )}
          bordered={false}
        />
      )}
    >
      {label ? (
        <Badge dot={showDot} className={styles.badge} offset={[-12, 8]}>
          <ConfigProvider
            theme={{
              token: { colorLink: colors.primary },
              components: {
                Button: {
                  fontWeight: 600,
                  fontSize: 13
                }
              }
            }}
          >
            <Button type="link" className={styles.link}>
              {label}
            </Button>
          </ConfigProvider>
        </Badge>
      ) : (
        <Badge dot={showDot} className={styles.badge} offset={[-6, 6]}>
          <Button className={styles.iconButton} type="primary" icon={<Filter />} />
        </Badge>
      )}
    </Dropdown>
  );
};
