import Icon from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Checkbox, List, Select } from 'antd';
import classNames from 'classnames';
import { memo, useState } from 'react';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { ReactComponent as Checkmark } from '../../../../assets/icons/checkmark.svg';
import { Views } from '../../../calendar/interfaces';
import { useCalendarSelector } from '../../../calendar/store/calendarSelectors';
import { useCalendarActionCreators } from '../../../calendar/store/calendarSlice';
import { getNextView } from '../../../calendar/utils';
import styles from '../styles/select.module.scss';

const ToggleViews = [Views.DAY, Views.WEEK, Views.MONTH];

const ViewSelect = () => {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const { showWeekends, view } = useCalendarSelector();
  const calendarActions = useCalendarActionCreators();

  const onShowWeekends = () => {
    calendarActions.toggleShowWeekends();
  };

  const value = getNextView(view, true);
  
  return (
    <Select
      key={'select'}
      open={open}
      bordered={false}
      value={value}
      suffixIcon={<Icon component={ArrowDown} rotate={open ? 180 : 0} className={styles.arrow} />}
      onDropdownVisibleChange={setOpen}
      className={styles.select}
      popupClassName={styles.popup}
      popupMatchSelectWidth={false}
      dropdownRender={() => (
        <List
          dataSource={ToggleViews}
          className={styles.selectList}
          renderItem={(item) => (
            <List.Item>
              <Checkbox
                value={item}
                key={item}
                checked={item === value}
                onChange={(e) => {
                  calendarActions.changeView(item);
                  setOpen(false);
                }}
                className={styles.checkbox}
              >
                {t(`common.${item}.label`)}
              </Checkbox>
            </List.Item>
          )}
          footer={
            <div
              className={classNames(styles.footer, { [styles.disabledItem]: view === Views.DAY })}
              onClick={onShowWeekends}
            >
              <Checkmark className={classNames(styles.checkmark, { [styles.checked]: showWeekends })} />
              {t('features.EventCalendar.viewSelect.showWeekends.text')}
            </div>
          }
        />
      )}
    />
  );
};

export default memo(ViewSelect);
