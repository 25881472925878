import classNames from 'classnames';
import { FC, useState } from 'react';
import { ReactComponent as Avatar } from '../../../../assets/icons/default-avatar.svg';
import { ReactComponent as Patient } from '../../../../assets/icons/patient-avatar.svg';
import { s3BucketUrl } from '../../../../common/constants';
import styles from './profile-avatar.module.scss';

export const ProfileAvatar: FC<{
  uniqueCode?: string | null;
  size?: number;
  className?: string;
  isPatient?: boolean;
}> = ({ uniqueCode, size = 72, className, isPatient }) => {
  const [isErrorWithLoading, setIsErrorWithLoading] = useState<boolean>(false);

  const profileImage = `${s3BucketUrl}/profile-images/${uniqueCode}.jpeg`;

  return (
    <>
      {!isErrorWithLoading ? (
        <img
          src={profileImage}
          onError={() => setIsErrorWithLoading(true)}
          className={classNames(styles.avatar, className)}
          alt="avatar"
          style={{ width: size, height: size }}
        />
      ) : isPatient ? (
        <Patient className={classNames(styles.patient, className)} style={{ width: size, height: size }} />
      ) : (
        <Avatar className={classNames(styles.avatar, className)} style={{ width: size, height: size }} />
      )}
    </>
  );
};
