import { SortOrder } from 'antd/es/table/interface';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ClinicalServiceFiltersMap } from '../../features/appointmentCreate/components/AppointmentCreatePatientList/AppointmentCreatePatientList';
import {
  AppointmentCreateDaoService,
  UserInfo
} from '../../features/appointmentCreate/services/AppointmentCreateDaoService';
import { SortBy } from '../enums/sortByEnum';
import { ColumnKeysEnum } from '../enums/usersTableEnum';
import { User } from '../interfaces/user';
import { useChangeLanguageReaction } from './useChangeLanguageReaction';
import { ClinicServiceDaoService } from '../../features/clinicService/services/ClinicServiceDaoService';
import { ClinicService, ClinicServiceFilter } from '../../features/clinicService/interfaces/clinicService';

export const useUserDataSource = ({initialParams, initialPage, initialPageSize, initialSorting, provider, empty}:
{  initialParams: { [key: string]: string | number | boolean | (string | number)[] },
  provider?: User | null,
  initialPage?: number,
  initialPageSize?: number,
  initialSorting?: [ColumnKeysEnum, SortOrder] | null,
  empty?: boolean;
}) => {
  const [dataSource, setDataSource] = useState<UserInfo[]>([]);
  const [pageSize, setPageSize] = useState(initialPageSize || 8);
  const [currentPage, setCurrentPage] = useState(initialPage || 1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState<{ [key: string]: string | number | string[] }>({});
  const [clinicalTypeOptions, setClinicalTypeOptions] = useState<ClinicServiceFilter[]>();
  const paramsSnapshotRef = useRef<{ [key: string]: string | number }>();
  const [sorting, setSorting] = useState<[ColumnKeysEnum, SortOrder] | null>(initialSorting || null);

  const fetchData = useCallback(async (
    currentPage: number,
    pageSize: number,
    filters: { [key: string]: string | number | string[] },
    initialParams: { [key: string]: string | number | boolean | (string | number)[] },
    sorting: [ColumnKeysEnum, SortOrder] | null
  ) => {
    try {
      const currentSortBy = sorting?.[0];
      const currentDirection = sorting?.[1];

      const params = {
        ...initialParams,
        page: currentPage,
        limit: pageSize,
        ...filters,
        ...(filters.phone && { phone: filters.phone.toString() }),
        ...(currentSortBy && { sortBy: currentSortBy }),
        ...(currentDirection && { direction: currentDirection === 'ascend' ? SortBy.ASC : SortBy.DESC })
      };
      paramsSnapshotRef.current = params;
      const response = await AppointmentCreateDaoService.getUsers(params);
      if (params === paramsSnapshotRef.current) {
        setDataSource(response.items);
        setTotalRecords(response.count);
      }
    } catch (error) {
      console.log('useUserDataSource#fetchData', error);
    }
  }, []);

  useChangeLanguageReaction({callback: () => fetchData(currentPage, pageSize, filters, initialParams, sorting)});

  const debouncedFetchData = useCallback(
    debounce((currentPage, pageSize, filters, initialParams, sorting) => {
      fetchData(currentPage, pageSize, filters, initialParams, sorting);
    }, 300),
    []
  );

  useEffect(() => {
    if (empty) {
      setDataSource([]);
      setTotalRecords(0);
      setCurrentPage(1);
    } else {
      debouncedFetchData(currentPage, pageSize, filters, initialParams, sorting);
    }
  }, [currentPage, pageSize, filters, JSON.stringify(initialParams), sorting, empty]);

  const onFilterChange = useCallback((filterKey: string, filterValue: string | string[]) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: filterValue
    }));
    setCurrentPage(1);
  }, []);

  const onSortingChange = useCallback((columnKey: ColumnKeysEnum, direction?: SortOrder) => {
    const currentSortBy = sorting?.[0];
    const currentDirection = sorting?.[1];
    if (!currentSortBy || currentDirection !== direction) {
      setSorting([columnKey, direction || 'ascend']);
    }
  }, [sorting]);

  useEffect(() => {
    if (provider && provider.clinicServiceType) {
      const {clinicServiceType, prefix, firstName, lastName} = provider;
      const filterKey = ClinicalServiceFiltersMap[clinicServiceType];
      const providerName = `${prefix || ''} ${firstName || ''} ${lastName || ''}`;
      onFilterChange(filterKey, providerName.trim());
    }
  }, [provider]);

  useEffect(() => {
    const getClinicsTypes = async () =>{
      const response: ClinicService[] = await ClinicServiceDaoService.getList();
      const transformedResponse: ClinicServiceFilter[] = response.map(clinic => ({label: clinic.type.toString(), value: clinic.title !== null ? clinic.title : ''}));
      setClinicalTypeOptions(transformedResponse);
    };
    getClinicsTypes();
  }, []);

  return {
    onFilterChange,
    dataSource,
    totalRecords,
    setPageSize,
    pageSize,
    currentPage,
    filters,
    sorting,
    setCurrentPage,
    onSortingChange,
    clinicalTypeOptions
  };
};
