import { CloseOutlined } from '@ant-design/icons';
import { Button, List } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { FilterTagProps } from './FilterTag';
import styles from './tag.module.scss';

export const ListItemTag: FC<FilterTagProps> = ({ label, onDelete, className, style }) => {
  return (
    <List.Item className={classNames(styles.listTag, className)} style={style}>
      <Button
        type="text"
        icon={<CloseOutlined style={{ width: '15px', fontSize: '13px' }} />}
        className={styles.listTagClose}
        onClick={onDelete}
        shape="circle"
      />
      <div className={styles.listTagLabel}>{label}</div>
    </List.Item>
  );
};
