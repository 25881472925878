import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { AssignedProvidersResponse } from '../store/assignedProvidersActions';

const getAssignedProviders = (userId: string): Promise<AssignedProvidersResponse> => {
  return TelehealthHttpService.url(`/users/${userId}/assigned-providers`).get<AssignedProvidersResponse>();
};

export const ProviderService = {
  getAssignedProviders,
};
