import { useTranslate } from '@tolgee/react';
import { Form } from 'antd';
import type { FC, ReactNode } from 'react';
import { specialSymbolPattern } from '../../../../common/constants';
import { ValidateFormStatus } from '../../../../common/interfaces/validateFormStatus';
import { Button, Input } from '../../../ui';
import { ChangePasswordFormValues } from '../../interfaces/authConfig';
import styles from '../../styles/forms.module.scss';

export interface ChangePasswordProps {
  onFinish: (values: ChangePasswordFormValues) => void;
  onChange: (changedValues: any, values: ChangePasswordFormValues) => void;
  validateStatus?: ValidateFormStatus;
  help?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const AuthChangePassword: FC<ChangePasswordProps> = ({
  onFinish,
  onChange,
  validateStatus,
  help,
  disabled,
  loading
}) => {
  const { t } = useTranslate();
  const byReason = (reason: string) => t('features.AuthChangePassword.passwordInpt.errValidation', { reason });
  const rules = [
    { required: true, message: t('features.AuthChangePassword.passwordInpt.errEmpty') },
    { min: 8, message: byReason(t('features.AuthChangePassword.passwordInpt.errMinLength')) },
    { max: 64, message: byReason(t('features.AuthChangePassword.passwordInpt.errMaxLength')) },
    { pattern: specialSymbolPattern, message: byReason(t('features.AuthChangePassword.passwordInpt.errPattern')) }
  ];

  return (
    <Form
      style={{ width: '100%' }}
      name="change-password"
      onFinish={onFinish}
      size="large"
      onValuesChange={onChange}
      disabled={disabled}
    >
      <Form.Item
        name="password"
        className={styles.formItem}
        rules={rules}
        validateStatus={validateStatus}
        validateFirst
      >
        <Input placeholder={t('features.AuthChangePassword.passwordInpt.placeholder')} type="password" />
      </Form.Item>
      <Form.Item
        name="password2"
        className={styles.formItem}
        rules={[
          ...rules,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('features.AuthChangePassword.retypePasswordInpt.errMismatch')));
            }
          })
        ]}
        validateStatus={validateStatus}
        help={help}
        dependencies={['password']}
        validateFirst
      >
        <Input placeholder={t('features.AuthChangePassword.retypePasswordInpt.placeholder')} type="password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block className={styles.button} loading={loading}>
          {t('features.AuthChangePassword.submitBtn.text')}
        </Button>
      </Form.Item>
    </Form>
  );
};
