import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { useTranslate } from '@tolgee/react';
import { Button, Input, Spin } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { ReactComponent as Send } from '../../../assets/icons/send.svg';
import { useSimpleConfirmModal } from '../Modal/useConfirmModal';
import { ChatMessageList } from './ChatMessageList';
import styles from './chat.module.scss';
import { ChatMessage, ChatUser, SendFunction } from './interfaces';

const { TextArea } = Input;

type ChatProps = {
  name: string;
  date: string;
  messages: ChatMessage[];
  users: ChatUser[];
  userId: string;
  onSend: SendFunction;
  onClose: () => void;
  className: string;
  loading: boolean;
  disabled?: boolean;
};

export const Chat: FC<ChatProps> = ({
  messages,
  name,
  date,
  users,
  userId,
  onSend,
  onClose,
  disabled,
  className,
  loading
}) => {
  const { t } = useTranslate();
  const [message, setMessage] = useState('');
  const trimmedMessage = message.trim();

  const sendMessage = () => {
    onSend(trimmedMessage);
    setMessage('');
  };

  const onPressEnter: TextAreaProps['onPressEnter'] = (e) => {
    if (!trimmedMessage) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (e.shiftKey || e.altKey || e.ctrlKey) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    sendMessage();
  };

  const { ConfirmModal, showConfirmModal } = useSimpleConfirmModal();

  return (
    <div className={classNames(styles.container, className)}>
      <ConfirmModal onOk={onClose} title={t('features.Chat.confirmation.text')} />
      <div className={styles.header}>
        <div>{date}</div>
        <div>{name}</div>
        <div className={styles.count}>{t('features.Chat.attendeesCount', { amount: users.length })}</div>
        <Button icon={<CloseOutlined />} className={styles.closeButton} onClick={showConfirmModal} type="link" />
      </div>
      <Spin spinning={loading} wrapperClassName={styles.spinner}>
        <ChatMessageList messages={messages} userId={userId} onSend={onSend} />
        <div className={styles.footer}>
          <TextArea
            value={message}
            className={styles.input}
            placeholder={t('features.Chat.textArea.placeholder')}
            autoSize={{ maxRows: 4 }}
            onChange={(e) => setMessage(e.target.value)}
            onPressEnter={onPressEnter}
          />
          <Button
            icon={<Send />}
            className={styles.sendButton}
            type="link"
            onClick={sendMessage}
            disabled={!trimmedMessage || disabled}
          />
        </div>
      </Spin>
    </div>
  );
};
