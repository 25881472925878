import { useTranslate } from '@tolgee/react';
import { ReactNode, Suspense } from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { LoaderData } from '../../../common/interfaces/loader';
import { ErrorPage } from '../../../pages';

export const AsyncLoader = <T,>({ render }: { render: (value: T) => ReactNode }) => {
  const { t } = useTranslate();
  const { promise } = useLoaderData() as LoaderData<T>;

  return (
    <Suspense fallback={<p>{t('common.loader.text')}</p>}>
      <Await resolve={promise} errorElement={<ErrorPage />}>
        {render}
      </Await>
    </Suspense>
  );
};
