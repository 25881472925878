import { Affix } from 'antd';
import { FC, useCallback, useEffect } from 'react';
import { useAppointmentListSelector } from '../../features/appointment/store/appointmentSelectors';
import { useAppointmentActionCreators } from '../../features/appointment/store/appointmentSlice';
import { AppointmentJoinChat } from '../../features/appointmentJoin/components/AppointmentJoinChat/AppointmentJoinChat';
import { AppointmentJoinChatConfirm } from '../../features/appointmentJoin/components/AppointmentJoinChat/AppointmentJoinChatConfirm';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { UserRoleEnum } from '../enums/userRoleEnum';
import style from './../../features/ui/Chat/chat.module.scss';

export const AppointmentChatProvider: FC = () => {
  const { chatAppointment: appointment } = useAppointmentListSelector();
  const appointmentActions = useAppointmentActionCreators();
  const { user, authorized } = useAuthConfigSelector();
  const isAdmin = user?.role.id === UserRoleEnum.Admin;

  const close = useCallback(() => {
    appointmentActions.resetActiveChat();
  }, []);

  useEffect(() => {
    if (!authorized) {
      close();
    }
  }, [authorized]);

  if (!appointment) return null;

  return (
    <AppointmentJoinChatConfirm appointment={appointment} onClose={close}>
      <Affix
        className={style.affix}
        offsetTop={isAdmin ? AppointmentJoinChat.adminTopOffset : AppointmentJoinChat.providerTopOffset}
      >
        <AppointmentJoinChat key={appointment.id} appointment={appointment} close={close} isAdmin={isAdmin} />
      </Affix>
    </AppointmentJoinChatConfirm>
  );
};
