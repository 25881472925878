import { useTranslate } from '@tolgee/react';
import { useState } from 'react';
import { FilterPanelProps, FilterSelectItem } from '../../features/ui/FiltersPanel';
import { PickerViewType } from '../../features/ui/FiltersPanel/components/DateRangePicker/DateRangePicker';
import { useFilters } from './useFilters';
import { useWindowExtraSizeType } from './useWindowExtraSizeType';

export const useAdminFilters = () => {
  const device = useWindowExtraSizeType();
  const [picker, setPicker] = useState<PickerViewType>('date');
  const { t } = useTranslate();

  const {
    filterDateRange,
    filterPatientName,
    filterType,
    filterSort,
    filterStatus,
    filterProviderName,
    filterProviderType,
    filterTesterSsiId
  } = useFilters({ picker, onPickerChange: setPicker });

  const inlineFiltersMap: Record<typeof device, FilterPanelProps['inlineFilters']> = {
    mobile: [filterSort],
    tablet: [filterSort, filterDateRange],
    tabletL: [filterSort, filterDateRange, filterStatus],
    tabletLL: [filterSort, filterDateRange, filterStatus, filterPatientName],
    desktop: [filterSort, filterDateRange, filterStatus, filterPatientName, filterType],
    desktopL: [filterSort, filterDateRange, filterStatus, filterPatientName, filterType, filterProviderType],
    desktopLL: [
      filterSort,
      filterDateRange,
      filterStatus,
      filterPatientName,
      filterType,
      filterProviderType,
      filterProviderName
    ],
    desktopLLL: [
      filterSort,
      filterDateRange,
      filterStatus,
      filterPatientName,
      filterType,
      filterProviderType,
      filterProviderName,
      filterTesterSsiId
    ]
  };

  const extraFiltersMap: Record<typeof device, FilterPanelProps['extraFilters']> = {
    mobile: [
      filterDateRange,
      filterStatus,
      filterPatientName,
      filterType,
      filterProviderName,
      filterProviderType,
      filterTesterSsiId
    ],
    tablet: [filterStatus, filterPatientName, filterType, filterProviderName, filterProviderType, filterTesterSsiId],
    tabletL: [filterPatientName, filterType, filterProviderName, filterProviderType, filterTesterSsiId],
    tabletLL: [filterType, filterProviderName, filterProviderType, filterTesterSsiId],
    desktop: [filterProviderName, filterProviderType, filterTesterSsiId],
    desktopL: [filterProviderName, filterTesterSsiId],
    desktopLL: [filterTesterSsiId],
    desktopLLL: []
  };

  const initialValues: FilterSelectItem[] = [
    { filterKey: 'sortBy', label: t('common.sortBy.soonest.text'), key: 'soonest', hidden: true, sticky: true }
  ];

  return { inlineFilters: inlineFiltersMap[device], extraFilters: extraFiltersMap[device], initialValues };
};
