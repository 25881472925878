import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import classNames from 'classnames';
import { FC, PropsWithChildren, useState } from 'react';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { getFullName } from '../../../../common/utils/helpers';
import { Appointment } from '../../../appointment/interfaces/appointment';
import { getAttendeeByRole } from '../../../appointment/utils/helpers';
import useModal from '../../../ui';
import styles from './appointment-join-chat-confirm.module.scss';

export const AppointmentJoinChatConfirm: FC<PropsWithChildren<{ appointment: Appointment; onClose: () => void }>> = ({
  appointment,
  children,
  onClose
}) => {
  const { t } = useTranslate();
  const { Modal, hideModal } = useModal({ initialOpened: true });
  const [confirmed, setConfirmed] = useState(false);

  const closeModal = () => {
    hideModal();
    onClose();
  };

  const patient =
    getAttendeeByRole(appointment.attendees, UserRoleEnum.Tester) ||
    getAttendeeByRole(appointment.attendees, UserRoleEnum.User);

  return confirmed ? (
    <>{children}</>
  ) : (
    <Modal onCancel={closeModal}>
      <p className={styles.title}>{formatDateWithDayJS(appointment?.startDatetime, 'MMMM DD, YYYY. h:mm a')}</p>
      <p className={styles.title}>
        {t('features.AppointmentJoinChatConfirm.confirmMdl.title', {
          patientName: patient?.ssiId || getFullName(patient),
          patientType: t(`common.userRolesTitles.${patient?.role}.name` as any)
        })}
      </p>
      <p className={styles.text}>{t('features.AppointmentJoinChatConfirm.confirmMdl.text1')}</p>
      <p className={styles.text}>{t('features.AppointmentJoinChatConfirm.confirmMdl.text2')}</p>
      <div className={styles.buttonsBlock}>
        <Button
          type="primary"
          className={classNames('buttonPrimary', styles.button)}
          onClick={() => {
            setConfirmed(true);
          }}
        >
          {t('features.AppointmentJoinChatConfirm.joinBtn.text')}
        </Button>
        <Button type="primary" className={classNames('buttonCancel', styles.button)} onClick={closeModal}>
          {t('features.AppointmentJoinChatConfirm.cancelBtn.text')}
        </Button>
      </div>
    </Modal>
  );
};
