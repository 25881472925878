import Icon from '@ant-design/icons';
import { ButtonProps, ConfigProvider } from 'antd';
import { type FC } from 'react';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left-2.svg';
import { Button } from '../Button/Button';
import styles from './back-button.module.scss';
import { useTheme } from '../../../common/providers/ThemeProvider';

export const BackButton: FC<
  ButtonProps & {
    label: string;
  }
> = ({ label, ...props }) => {
  const {colors} = useTheme();
  return (
    <span className={styles.buttonContainer}>
      <ConfigProvider
        theme={{
          token: { colorLink: colors.primary }
        }}
      >
        <Button icon={<Icon component={ArrowLeft} />} className={styles.button} {...props} type="link">
          {label}
        </Button>
      </ConfigProvider>
    </span>
  );
};
