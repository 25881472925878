import { LoadingOutlined } from '@ant-design/icons';
import { useTranslate } from '@tolgee/react';
import { Button, Spin } from 'antd';
import classNames from 'classnames';
import { FC, useLayoutEffect, useRef } from 'react';
import { ReactComponent as Exclamation } from '../../../assets/icons/exclamation.svg';
import { UserRoleEnum } from '../../../common/enums/userRoleEnum';
import { formatDateWithDayJS } from '../../../common/utils/dateFormat';
import { unbreakString } from '../../../common/utils/helpers';
import { ProfileAvatar } from '../../profile/components/ProfileAvatar/ProfileAvatar';
import styles from './chat.module.scss';
import { ChatMessage, SendFunction } from './interfaces';

const isSameUser = (currentMessage: ChatMessage, diffMessage: ChatMessage | null | undefined): boolean =>
  !!currentMessage.user?._id && currentMessage.user._id === diffMessage?.user?._id;

type ChatMessageListProps = {
  messages: ChatMessage[];
  userId: string;
  onSend: SendFunction;
};

export const ChatMessageList: FC<ChatMessageListProps> = ({ messages, userId, onSend }) => {
  const divRef = useRef<HTMLUListElement>(null);

  useLayoutEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <ul ref={divRef} className={styles.list}>
      {messages.map((message, index) => {
        const previousMessage: ChatMessage | undefined = messages[index - 1];
        const isSelfMessage = message.user?._id === userId;

        const isNewThread = message.system
          ? !previousMessage?.system
          : !!previousMessage?.system || !isSameUser(message, previousMessage);

        const messageProps = {
          isNewThread,
          message,
          onSend
        };

        if (message.system) return <SystemMessage key={message._id} {...messageProps} />;
        if (isSelfMessage) return <SelfMessage key={message._id} {...messageProps} />;
        return <PartnerMessage key={message._id} {...messageProps} />;
      })}
    </ul>
  );
};

type CurrentMessageProps = { message: ChatMessage; isNewThread?: boolean; onSend: SendFunction };

const SystemMessage: FC<CurrentMessageProps> = ({ message, isNewThread }) => {
  return (
    <li className={classNames(styles.systemMessage, { [styles.newThread]: isNewThread })}>
      <span>{unbreakString(message.user?.name)}</span>
      {` ${unbreakString(message.text)},`}
      {` ${unbreakString(formatDateWithDayJS(message.createdAt, 'MMMM DD, YYYY. h:mm a'))}`}
    </li>
  );
};

const PartnerMessage: FC<CurrentMessageProps> = ({ message, isNewThread }) => {
  return (
    <li className={classNames(styles.partner, { [styles.newThread]: isNewThread })}>
      <div className={styles.avatar}>
        {isNewThread && (
          <ProfileAvatar uniqueCode={message.user?.uniqueCode} size={24} isPatient={message.user?.role === UserRoleEnum.User} />
        )}
      </div>
      <div className={styles.partnerContent}>
        {isNewThread && <div className={styles.partnerName}>{message.user?.name}</div>}
        <div className={classNames(styles.bubble, styles.partnerBubble)}>{message.text}</div>
      </div>
    </li>
  );
};

const SelfMessage: FC<CurrentMessageProps> = ({ message, isNewThread, onSend }) => {
  const { t } = useTranslate();

  const buttonActive = message.sent === false;

  return (
    <li className={classNames(styles.self, { [styles.newThread]: isNewThread })}>
      <div className={styles.selfSpin}>
        {message.pending && <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />}
      </div>
      <div className={styles.selfBubbleContainer}>
        <Button
          disabled={!buttonActive}
          className={classNames(styles.bubble, styles.selfButton, { [styles.pending]: message.pending })}
          type="text"
          onClick={() => {
            onSend(message.text, message._id);
          }}
        >
          {message.text}
        </Button>
        {buttonActive && (
          <div className={styles.error}>
            <Exclamation className={styles.errorIcon} />
            <div className={styles.errorMessage}>{t('features.ChatMessageList.errSend')}</div>
          </div>
        )}
      </div>
    </li>
  );
};
