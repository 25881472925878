import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const authSelector = (state: RootState) => state.auth;

const authConfigSelector = (state: RootState) => {
  const authConfig = authSelector(state);
  return authConfig;
};

export const useAuthConfigSelector = () => {
  const authConfig = useAppSelector(authConfigSelector);
  return authConfig;
};

export const useUserSelector = () => {
  const authConfig = useAppSelector(authConfigSelector);
  return authConfig.user;
};
