import { RadioChangeEvent } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useScrollObserver } from '../../../../common/hooks/useScrollObserver';
import { useAppointmentsByPatienSelector } from '../../store/appointmentsByPatientSelectors';
import { useAppointmentsByPatientActionCreators } from '../../store/appointmentsByPatientSlice';
import { AppointmentsByPatientHistorySwitch } from '../AppointmentsByPatientHistorySwitch/AppointmentsByPatientHistorySwitch';
import { AppointmentsByPatientList } from '../AppointmentsByPatientList/AppointmentsByPatientList';
import styles from './appointments-by-patient-container.module.scss';

const isListView = true;

export const AppointmentByPatientContainer = () => {
  const params = useParams<{ id: string }>();
  const paginationPage = useRef(1);
  const isShowPast = useRef(false);
  const observerTarget = useRef<HTMLDivElement>(null);
  const appointmentsByPatientActions = useAppointmentsByPatientActionCreators();
  const { status, entities: appointments, hasNextPage, error } = useAppointmentsByPatienSelector();

  const fetchData = useCallback(() => {
    appointmentsByPatientActions.getProviderAppointmentsByPatient({ 
      page: paginationPage.current,
      limit: 10, 
      patientId: Number(params.id),
      past: isShowPast.current
    }).then(() => {
      paginationPage.current++;
    });
  }, [params.id]);

  useScrollObserver({
    fetchData,
    observerTarget,
    isListView,
    hasNextPage
  });

  const onHistoryViewChange = (e: RadioChangeEvent) => {
    appointmentsByPatientActions.reset();
    paginationPage.current = 1;
    isShowPast.current = e.target.value;
    fetchData();
  };

  useEffect(() => {
    // need to reset state if user uses nav buttons in the browser
    return () => {
      appointmentsByPatientActions.reset();
      paginationPage.current = 1;
    };
  }, []);

  return (
    <>
      <div className={styles.historySwitchContainer}>
        <AppointmentsByPatientHistorySwitch isChecked={isShowPast.current} onChange={onHistoryViewChange}/>
      </div>
      <AppointmentsByPatientList appointments={appointments} status={status} error={error} isHistoryList={isShowPast.current}/>
      <div ref={observerTarget} className={styles.observerTarget}></div>
    </>
  );
};
