import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';

const selector = (state: RootState) => state.notificationList;

const notificationListSelector = (state: RootState) => {
  const notificationList = selector(state);
  return notificationList;
};

export const useNotificationListSelector = () => {
  const state = useAppSelector(notificationListSelector);
  return state;
};
