import { UserRoleEnum } from '../../../common/enums/userRoleEnum';
import { Attendee } from '../interfaces/attendee';
import { AppointmentDaoService, GetUserNames } from '../services/AppointmentDaoService';

export const getAttendeeByRole = (attendees: Attendee[], role: UserRoleEnum): Attendee | undefined => {
  return attendees.find((item) => item.role === role);
};

export const getFetchUsers = (roles: number[]) => async (fullName?: string, providerId?: number) => {
  const params: GetUserNames = {
    roles,
    fullName,
    providerId,
  };

  try {
    const list = await AppointmentDaoService.getUserNames(params);
    return list.map((name) => ({ key: name, label: name }));
  } catch (error) {
    console.log('getUserNames', error);
  }
  return [];
};

export const fetchTesterSsiIds = async (ssiId?: string) => {
  try {
    const list = await AppointmentDaoService.getTesterUserSsiIds({ssiId});
    return list.map((ssiId) => ({ key: ssiId, label: ssiId }));
  } catch (error) {
    console.log('fetchTesterSsiIds', error);
  }
  return [];
};
