export enum NotificationTypesEnum {
  START_CALL = 'start_call',
  REQUESTED_BY_PROVIDER = 'requested_by_provider',
  REQUESTED_BY_PATIENT = 'requested_by_patient',
  REQUESTED_BY_TESTER = 'requested_by_tester',
  PENDING_CONFIRMATION = 'pending_confirmation',
  UPCOMING_APPOINTMENT = 'upcoming_appointment',
  APPOINTMENT_CANCELED = 'appointment_canceled',
  APPOINTMENT_COMPLETED = 'appointment_completed',
  APPOINTMENT_CONFIRMED = 'appointment_confirmed',
  AVAILABILITY_CHANGED = 'availability_changed'
}
