import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { baseUrl } from '../../constants';
import { RootState } from '../../interfaces/store';
import { errorAction } from '../../middlewares/listener';
import { getTokenFromStorage, removeTokenInStorage } from '../../utils/storage';
import { UnauthorizedError, ValidationError, handleAxiosError } from './errors.helper';
import { isInstanceOf } from '../../utils/typeUtils';

let store: ToolkitStore<RootState>;

export const injectStore = (_store: ToolkitStore<RootState>) => {
  store = _store;
};

const excludeUrls = ['/auth/admin/email/login', '/auth/change/password'];

export const baseRequestInterceptor = (apiUrl: string, request: InternalAxiosRequestConfig) => {
  request.baseURL = apiUrl;
  request.headers['Content-Type'] = 'application/json';
  request.responseType = 'json';
  if (excludeUrls.includes(request.url || '')) {
    return request;
  }
  return request;
};

export const telehealthRequestInterceptor = (apiUrl: string, request: InternalAxiosRequestConfig) => {
  const currentRequest = baseRequestInterceptor(apiUrl, request);
  currentRequest.baseURL = currentRequest.baseURL + '/api';
  currentRequest.headers['Authorization'] = `Bearer ${getTokenFromStorage().token}` + '';
  return currentRequest;
};

export const requestErrorInterceptor = (error: unknown) => {
  return Promise.reject(error);
};

export const responseInterceptor = (response: AxiosResponse) => {
  return response.data;
};

const excludeErrors = [ValidationError];

export const responseErrorInterceptor = (error: AxiosError) => {
  return handleAxiosError(error).catch(error => {
    if (!excludeErrors.some(host => isInstanceOf(error, host))) {
      isInstanceOf(error, UnauthorizedError) && removeTokenInStorage();
      store.dispatch(errorAction(error));
    }
    throw error;
  });
};
