import { useMemo } from 'react';
import { formatDate } from '../../../common/utils/dateFormat';
import { NotificationAppointmentItem, NotificationItem } from '../interfaces/notification';
import { GetListResponse } from '../services/NotificationListDaoService';

const groupByDate = (
  acc: Record<string, (NotificationItem | NotificationAppointmentItem)[]>,
  notification: NotificationItem | NotificationAppointmentItem
): Record<string, (NotificationItem | NotificationAppointmentItem)[]> => {
  const date = formatDate(notification.notificationTime, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  acc[date] = acc[date] || [];
  acc[date].push(notification);
  return acc;
};

export const useGroupedNotificationList = (data: GetListResponse) => {
  const groupedNotificationList = useMemo(() => {
    const grouped = data.notificationList.reduce(groupByDate, {});
    return Object.entries(grouped);
  }, [data]);

  return { groupedNotificationList };
};
