import { useTranslate } from '@tolgee/react';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { getLTFormat } from '../../../../common/utils/dateFormat';
import { Schedule, Slot } from '../../interfaces/schedule';
import { CONTACT_METHOD_ERROR } from '../AvailabilitySchedule/AvailabilitySchedule';

interface AvailabilityCheckboxesProps {
  slot: Slot;
  index: number;
  record: Schedule;
  handleTimeChange: (dayOfTheWeek: number, index: number, field: 'startTime' | 'endTime', value: string) => void;
}

export const AvailabilityCheckboxes: FC<AvailabilityCheckboxesProps> = ({ slot, record, handleTimeChange, index }) => {
  const { t } = useTranslate();

  return (
    <div key={index} className={`time-pickers-container ${slot.deleted ? 'slot-deleted' : ''}`}>
      <TimePicker
        className={slot.error && slot.error !== CONTACT_METHOD_ERROR ? 'error' : ''}
        value={slot.startTime ? dayjs(slot.startTime) : undefined}
        format={getLTFormat()}
        placeholder={t('features.AvailabilityCheckboxes.startInpt.placeholder')}
        disabled={slot.deleted || !record.enabled}
        onChange={(time) => {
          return handleTimeChange(record.dayOfTheWeek, index, 'startTime', time?.toISOString() || '');
        }}
        inputReadOnly={true}
        showNow={false}
        needConfirm={false}
      />
      {' - '}
      <TimePicker
        className={slot.error && slot.error !== CONTACT_METHOD_ERROR ? 'error' : ''}
        value={slot.endTime ? dayjs(slot.endTime) : undefined}
        format={getLTFormat()}
        placeholder={t('features.AvailabilityCheckboxes.endInpt.placeholder')}
        disabled={slot.deleted || !record.enabled}
        onChange={(time) => handleTimeChange(record.dayOfTheWeek, index, 'endTime', time?.toISOString() || '')}
        inputReadOnly={true}
        showNow={false}
        needConfirm={false}
      />
      {slot.error && slot.error !== CONTACT_METHOD_ERROR && <div className="error-message">{slot.error}</div>}
    </div>
  );
};
