import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRoleEnum } from '../../../common/enums/userRoleEnum';
import { Patient } from '../../../common/interfaces/patient';
import { User } from '../../../common/interfaces/user';
import {
  AppointmentCreateDaoService,
  GetUsersParams,
  UserListResponse
} from '../../appointmentCreate/services/AppointmentCreateDaoService';
import { ProfileDaoService } from '../services/ProfileDaoService';

export const getPatientProfile = createAsyncThunk<Patient, { role: UserRoleEnum; userId: string }>(
  'profile/get-patient-profile',
  async (params) => {
    const profile =
      params.role === UserRoleEnum.Provider
        ? await ProfileDaoService.getPatientInfoByProvider(params.userId)
        : await ProfileDaoService.getPatientInfoByAdmin<Patient>(params.userId);

    return profile;
  }
);

export const getPatientList = createAsyncThunk<UserListResponse, GetUsersParams>(
  'profile/get-users',
  async (params) => {
    const users = await AppointmentCreateDaoService.getUsers(params);

    return users;
  }
);

export const getContactPersons = createAsyncThunk<Array<User>, { providerId: number }>(
  'profile/get-contact-persons',
  async (params) => {
    const users = await ProfileDaoService.getContactPersons(params.providerId);

    return users;
  }
);

export const profileThunks = {
  getPatientProfile,
  getPatientList,
  getContactPersons
};
