import { useAppSelector } from '../../../common/hooks/store';
import { RootState } from '../../../common/interfaces/store';
import { appointmentAdapter } from './appointmentSlice';

const selector = (state: RootState) => state.appointmentList;

const selectors = appointmentAdapter.getSelectors();

const appointmentListSelector = (state: RootState) => {
  const appointmentListState = selector(state);
  return appointmentListState;
};

export const useAppointmentListSelector = () => {
  const state = useAppSelector(appointmentListSelector);
  return state;
};

export const useAppointmensSelector = () => {
  const selectAllSelector = ({ appointmentList }: RootState) => selectors.selectAll(appointmentList);
  const appointments = useAppSelector(selectAllSelector);
  return appointments;
};
