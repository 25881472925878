import { useTranslate } from '@tolgee/react';
import { Collapse } from 'antd';
import classNames from 'classnames';
import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { User } from '../../../../common/interfaces/user';
import { getFullName } from '../../../../common/utils/helpers';
import { ConditionalWrapper } from '../../../ui/ConditionalWrapper/ConditionalWrapper';
import useConfirmModal from '../../../ui/Modal/useConfirmModal';
import useModal from '../../../ui/Modal/useModal';
import useSuccessModal from '../../../ui/Modal/useSuccessModal';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar';
import { ProfileProviderContactInformation } from '../ProfileProviderContactInformation/ProfileProviderContactInformation';
import { ProfileProviderEditForm } from '../ProfileProviderEditForm/ProfileProviderEditForm';
import style from './profile-provider.module.scss';

interface ProfileProviderProps {
  endAction: () => void;
  showEditIcon: boolean;
  isMyProfile: boolean;
  user: User;
}

const { Panel } = Collapse;

export const ProfileProvider: FC<ProfileProviderProps> = ({ endAction, showEditIcon, user, isMyProfile }) => {
  const { t } = useTranslate();

  const { pathname } = useLocation();
  const isPersonalInformationPage = pathname.includes('personal-information');

  const [isEditSectionVisible, setIsEditSectionVisible] = useState<boolean>(false);

  const { isDesktop } = useWindowSizeType();

  const { Modal: EditModal, showModal: showEditModal, hideModal: hideEditModal } = useModal({ width: 968 });
  const { SuccessModal, showSuccessModal } = useSuccessModal(t('features.ProfileProvider.savedMsg'));
  const { ConfirmModal, showConfirmModal } = useConfirmModal(isEditSectionVisible);

  const providerFullName = getFullName(user);

  const onEditBtnClick = () => {
    if (isDesktop) {
      showEditModal();
    } else {
      setIsEditSectionVisible(true);
    }
  };

  const confirmModalOkHandler = () => {
    hideEditModal();
    setIsEditSectionVisible(false);
  };

  const header = (
    <div className={style.profilePhotoContainer}>
      <ProfileAvatar uniqueCode={user.uniqueCode} className={style.avatar} />
      <div className={style.userTitle}>
        <p className={style.userName}>{providerFullName}</p>
        <p className={style.title}>
          {user.typeOfProvider || '-'} at {user.company || '-'}
        </p>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmModal onOk={confirmModalOkHandler} />
      <SuccessModal />

      {isEditSectionVisible ? (
        <ProfileProviderEditForm
          values={user}
          isMyProfile={isMyProfile}
          hideEditModal={() => setIsEditSectionVisible(false)}
          showConfirmModal={showConfirmModal}
          showSuccessModal={showSuccessModal}
          endAction={endAction}
        />
      ) : (
        <div
          className={classNames(style.profileContainer, {
            [style.providerPage]: !isPersonalInformationPage
          })}
        >
          <ConditionalWrapper
            condition={!isPersonalInformationPage && !isDesktop}
            wrapper={(children) => (
              <Collapse
                className={style.collapse}
                expandIconPosition="right"
                expandIcon={({ isActive }) => (
                  <ArrowDown className={classNames(style.arrowDown, { [style.active]: isActive })} />
                )}
              >
                <Panel header={header} key="ProviderPersonalInformation">
                  {children}
                </Panel>
              </Collapse>
            )}
          >
            {(isPersonalInformationPage || isDesktop) && header}
            <ProfileProviderContactInformation user={user} />
            <EditModal onCancel={showConfirmModal} preventHideOnCancel classes={style.modal}>
              <ProfileProviderEditForm
                values={user}
                isMyProfile={isMyProfile}
                hideEditModal={hideEditModal}
                showConfirmModal={showConfirmModal}
                showSuccessModal={showSuccessModal}
                endAction={endAction}
              />
            </EditModal>
          </ConditionalWrapper>

          {showEditIcon && <EditIcon className={classNames('edit-icon', style.editIcon)} onClick={onEditBtnClick} />}
        </div>
      )}
    </>
  );
};
