export enum RegionsEnum {
  Clarendon = 'Clarendon',
  Hanover = 'Hanover',
  Kingston = 'Kingston',
  Manchester = 'Manchester',
  Portland = 'Portland',
  StAndrew = 'St. Andrew',
  StAnn = 'St. Ann',
  StCatherine = 'St. Catherine',
  StElizabeth = 'St. Elizabeth',
  StJames = 'St. James',
  StMary = 'St. Mary',
  StThomas = 'St. Thomas',
  Trelawny = 'Trelawny',
  Westmoreland = 'Westmoreland',
}

export enum RegionsCanadaEnum {
  Alberta = 'Alberta',
  BritishColumbia = 'British Columbia',
  Manitoba = 'Manitoba',
  NewBrunswick = 'New Brunswick',
  NewfoundlandAndLabrador = 'Newfoundland and Labrador',
  Northwest = 'Northwest',
  Territories = 'Territories',
  NovaScotia ='Nova Scotia',
  Nunavut = 'Nunavut',
  Ontario = 'Ontario',
  PrinceEdwardIsland = 'Prince Edward Island',
  Quebec = 'Quebec',
  Saskatchewan ='Saskatchewan',
  Yukon = 'Yukon',
}

export const enumsRegions =  {
  'jamaica': RegionsEnum,
  'canada': RegionsCanadaEnum
};

export enum Locale {
  EN = 'en-us',
  FR = 'fr-ca'
}
