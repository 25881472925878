import { RosterAttendeeType, useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useEffect, useRef, useState } from 'react';

export function useAttendeeAudioStatuses(attendees: RosterAttendeeType[]) {
  const audioVideo = useAudioVideo();
  const [statuses, setStatuses] = useState<Map<string, { muted: boolean }>>(new Map());
  const subscribeRef = useRef<Map<string, (...args: any) => void>>(new Map());

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    const curruntIds = new Set<string>();
    attendees.forEach((attendee) => {
      if (subscribeRef.current.has(attendee.chimeAttendeeId)) return;
      const callback = (_: string, __: number | null, muted: boolean | null): void => {
        if (muted !== null) {
          setStatuses((s) => {
            const newState = new Map(s);
            newState.set(attendee.chimeAttendeeId, { muted });            
            return newState;
          });
        }
      };
      setStatuses((s) => {
        const newState = new Map(s);
        newState.set(attendee.chimeAttendeeId, { muted: false });
        return newState;
      });
      audioVideo.realtimeSubscribeToVolumeIndicator(attendee.chimeAttendeeId, callback);
      subscribeRef.current.set(attendee.chimeAttendeeId, callback);
      curruntIds.add(attendee.chimeAttendeeId);
    });
    subscribeRef.current.forEach((callback, attendeeId) => {
      if (curruntIds.has(attendeeId)) return;
      audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, callback);
      subscribeRef.current.delete(attendeeId);
    });
  }, [audioVideo, attendees]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    return () => {
      subscribeRef.current.forEach((callback, attendeeId) => {
        audioVideo.realtimeUnsubscribeFromVolumeIndicator(attendeeId, callback);
        subscribeRef.current.delete(attendeeId);
      });
    };
  }, [audioVideo]);

  return statuses;
}

export default useAttendeeAudioStatuses;
