import { RefObject, useEffect } from 'react';

interface Params {
  fetchData: () => void;
  observerTarget: RefObject<HTMLDivElement | null>;
  isListView: boolean;
  hasNextPage: boolean;
}

export const useScrollObserver = ({ fetchData, observerTarget, isListView, hasNextPage }: Params) => {
  useEffect(() => {
    if (hasNextPage && isListView) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            fetchData();
          }
        },
        { threshold: 1 }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }
  }, [fetchData, isListView, hasNextPage]);
};
