import { useTranslate } from '@tolgee/react';
import { ClinicServiceList } from '../../features/clinicService/components';
import style from './services.module.scss';

export const ServicesPage = () => {
  const { t } = useTranslate();

  return (
    <div className={style.container}>
      <p className={style.title}>{t('pages.ServicesPage.title')}</p>
      <ClinicServiceList />
    </div>
  );
};
