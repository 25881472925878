import  { TelehealthHttpService } from '../../../common/services/http/http.service';
import { buildQueryString } from '../../../common/utils/buildQueryString';
import { Schedule } from '../interfaces/schedule';

export interface GetScheduleResponse {
  schedule: Schedule[];
  updatedAt?: string;
  updatedBy?: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

export type UpdateScheduleParams = GetScheduleResponse & {
  providerId: number;
  providerTimezoneOffset: number;
};

interface GetScheduleParams {
  providerId?: string;
  [key: string]: string | undefined;
}

const getSchedule = (params: GetScheduleParams): Promise<GetScheduleResponse> => {
  const queryString = buildQueryString(params);
  return TelehealthHttpService.url(`schedule?${queryString}`).get();
};

const updateSchedule = (body: UpdateScheduleParams) => {
  return TelehealthHttpService.url('schedule').put(body);
};

export const AvailabilityDaoService = {
  getSchedule,
  updateSchedule
};
