import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { WithRequiredProp } from '../../helpers';
import {
  baseRequestInterceptor,
  requestErrorInterceptor,
  responseErrorInterceptor,
  responseInterceptor, telehealthRequestInterceptor
} from './interceptors';
import { baseUrl, iAmReadyApiUrl } from '../../constants';

export type RequestBody = AxiosRequestConfig['data'];

const telehealthAxiosInstance: AxiosInstance = axios.create();
const iAmReadyAxiosInstance: AxiosInstance = axios.create();

telehealthAxiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig) => telehealthRequestInterceptor(baseUrl as string, request),
  requestErrorInterceptor, { synchronous: true });
telehealthAxiosInstance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor,
  { synchronous: true });

iAmReadyAxiosInstance.interceptors.request.use(
  (request: InternalAxiosRequestConfig) => baseRequestInterceptor(iAmReadyApiUrl as string, request),
  requestErrorInterceptor, { synchronous: true });
iAmReadyAxiosInstance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor,
  { synchronous: true });


class HttpService {
  private currentInstance: AxiosInstance;
  private _url: string = '';
  private _headers: Record<string, string> = {};

  constructor(instance: AxiosInstance) {
    this.currentInstance = instance;
  }

  // Creates an instance of request builder with given url
  public url(url: string) {
    this._url = url;
    return this;
  }


  // Sets request header
  public header(key: string, value: string): HttpService {
    this._headers[key] = value;
    return this;
  }

  // Creates http request with GET method
  public get<T = any>(): Promise<T> {
    return this.currentInstance.get(this._url, this._options);
  }

  // Creates http request with POST method
  public post<T = any>(body?: RequestBody): Promise<T> {
    return this.currentInstance.post(this._url, body, this._options);
  }

  // Creates http request with PUT method
  public put<T = any>(body?: RequestBody): Promise<T> {
    return this.currentInstance.put(this._url, body, this._options);
  }

  // Creates http request with DELETE method
  public delete<T = any>(): Promise<T> {
    return this.currentInstance.delete(this._url, this._options);
  }

  // Creates http request with PATCH method
  public patch<T = any>(body?: RequestBody): Promise<T> {
    return this.currentInstance.patch(this._url, body, this._options);
  }

  private get _options() {
    const options: WithRequiredProp<AxiosRequestConfig, 'headers'> = {
      headers: this._headers
    };
    return options;
  }
}

export const TelehealthHttpService= new HttpService(telehealthAxiosInstance);
export const IAmReadyHttpService = new HttpService(iAmReadyAxiosInstance);

