// it is customized clone of Month from react-big-calendar
import clsx from 'clsx';
import * as animationFrame from 'dom-helpers/animationFrame';
import getPosition from 'dom-helpers/position';
import chunk from 'lodash/chunk';
import React, { createRef } from 'react';
import { Navigate, ViewProps, Views } from 'react-big-calendar';
// @ts-expect-error any
import DateHeader from 'react-big-calendar/lib/DateHeader';
// @ts-expect-error any
import DateContentRow from 'react-big-calendar/lib/DateContentRow';
// @ts-expect-error any
import PopOverlay from 'react-big-calendar/lib/PopOverlay';
// @ts-expect-error any
import Header from 'react-big-calendar/lib/Header';
// @ts-expect-error any
import { inRange, sortEvents } from 'react-big-calendar/lib/utils/eventLevels';
// @ts-expect-error any
import { notify } from 'react-big-calendar/lib/utils/helpers';
import { CalendarEvent } from '../useCalendarEvent';

const eventsForWeek = (evts: CalendarEvent[], start: Date, end: Date, accessors: object, localizer: any) =>
  evts.filter((e: CalendarEvent) => inRange(e, start, end, accessors, localizer));

type Props = ViewProps<CalendarEvent>;

type State = {
  rowLimit: number;
  needLimitMeasure: boolean;
  date: null | Date;
  overlay: null | {
    date: Date;
    events: CalendarEvent[];
    position: {
      top: number;
      left: number;
      height: number;
      width: number;
    };
    target: any;
  };
};

class WorkMonth extends React.Component<Props, State> {
  containerRef: React.RefObject<HTMLDivElement>;
  slotRowRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);

    this.state = {
      rowLimit: 5,
      needLimitMeasure: true,
      date: null,
      overlay: null
    };
    this.containerRef = createRef();
    this.slotRowRef = createRef();
    this._resizeListener = this._resizeListener.bind(this);
    this.overlayDisplay = this.overlayDisplay.bind(this);
  }

  static getDerivedStateFromProps({ date, localizer }: Props, state: State) {
    return {
      date,
      needLimitMeasure: localizer.neq(date, state.date, 'month')
    };
  }

  componentDidMount() {
    if (this.state.needLimitMeasure) this.measureRowLimit();

    window.addEventListener('resize', this._resizeListener, false);
  }

  componentDidUpdate() {
    if (this.state.needLimitMeasure) this.measureRowLimit();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resizeListener, false);
  }

  _resizeListener() {
    animationFrame.request(() => {
      this.setState({ needLimitMeasure: true });
    });
  }

  getContainer = () => {
    return this.containerRef.current;
  };

  render() {
    const { date, localizer } = this.props;
    const month: Date[] = localizer.visibleDays(date, localizer);
    const weeks = chunk(month, 7);

    return (
      <div className="rbc-month-view" role="table" aria-label="Month View" ref={this.containerRef}>
        <div className="rbc-row rbc-month-header" role="row">
          {this.renderHeaders(weeks[0])}
        </div>
        {weeks.map(this.renderWeek)}
        {this.renderOverlay()}
      </div>
    );
  }

  renderWeek = (week: Date[], weekIdx: number) => {
    const {
      events = [],
      components,
      selectable,
      getNow,
      selected,
      date,
      localizer,
      accessors,
      getters,
      showAllEvents
    } = this.props;
    week = week.slice(0, 5);

    const { needLimitMeasure, rowLimit } = this.state;

    const weeksEvents = eventsForWeek([...events], week[0], week[week.length - 1], accessors, localizer);

    weeksEvents.sort((a, b) => sortEvents(a, b, accessors, localizer));

    return (
      <DateContentRow
        key={weekIdx}
        ref={weekIdx === 0 ? this.slotRowRef : undefined}
        container={this.getContainer}
        className="rbc-month-row"
        getNow={getNow}
        date={date}
        range={week}
        events={weeksEvents}
        maxRows={showAllEvents ? Infinity : rowLimit}
        selected={selected}
        selectable={selectable}
        components={components}
        accessors={accessors}
        getters={getters}
        localizer={localizer}
        renderHeader={this.readerDateHeading}
        renderForMeasure={needLimitMeasure}
        onShowMore={this.handleShowMore}
      />
    );
  };

  readerDateHeading = ({ date, className, ...props }: any) => {
    const { date: currentDate, localizer } = this.props;
    const isOffRange = localizer.neq(date, currentDate, 'month');
    const isCurrent = localizer.isSameDate(date, currentDate);
    const label = localizer.format(date, 'dateFormat');

    return (
      <div
        {...props}
        className={clsx(className, isOffRange && 'rbc-off-range', isCurrent && 'rbc-current')}
        role="cell"
      >
        <DateHeader
          label={label}
          drilldownView={Views.DAY}
          onDrillDown={(e: any) => this.handleHeadingClick(date, e)}
        />
      </div>
    );
  };

  handleHeadingClick = (date: Date, e: any) => {
    e.preventDefault();
    notify(this.props.onDrillDown, [date, Views.DAY]);
  };

  renderHeaders(row: Date[]) {
    const { localizer } = this.props;

    return row.slice(0, 5).map((day: Date, idx: number) => (
      <div key={'header_' + idx} className="rbc-header">
        <Header date={day} localizer={localizer} label={localizer.format(day, 'weekdayFormat')} />
      </div>
    ));
  }

  renderOverlay() {
    const overlay = this.state.overlay ?? ({} as any);
    const { accessors, localizer, components, getters, selected, popupOffset, handleDragStart } = this.props;

    return (
      <PopOverlay
        overlay={overlay}
        accessors={accessors}
        localizer={localizer}
        components={components}
        getters={getters}
        selected={selected}
        popupOffset={popupOffset}
        ref={this.containerRef}
        handleDragStart={handleDragStart}
        show={!!overlay.position}
        overlayDisplay={this.overlayDisplay}
        onHide={this.overlayDisplay}
      />
    );
  }

  measureRowLimit() {
    this.setState({
      needLimitMeasure: false,
      rowLimit: this.slotRowRef.current.getRowLimit()
    });
  }

  handleShowMore = (events: CalendarEvent[], date: Date, cell: any, slot: any, target: any) => {
    const position = getPosition(cell, this.containerRef.current as HTMLElement);

    this.setState({
      overlay: { date, events, position, target }
    });
  };

  overlayDisplay = () => {
    this.setState({
      overlay: null
    });
  };
}

// @ts-expect-error any
WorkMonth.range = (date, { localizer }) => {
  const start = localizer.firstVisibleDay(date, localizer);
  const end = localizer.lastVisibleDay(date, localizer);
  return { start, end };
};

// @ts-expect-error any
WorkMonth.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'month');
    case Navigate.NEXT:
      return localizer.add(date, 1, 'month');
    default:
      return date;
  }
};

// @ts-expect-error any
WorkMonth.title = (date, { localizer }) => localizer.format(date, 'monthHeaderFormat');

export default WorkMonth;
