import { T, useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UserRoleEnum } from '../../common/enums/userRoleEnum';
import { useWindowSizeType } from '../../common/hooks/useWindowSizeType';
import { User } from '../../common/interfaces/user';
import { AccessConfigEnum, AccessService } from '../../features/access/AccessService';
import { AppointmentContainerProvider } from '../../features/appointment/components';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { Availability } from '../../features/availability/components/AvailabilitySchedule/AvailabilitySchedule';
import { ProfileProvider } from '../../features/profile/components/ProfileProvider/ProfileProvider';
import { ProfileDaoService } from '../../features/profile/services/ProfileDaoService';
import { BackButton, Button } from '../../features/ui';
import { ViewSwitch } from '../../features/ui/ViewSwitch/VIewSwitch';
import styles from './provider-details-page.module.scss';

const VIEW_CONFIG = [
  {
    key: 'all_appointments',
    label: <T keyName="common.allAppointmentsTab.label" />
  },
  {
    key: 'availability',
    label: <T keyName="common.availabilityTab.label" />
  }
];

export const ProviderDetailsPage = () => {
  const { t } = useTranslate();
  const [providerProfile, setProviderProfile] = useState<User>();
  const providerId = useParams().id!;
  const { user } = useAuthConfigSelector();
  const navigate = useNavigate();
  const userRole = user?.role.id as UserRoleEnum;
  const isAdmin = userRole === UserRoleEnum.Admin;
  const isMyProfile = String(user?.id) === providerId;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentView = searchParams.get('activeViewKey') || '';

  const { isMobile } = useWindowSizeType();

  useEffect(() => {
    if (!currentView) {
      setSearchParams({ activeViewKey: VIEW_CONFIG[0].key });
    }
  }, []);

  const onViewChange = (activeViewKey: string) => {
    setSearchParams({ activeViewKey });
  };

  const fetchProviderProfile = async () => {
    try {
      const provider = await ProfileDaoService.getPatientInfoByAdmin<User>(providerId);
      setProviderProfile(provider);
    } catch (error) {
      console.log('ProviderDetailsPage#fetchProviderProfile', error);
    }
  };

  const newAppointmentButton = AccessService.isMatchAccessConfig(AccessConfigEnum.createAppointmentButton, userRole) ? (
    <Button type="primary" className={styles.button} onClick={() => navigate('/appointments/appointment-create')}>
      {t('common.newAppointmentBtn.text')}
    </Button>
  ) : null;

  useEffect(() => {
    if (isAdmin) {
      fetchProviderProfile();
    } else {
      navigate('/appointments');
    }
  }, []);

  return (
    <div className={styles.container}>
      <BackButton label={t('common.backBtn.label')} onClick={() => navigate(-1)} />
      <div className={styles.content}>
        <div className={styles.leftPanel}>
          {providerProfile && (
            <ProfileProvider
              user={providerProfile}
              endAction={fetchProviderProfile}
              showEditIcon={isAdmin}
              isMyProfile={isMyProfile}
            />
          )}
        </div>
        <div className={styles.rightPanel}>
          <ViewSwitch items={VIEW_CONFIG} onChange={onViewChange} activeView={currentView} />
          {currentView === VIEW_CONFIG[0].key ? (
            <>
              <div className={styles.topLineProvider}>{!isMobile && newAppointmentButton}</div>
              <AppointmentContainerProvider role={userRole} providerId={providerId} />
              {isMobile && newAppointmentButton}
            </>
          ) : (
            <Availability providerId={providerId} />
          )}
        </div>
      </div>
    </div>
  );
};
