import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetListResponse, MarkAsReadParams, NotificationListDaoService } from '../services/NotificationListDaoService';

export const getList = createAsyncThunk<{ notifications: GetListResponse; page: number }, number>(
  'notification-list/get-list',
  async (page, thunkApi) => {
    const notifications = await NotificationListDaoService.getList(page);
    return { notifications, page };
  }
);

export const markAsRead = createAsyncThunk<{ ids?: string[]; groupId?: string; amount: number }, MarkAsReadParams>(
  'notification-list/mark-as-read',
  async (params, thunkApi) => {
    const requestParams = params.ids?.length ? { ids: params.ids } : params;
    await NotificationListDaoService.markAsRead(requestParams);
    const res = await NotificationListDaoService.getUnreadAmount();
    return { groupId: params.groupId, amount: res.amount };
  }
);

export const updateUnreadAmount = createAsyncThunk<number>('notification-list/update-unread-count', async () => {
  const res = await NotificationListDaoService.getUnreadAmount();
  return res.amount;
});

export const notificationListThunks = {
  getList,
  markAsRead,
  updateUnreadAmount
};
