import { useTranslate } from '@tolgee/react';
import { Modal } from 'antd';

import browserPageCameraImage from '../../../../assets/images/browser_page_camera.svg';
import browserPageMicrophoneImage from '../../../../assets/images/browser_page_microphone.svg';
import styles from '../AppointmentJoinPageContent/appointment-join-page-content.module.scss';

import { AppointmentTypeEnum } from '../../../../common/enums/appointmentTypeEnum';

import type { FC } from 'react';
import type { Props } from './interfaces';

export const AppointmentModalPermission: FC<Props> = ({
  showMicrophoneAndCameraAccessModal,
  setShowMicrophoneAndCameraAccessModal,
  appointment,
}) => {
  const { t } = useTranslate();
  const isVideo = appointment.type === AppointmentTypeEnum.video || appointment.type === AppointmentTypeEnum.hologram;

  return (<Modal
    wrapClassName={styles.permissionsModal}
    width={423}
    open={showMicrophoneAndCameraAccessModal}
    onCancel={() => setShowMicrophoneAndCameraAccessModal(false)}
    footer={null}
  >
    {isVideo ? (
      <>
        <img className={styles.browserPageImage} src={browserPageCameraImage}></img>
        <p className={styles.permissionsTitle}>Want to use your camera and mic for the meeting?</p>
        <p className={styles.permissionsDescription}>
          { t('features.AppointmentModalPermission.videoAndAudioPermissions.text') }
        </p>
      </>
    ) : (
      <>
        <img className={styles.browserPageImage} src={browserPageMicrophoneImage}></img>
        <p className={styles.permissionsTitle}>Want to use your mic for the meeting?</p>
        <p className={styles.permissionsDescription}>
          { t('features.AppointmentModalPermission.audioPermissions.text') }\
        </p>
      </>
    )}
  </Modal>);
};
