import { ConfigProvider } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '../../../common/providers/ThemeProvider';
import { Button } from '../Button/Button';
import { ButtonDropdown } from './components/Dropdown/ButtonDropdown';
import { SelectedItemsList } from './components/SelectedItemsList/SelectedItemsList';
import styles from './filters-panel.module.scss';
import { ChangeFunction, ExtraFilterConfigItem, FilterLocale, FilterSelectItem, InlineFilterConfigItem } from './types';
import { filterFactory } from './utils/filterFactory';
import { tolgee } from '../../../app/App';

export interface FilterPanelProps {
  i18n: FilterLocale;
  inlineFilters: InlineFilterConfigItem[];
  extraFilters: ExtraFilterConfigItem[];
  initialValues?: FilterSelectItem[];
  onChange?: (selectedValues: FilterSelectItem[]) => void;
  onClose?: () => void;
  onReset?: () => void;
}

export const FiltersPanel = ({
  extraFilters,
  inlineFilters,
  initialValues = [],
  onChange,
  i18n,
  onReset
}: FilterPanelProps) => {
  const [selected, setSelected] = useState<FilterSelectItem[]>(initialValues);
  const language = tolgee.getLanguage();

  const changeHandler = useCallback<ChangeFunction>((item) => {
    setSelected((prevState) => [
      ...prevState.filter((value) => !(value.filterKey === item.filterKey && (item.radio || value.key === item.key))),
      ...(item.value ? [item] : [])
    ]);
  }, []);

  const removeItem = useCallback((item: FilterSelectItem) => {
    setSelected((prevState) =>
      prevState.filter((value) => !(value.filterKey === item.filterKey && value.key === item.key))
    );
  }, []);

  const resetItems = useCallback(() => {
    setSelected((prevState) => prevState.filter((select) => select.sticky));
    onReset?.();
  }, []);

  const filtersList = useMemo(() => {
    return inlineFilters
      .filter(({ hidden }) => !hidden)
      .map((config) => filterFactory(config, selected, changeHandler));
  }, [changeHandler, filterFactory, inlineFilters, selected]);

  const isMountedRef = useRef(false);

  const { colors } = useTheme();

  useEffect(() => {
    if (isMountedRef.current && onChange) {
      onChange(selected);
    }
    isMountedRef.current = true;
  }, [selected]);

  useEffect(() => {
    setSelected((prevState: FilterSelectItem[]) => {
      return  prevState.map((item: FilterSelectItem) =>{
        const index = inlineFilters.findIndex((value) => value.filter.filterKey === item?.filterKey);
        if(index !== -1){
          return {...item, label: inlineFilters[index].label || item?.label};
        } else {
          return item;
        }
      });
    });
  }, [language]);

  return (
    <div className={styles.container}>
      <div className={styles.topLine}>
        <div className={styles.filters}>{filtersList}</div>
        <div className={styles.buttons}>
          {extraFilters.length > 0 && (
            <ButtonDropdown
              filters={extraFilters}
              onChange={changeHandler}
              selected={selected}
              label={i18n.extraFiltersButtonLabel}
            />
          )}
          <ConfigProvider
            theme={{
              token: { colorLink: colors.primary },
              components: {
                Button: {
                  fontWeight: 600,
                  fontSize: 13
                }
              }
            }}
          >
            <Button type="link" className={styles.link} onClick={resetItems}>
              {i18n.resetButtonLabel}
            </Button>
          </ConfigProvider>
        </div>
      </div>

      <SelectedItemsList values={selected.filter((select) => !select.hidden)} onItemRemove={removeItem} />
    </div>
  );
};
