export enum ColumnKeysEnum {
  uniqueCode = 'uniqueCode',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  clinicServiceType = 'clinicServiceType',
  assignedCaseManager = 'assignedCaseManager',
  lastVisitedPharmacist = 'lastVisitedPharmacist',
  assignedContactInvestigator = 'assignedContactInvestigator',
  assignedPsychologist = 'assignedPsychologist',
  region = 'region',
  address = 'address',
  todayAvailability = 'todayAvailability',
  futureAvailability = 'futureAvailability',
}
