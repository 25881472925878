import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRoleEnum } from '../../../common/enums/userRoleEnum';
import { Appointment } from '../interfaces/appointment';
import {
  AppointmentDaoService,
  CancelParams,
  ConfirmAppointmentParams,
  DetailsListParams,
  DetailsListResponse
} from '../services/AppointmentDaoService';

export const getList = createAsyncThunk<DetailsListResponse, DetailsListParams & {role: UserRoleEnum;}>(
  'appointment/get-list',
  async (params, thunkApi) => {
    const { role, ...requestParams } = params;
    let appointments: DetailsListResponse;
    if (role === UserRoleEnum.Provider || role === UserRoleEnum.PeerNavigator) {
      appointments = await AppointmentDaoService.getDetailedList(requestParams);
    } else {
      appointments = await AppointmentDaoService.getDetailedListAllProviders(requestParams);
    }
    return appointments;
  }
);
export const cancel = createAsyncThunk<{appointment: Appointment; seriesId?: number}, CancelParams>(
  'appointment/cancel',
  async (params, thunkApi) => {
    const appointment = await AppointmentDaoService.cancel(params);
    return {appointment, seriesId: params.seriesId};
  }
);
export const confirm = createAsyncThunk<Appointment, ConfirmAppointmentParams>(
  'appointment/confirm',
  async (params, thunkApi) => {
    const appointment = await AppointmentDaoService.confirm(params);
    return appointment;
  }
);

export const appointmentThunks = {
  getList,
  cancel,
  confirm
};
