import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { buildQueryString } from '../../../common/utils/buildQueryString';
import { Appointment } from '../interfaces/appointment';
import { Attendee } from '../interfaces/attendee';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';

export interface Pagination {
  page: number;
  limit: number;
}

export interface DetailsListParams extends Pagination {
  sortBy?: string;
  endDatetime?: string;
  startDatetime?: string;
  patientLastName?: string[];
  patientFirstName?: string[];
  patientUniqueCode?: string[];
  providerFullName?: string[];
  providerId?: string[];
  patientFullName?: string[];
  status?: number[];
  type?: string[];
  clinicService?: string[];
  upcoming?: boolean;
  [key: string]: (string | number)[] | string | number | undefined | boolean;
}

export interface ConfirmAppointmentParams {
  id: string;
  userId?: number;
}
export interface CancelParams {
  id: string;
  seriesId?: number;
}
export interface DetailsListResponse {
  data: Appointment[];
  hasNextPage: boolean;
}

export interface ConnectToAppointmentResponse {
  meetingResponse: {
    Meeting: {MeetingId: string};
  };
  attendeeResponse: {
    Attendee: {AttendeeId: string};
  };
}

export type GetUserNames = {
  fullName?: string;
  roles?: number[];
  providerId?: number;
}

const getDetailedList = (params: DetailsListParams) => {
  const queryString = buildQueryString(params);
  return TelehealthHttpService.url(`/appointment/details?${queryString}`).get<DetailsListResponse>();
};

const getDetailedListAllProviders = (params: DetailsListParams) => {
  const queryString = buildQueryString(params);
  return TelehealthHttpService.url(`/appointment/all/details?${queryString}`).get<DetailsListResponse>();
};

const getDetailedItem = (id: string) => {
  return TelehealthHttpService.url(`/appointment/${id}/details`).get<Appointment>();
};

const cancel = (params: CancelParams) => {
  return TelehealthHttpService.url('/appointment/cancel').post<Appointment>(params);
};

const confirm = (params: ConfirmAppointmentParams) => {
  return TelehealthHttpService.url('/appointment/confirm').post<Appointment>(params);
};
const dial = (type: AppointmentTypeEnum) => {
  if(type === AppointmentTypeEnum.hologram) {
    return TelehealthHttpService.url('/appointment/dial').post<ConnectToAppointmentResponse>();
  }
};
const connect = (id: string) => {
  return TelehealthHttpService.url('/appointment/connect').post<ConnectToAppointmentResponse>({ id });
};

const disconnect = (id: string) => {
  return TelehealthHttpService.url(`/appointment/disconnect/${id}`).post();
};

const getExternalAttendee = (attendeeId: string) => {
  return TelehealthHttpService.url(`attendee/${attendeeId}/details`).get<Attendee>();
};

const getUserNames = (params: GetUserNames) => {
  const queryString = buildQueryString(params);
  return TelehealthHttpService.url(`/users/full-names?${queryString}`).get<string[]>();
};

const getTesterUserSsiIds = (params: {ssiId?: string}) => {
  const queryString = buildQueryString(params);
  return TelehealthHttpService.url(`/peer-navigators/tester/ssi-ids?${queryString}`).get<string[]>();
};

export const AppointmentDaoService = {
  getDetailedListAllProviders,
  getDetailedList,
  getDetailedItem,
  cancel,
  confirm,
  connect,
  getExternalAttendee,
  getUserNames,
  getTesterUserSsiIds,
  disconnect,
  dial
};
