import { useTranslate } from '@tolgee/react';
import { ConfigProvider, Radio, RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ReactComponent as ListIcon } from '../../../../assets/icons/list.svg';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { useAppointmentListSelector } from '../../store/appointmentSelectors';
import { useAppointmentActionCreators } from '../../store/appointmentSlice';
import style from './appointment-switch.module.scss';
import { useTheme } from '../../../../common/providers/ThemeProvider';

export const AppointmentViewSwitch: FC = () => {
  const { t } = useTranslate();
  const { isDesktop } = useWindowSizeType();
  const { isListView } = useAppointmentListSelector();
  const appointmentActions = useAppointmentActionCreators();
  const {colors} = useTheme();

  const handleChange = (e: RadioChangeEvent) => {
    e.target.value !== isListView && appointmentActions.toggleListView();
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSizeLG: 14,
          colorPrimary: colors.textColor,
          colorText: colors.primary,
          colorTextDisabled: colors.primary,
          colorBgContainerDisabled: colors.background,
          borderRadius: 2
        },
        components: {
          Radio: {
            buttonCheckedBg: colors.primary,
            colorBorder: colors.primary
          }
        }
      }}
    >
      <Radio.Group defaultValue={isListView} onChange={handleChange} size="large" className='appointment-view-switch'>
        <Radio.Button value={false}>
          {isDesktop ? t('features.AppointmentViewSwitch.calendarViewBtn.text') : <CalendarIcon className={classNames(style.icon, { [style.active]: !isListView })} />}
        </Radio.Button>
        <Radio.Button value={true}>
          {isDesktop ? t('features.AppointmentViewSwitch.listViewBtn.text') : <ListIcon className={classNames(style.icon, { [style.active]: isListView })} />}
        </Radio.Button>
      </Radio.Group>
    </ConfigProvider>
  );
};
