import { PayloadAction } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import useErrorModal from '../../features/ui/Modal/useErrorModal';
import useSuccessModal from '../../features/ui/Modal/useSuccessModal';

export type ActionResultCallback = (action: PayloadAction<any>) => void;

export const useActionResultModals = (fetchData: (refreshing?: boolean) => void) => {
  const { SuccessModal, showSuccessModal } = useSuccessModal();
  const { ErrorModal, showErrorModal } = useErrorModal();

  const onActionResponse: ActionResultCallback = useCallback(
    (action: PayloadAction) => {
      if (action.type.includes('rejected')) {
        showErrorModal();
      } else if (action.type.includes('fulfilled')) {
        showSuccessModal();
        fetchData(true);
      }
    },
    [fetchData, showSuccessModal]
  );
  return {
    SuccessModal,
    ErrorModal,
    onActionResponse
  };
};
