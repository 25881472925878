import { useTolgee } from '@tolgee/react';
import { useEffect } from 'react';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { useProfileActionCreators } from '../../features/profile/store/profileSlice';
import { SessionService } from '../../features/session/services/SessionService';
import { Locales } from '../constants';
import { localesMap } from '../date';

export const LanguageChangeProvider = () => {
  const tolgee = useTolgee(['language']);
  const locale = tolgee.getLanguage() || Locales['en-Us'];
  const { token } = useAuthConfigSelector();
  const profileActions = useProfileActionCreators();

  useEffect(() => {
    const lang = localesMap[locale as Locales];
    if (token) {
      SessionService.patchLanguage({ lang }).then(() => {
        profileActions.setLanguage(lang);
      });
    }
  }, [locale, token]);

  return <></>;
};
