import { Button, ConfigProvider, InputNumber, InputNumberProps } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow-up.svg';
import styles from './number-input.module.scss';
import { useTheme } from '../../../common/providers/ThemeProvider';

const getLimitate = (max: any, min: any) => {
  return (value: any, inc = 0) => Math.max(~~min, Math.min(~~value + inc, ~~max));
};

export const NumberInput: FC<
  InputNumberProps & {
    step?: number;
    onValueChange: (value: number) => void;
  }
> = ({ onChange, onValueChange, value, ...props }) => {
  const { max, min, controls, step = 1 } = props;
  const limitate = getLimitate(max, min);
  const {colors} = useTheme();

  const onIncrease = () => {
    onValueChange?.(limitate(value, step));
  };

  const onDecrease = () => {
    onValueChange?.(limitate(value, -step));
  };

  const onInputChange = (value: string | number | null | undefined) => {
    onValueChange?.(limitate(value));
  };

  return (
    <div className={styles.container}>
      <ConfigProvider
        theme={{
          token: { colorLink: colors.primary },
          components: {
            Button: {
              fontWeight: 600
            }
          }
        }}
      >
        <InputNumber
          {...props}
          value={value || null}
          onChange={onInputChange}
          controls={false}
          className={classNames(styles.input, props.className)}
        />
        {controls && (
          <div className={styles.controls}>
            <Button
              icon={<ArrowUp />}
              className={styles.button}
              type="link"
              onClick={onIncrease}
              disabled={value === max}
            />
            <Button
              icon={<ArrowDown />}
              className={styles.button}
              type="link"
              onClick={onDecrease}
              disabled={!value || value === min}
            />
          </div>
        )}
      </ConfigProvider>
    </div>
  );
};
