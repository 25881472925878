import { useTranslate } from '@tolgee/react';
import { FC } from 'react';
import { StateStatus } from '../../../../common/enums/store';
import { AssignedProvider } from '../../store/assignedProvidersSlice';
import { AssignedProvidersListItem } from '../AssignedProvidersListItem/AssignedProvidersListItem';

export const AssignedProvidersList: FC<{
  providers: AssignedProvider[];
  status: StateStatus;
  error: any;
}> = ({
  providers = [],
  error,
  status
}) => {
  const { t } = useTranslate();

  return (
    <>
      {providers.map((provider) => <AssignedProvidersListItem {...provider} key={provider.id}/>)}      
      <div>{status === StateStatus.LOADING && t('common.appointments.loadingBar.text')}</div>
      <div>{error && t('common.appointments.errFetch')}</div>
    </>
  );
};

