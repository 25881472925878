import { Tooltip } from 'antd';
import classNames from 'classnames';
import { CSSProperties, FC } from 'react';
import { EventWrapperProps } from 'react-big-calendar';
import { Link } from 'react-router-dom';
import { AppointmentStatusEnum } from '../../../appointment/enums/appointmentEnums';
import { CalendarEvent } from '../useCalendarEvent';
import { AppointmentType } from './AppointmentType';

enum CalendarView {
  time = 'time',
  date = 'date'
}

const statusStyleMap: Record<AppointmentStatusEnum, string> = {
  '0': 'rbc-event-requested-by-provider',
  '1': 'rbc-event-requested-by-patient',
  '2': 'rbc-event-confirmed',
  '3': '',
  '4': 'rbc-event-active',
  '5': '',
  '6': 'rbc-event-requested-by-tester'
};

const additionalStyleMap: Record<AppointmentStatusEnum, string> = {
  '0': '',
  '1': '',
  '2': 'rbc-peer-navigator-event-confirmed',
  '3': '',
  '4': 'rbc-peer-navigator-event-active',
  '5': '',
  '6': ''
};

const eventPositionCalc = (event: CalendarEvent, type: CalendarView, style?: CSSProperties): CSSProperties => {
  if (type === CalendarView.time) {
    return {
      top: Number(style?.top) * 9.59,
      position: 'absolute',
      left: 0,
      right: 0,
      height: (new Date(event.end).getTime() - new Date(event.start).getTime()) / 1000 / 60 / 1.498 + 'px'
    };
  }
  return {};
};

export const EventCell: FC<
  {
    event: CalendarEvent;
    draggable?: boolean;
    style?: CSSProperties;
    type: CalendarView;
  } & EventWrapperProps
> = (props) => {
  const { event, draggable, style, type } = props;

  if (event.type === 'dayoff') {
    // factor is relevant to calendar container height=610px
    const eventStyle: CSSProperties = eventPositionCalc(event, type, style);
    return (
      <div className={'rbc-event-dayoff'} style={eventStyle} tabIndex={0}>
        {event.title}
      </div>
    );
  }

  const linkView = (
    <Link
      to={`/appointments/${event.appointmentId}`}
      className={classNames(
        'rbc-event-popup',
        statusStyleMap[event.isPeerNavigatorAppointment ? AppointmentStatusEnum.REQUESTED_BY_TESTER : event.status!],
        event.isPeerNavigatorAppointment ? additionalStyleMap[event.status!] : ''
      )}
    >
      <AppointmentType type={event.type} isRecurrent={event.isRecurrent} />
      <span className={classNames(event.isPeerNavigatorAppointment ? 'light' : '')}>
        {`${event.title}, ${event.patient}`}
      </span>
    </Link>
  );

  const isShowMoreEvent = draggable;
  if (isShowMoreEvent) {
    return linkView;
  }

  // factor is relevant to calendar container height=610px
  const eventStyle: CSSProperties = eventPositionCalc(event, type, style);

  return (
    <Tooltip
      title={linkView}
      placement="right"
      arrow={false}
      overlayClassName="rbc-event-tooltip"
      overlayInnerStyle={{
        color: 'black',
        padding: 0,
        margin: 0,
        minHeight: 26,
        borderRadius: 5
      }}
      trigger={['hover', 'click']}
    >
      <div
        tabIndex={0}
        className={classNames(
          'rbc-event-cell',
          statusStyleMap[event.isPeerNavigatorAppointment ? AppointmentStatusEnum.REQUESTED_BY_TESTER : event.status!],
          event.isPeerNavigatorAppointment ? additionalStyleMap[event.status!] : ''
        )}
        style={eventStyle}
      >
        <AppointmentType type={event.type} isRecurrent={event.isRecurrent} />
        <span>{event.isRecurrent ? event.shortTitle : event.title}</span>
      </div>
    </Tooltip>
  );
};
