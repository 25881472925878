import { iso1A2Code } from '@rapideditor/country-coder';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { ENTER_VALID_PHONE_MESSAGE } from '../../profile/utils/profileProviderValidationSchema';
import style from '../ControlledInput/controlled-input.module.scss';

interface Props {
  control: Control<any>;
  name: string;
  validationCheck: (value: string) => void;
  label?: string;
  placeholder?: string;
  error?: boolean;
  classes?: string;
}

const DEFAULT_COUNTRY_CODE = 'jm';

export const ControlledPhoneInput: FC<Props> = (props) => {
  const [defaultCountryCode, setDefaultCountryCode] = useState<string | undefined>();

  useEffect(() => {
    if (props.control._formValues.phone) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentCountryCode = iso1A2Code([position.coords.longitude, position.coords.latitude]);
          if (currentCountryCode) {
            setDefaultCountryCode(currentCountryCode.toLowerCase());
          }
        },
        () => setDefaultCountryCode(DEFAULT_COUNTRY_CODE)
      );
    }
  }, []);

  return (
    <div
      className={classNames(
        {
          [style.controlledInput]: true,
          [style.error]: props.error
        },
        props.classes
      )}
    >
      {props.label && <label className={style.label}>{props.label}</label>}
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => {
          return (
            <PhoneInput
              {...field}
              onChange={(value: any) => {
                field.onChange(value);
                props.validationCheck(value);
              }}
              placeholder={props.placeholder}
              masks={{ jm: '...-....' }}
              country={defaultCountryCode}
            />
          );
        }}
      />
      {props.error && <p className={style.validationMessage}>{ENTER_VALID_PHONE_MESSAGE}</p>}
    </div>
  );
};
