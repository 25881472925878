import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import useLayoutEffect from 'rc-util/lib/hooks/useLayoutEffect';
import { memo, useMemo, useState } from 'react';
import ResizeItem from './ResizeItem';
import styles from './overflow.module.scss';
import useEffectState, { useBatcher } from './useEffectState';

const RESPONSIVE = 'responsive' as const;

export interface OverflowProps<ItemType> {
  data: ItemType[];
  getItemKey: (item: ItemType) => React.Key;
  renderItem: (item: ItemType, style: React.CSSProperties) => React.ReactNode;
  renderRest: (omittedItems: ItemType[], style: React.CSSProperties) => React.ReactNode;
  className?: string;
  maxCount?: number | typeof RESPONSIVE;
  // Used for `responsive`. It will limit render node to avoid perfomanse issue
  itemWidth?: number;
}

function Overflow<ItemType extends { key: React.Key }>({
  data,
  getItemKey,
  renderItem,
  renderRest,
  itemWidth = 10,
  className,
  maxCount,
}: OverflowProps<ItemType>) {
  const notifyEffectUpdate = useBatcher();
  const [containerWidth, setContainerWidth] = useEffectState<number>(notifyEffectUpdate, 0);
  const [itemWidths, setItemWidths] = useEffectState(notifyEffectUpdate, new Map<React.Key, number>());
  const [prevRestWidth, setPrevRestWidth] = useEffectState<number>(notifyEffectUpdate, 0);
  const [restWidth, setRestWidth] = useEffectState<number>(notifyEffectUpdate, 0);
  const [displayCount, setDisplayCount] = useState(0);
  const [restReady, setRestReady] = useState(false);

  const isResponsive = maxCount === RESPONSIVE;
  const shouldResponsive = !!data.length && isResponsive;
  // When is `responsive`, we will always render rest node to get the real width of it for calculation
  const showRest = shouldResponsive || (typeof maxCount === 'number' && data.length > maxCount);

  const mergedData = useMemo(() => {
    let items = data;
    if (shouldResponsive) {
      items = data.slice(0, Math.min(data.length, containerWidth / itemWidth));
    } else if (typeof maxCount === 'number') {
      items = data.slice(0, maxCount);
    }
    return items;
  }, [data, itemWidth, containerWidth, maxCount, shouldResponsive]);

  const omittedItems = useMemo(() => {
    if (shouldResponsive) {
      return data.slice(displayCount + 1);
    }
    return data.slice(mergedData.length);
  }, [data, mergedData.length, shouldResponsive, displayCount]);

  function onOverflowResize(_: object, element: HTMLElement) {
    setContainerWidth(element.clientWidth);
  }

  function registerSize(key: React.Key, width: number) {
    setItemWidths((origin) => {
      const clone = new Map(origin);
      if (width) {
        clone.set(key, width);
      } else {
        clone.delete(key);
      }
      return clone;
    });
  }

  function registerOverflowSize(width: number) {
    setRestWidth(width);
    setPrevRestWidth(restWidth);
  }

  useLayoutEffect(() => {
    function getItemWidth(index: number) {
      return itemWidths.get(mergedData[index].key);
    }

    function updateDisplayCount(count: number, flag?: boolean, notReady?: boolean) {
      if (displayCount === count && !flag) return;
      setDisplayCount(count);
      if (!notReady) {
        setRestReady(count < data.length - 1);
      }
    }

    // Always use the max width to avoid blink
    const mergedRestWidth = Math.max(prevRestWidth, restWidth);

    if (containerWidth && typeof mergedRestWidth === 'number' && mergedData) {
      let totalWidth = 0;
      const len = mergedData.length;
      const lastIndex = len - 1;
      // When data count change to 0, reset this since not loop will reach
      if (!len) {
        updateDisplayCount(0);
        return;
      }
      for (let i = 0; i < len; i += 1) {
        const currentItemWidth = getItemWidth(i);
        // Break since data not ready
        if (currentItemWidth === undefined) {
          updateDisplayCount(i - 1, undefined, true);
          break;
        }
        // Find best match
        totalWidth += currentItemWidth;
        if (
          // Only one means `totalWidth` is the final width
          (lastIndex === 0 && totalWidth <= containerWidth) ||
          // Last two width will be the final width
          (i === lastIndex - 1 && totalWidth + getItemWidth(lastIndex)! <= containerWidth)
        ) {
          // Additional check if match the end
          updateDisplayCount(lastIndex);
          break;
        } else if (totalWidth + mergedRestWidth > containerWidth) {
          // Can not hold all the content to show rest
          updateDisplayCount(i - 1, true);
          break;
        }
      }
    }
  }, [containerWidth, itemWidths, restWidth, mergedData]);

  const displayRest = restReady && !!omittedItems.length;

  const internalRenderItemNode = (item: ItemType, index: number) => {
    return (
      <ResizeItem
        key={getItemKey(item)}
        order={index}
        registerSize={(size) => registerSize(item.key, size)}
        display={index <= displayCount}
        responsive={shouldResponsive}
        render={(style) => renderItem(item, style)}
      />
    );
  };

  const restNode = (
    <ResizeItem
      key="rest"
      responsive={shouldResponsive}
      display={displayRest}
      registerSize={registerOverflowSize}
      render={(style) => renderRest(omittedItems, style)}
    />
  );

  return (
    <ResizeObserver onResize={onOverflowResize} disabled={!shouldResponsive}>
      <div className={classNames(styles.container, mergedData.length > 0 && className)}>
        {mergedData.map(internalRenderItemNode)}
        {showRest && restNode}
      </div>
    </ResizeObserver>
  );
}

export default memo(Overflow) as typeof Overflow;
