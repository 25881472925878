import ResizeObserver from 'rc-resize-observer';
import { useEffect } from 'react';

export interface ItemProps extends React.HTMLAttributes<any> {
  responsive?: boolean;
  registerSize: (width: number) => void;
  render: (style: React.CSSProperties) => React.ReactNode;
  display: boolean;
  order?: number;
  // https://github.com/ant-design/ant-design/issues/35475
  /**
   * @private To make node structure stable. We need keep wrap with ResizeObserver.
   * But disable it when it's no need to real measure.
   */
  responsiveDisabled?: boolean;
}

function ResizeItem({
  responsive,
  responsiveDisabled,
  registerSize,
  display,
  order = Number.MAX_SAFE_INTEGER,
  render,
}: ItemProps) {

  const hidden = responsive && !display;

  useEffect(
    () => () => {
      registerSize(0);
    },
    []
  );

  const overflowStyle: React.CSSProperties = hidden ? {
    opacity: 0,
    height: 0,
    overflowY: 'hidden',
    pointerEvents: 'none',
    position: 'absolute',
    order: responsive ? order : undefined,
  } : {
    opacity: 1,
    order: responsive ? order : undefined,
  };

  return (
    <ResizeObserver
      onResize={({ offsetWidth }) => {
        registerSize(offsetWidth);
      }}
      disabled={responsiveDisabled}
    >
      {render(overflowStyle)}
    </ResizeObserver>
  );
}

export default ResizeItem;
