export function isObject(value: unknown): value is object {
  return typeof value === 'object' && value !== null;
}

// eslint-disable-next-line
export function isInstanceOf<T extends Function>(value: unknown, host: T): value is T {
  if (!isObject(value)) {
    return false;
  }
  if (value instanceof host) {
    return true;
  }
  if ('name' in value && typeof value.name === 'string' && value.name === host.name) {
    return true;
  }
  return false;
}
