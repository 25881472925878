import { Checkbox } from 'antd';
import { FC } from 'react';
import { ReactComponent as VideoCumIcon } from '../../../../assets/icons/contact-method-video.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone.svg';
import { ReactComponent as TextIcon } from '../../../../assets/icons/text.svg';
import { ReactComponent as HoloBox } from '../../../../assets/icons/hologram.svg';
import { Schedule, Slot } from '../../interfaces/schedule';
import { CONTACT_METHOD_ERROR } from '../AvailabilitySchedule/AvailabilitySchedule';
import style from '../AvailabilitySchedule/availability-schedule.module.scss';

interface Props {
  record: Schedule;
  slot: Slot;
  handleMethodChange: (
    dayOfTheWeek: number,
    index: number,
    field: 'audio' | 'video' | 'chat' | 'hologram',
    checked: boolean
  ) => void;
  index: number;
}

export const AvailabilityContactMethodsCheckboxes: FC<Props> = ({ handleMethodChange, record, slot, index }) => {
  return (
    <div className="slot-methods-checkboxes">
      <Checkbox
        className={slot.error && slot.error === CONTACT_METHOD_ERROR ? 'error' : ''}
        checked={slot.audio}
        onChange={(e) => handleMethodChange(record.dayOfTheWeek, index, 'audio', e.target.checked)}
        disabled={slot.deleted || !record.enabled}
      >
        <Phone className={style.methodIcon} />
      </Checkbox>
      <Checkbox
        className={slot.error && slot.error === CONTACT_METHOD_ERROR ? 'error' : ''}
        checked={slot.video}
        onChange={(e) => handleMethodChange(record.dayOfTheWeek, index, 'video', e.target.checked)}
        disabled={slot.deleted || !record.enabled}
      >
        <VideoCumIcon className={style.methodIcon} />
      </Checkbox>
      <Checkbox
        className={slot.error && slot.error === CONTACT_METHOD_ERROR ? 'error' : ''}
        checked={slot.chat}
        onChange={(e) => handleMethodChange(record.dayOfTheWeek, index, 'chat', e.target.checked)}
        disabled={slot.deleted || !record.enabled}
      >
        <TextIcon className={style.methodIcon} />
      </Checkbox>
      <Checkbox
        className={slot.error && slot.error === CONTACT_METHOD_ERROR ? 'error' : ''}
        checked={slot.hologram}
        onChange={(e) => handleMethodChange(record.dayOfTheWeek, index, 'hologram', e.target.checked)}
        disabled={slot.deleted || !record.enabled}
      >
        <HoloBox className={style.methodIcon}/>
      </Checkbox>
      {slot.error && slot.error === CONTACT_METHOD_ERROR && <div className="error-message">{slot.error}</div>}
    </div>
  );
};
