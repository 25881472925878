import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@tolgee/react';
import parsePhoneNumber from 'libphonenumber-js';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { User } from '../../../../common/interfaces/user';
import { Button } from '../../../ui';
import { ControlledInput } from '../../../ui/ControlledInput/ControlledInput';
import { ControlledPhoneInput } from '../../../ui/ControlledPhoneInput/ControlledPhoneInput';
import { ControlledSelect } from '../../../ui/ControlledSelect/ControlledSelect';
import { ProfileDaoService } from '../../services/ProfileDaoService';
import {
  MAX_50_SYMBOLS,
  MAX_60_SYMBOLS,
  MAX_BIO_SYMBOLS,
  MAX_NAME_SYMBOLS,
  profileProviderValidationSchema, MAX_SURNAME_SYMBOLS
} from '../../utils/profileProviderValidationSchema';
import { ProfileAvatar } from '../ProfileAvatar/ProfileAvatar';
import style from './profile-provider-edit-form.module.scss';
import { useGetListOfProvince } from '../../../../common/hooks/useGetListOfProvince';

interface Props {
  values: User;
  showConfirmModal: () => void;
  showSuccessModal: () => void;
  hideEditModal: () => void;
  endAction: () => void;
  isMyProfile?: boolean;
}

export const ProfileProviderEditForm: FC<Props> = ({
  values,
  showConfirmModal,
  showSuccessModal,
  hideEditModal,
  endAction,
  isMyProfile
}) => {
  const { t } = useTranslate();
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const { listLocations }  = useGetListOfProvince();
  const formTitle = isMyProfile
    ? t('features.ProfileProviderEditForm.editOwn.title')
    : t('features.ProfileProviderEditForm.editOther.title', {
      firstName: values.firstName,
      lastName: values.lastName
    });

  const defaultValues = {
    firstName: values.firstName,
    lastName: values.lastName,
    typeOfProvider: values.typeOfProvider,
    email: values.email,
    prefix: values.prefix,
    phone: values.phone || '',
    region: values.region,
    address: values.address,
    company: values.company,
    bio: values.bio
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty }
  } = useForm({
    resolver: yupResolver(profileProviderValidationSchema),
    defaultValues
  });

  const isPhoneNotValid = (phone: string) => phone.length < 8 || parsePhoneNumber(`+${phone}`)?.isValid() === false;

  const onSubmit = async (data: Record<string, string | null>) => {
    validationPhoneCheck(data.phone as string);

    if (isPhoneNotValid(data.phone as string)) {
      return;
    }

    const body = { id: values.id, ...data };

    try {
      await ProfileDaoService.updateProviderProfile(body);
      endAction();
      showSuccessModal();
    } catch (error) {
      console.error('edit profile#submit', error);
    } finally {
      hideEditModal();
    }
  };

  const onCancel = () => {
    if (isDirty) {
      showConfirmModal();
    } else {
      hideEditModal();
    }
  };

  const validationPhoneCheck = (phone: string) => {
    if (isPhoneNotValid(phone)) {
      setIsPhoneValid(false);
    } else {
      setIsPhoneValid(true);
    }
  };

  const PREFIX_OPTIONS = [
    { value: '', label: t('common.select.noneOption.label') },
    ...t('features.ProfileProvider.prefixes')
      .split(' ')
      .map((label) => ({ value: label, label }))
  ];
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
      <p className={style.title}>{formTitle}</p>
      <div className={style.avatarLine}>
        <ProfileAvatar uniqueCode={values.uniqueCode} />
        <ControlledSelect
          name="prefix"
          label={t('features.ProfileProviderEditForm.prefix.label')}
          control={control}
          options={PREFIX_OPTIONS}
          classes={style.prefix}
        />
      </div>
      <div className={style.line}>
        <ControlledInput
          type="input"
          name="firstName"
          label={t('common.providerData.firstName.label') + '*'}
          control={control}
          error={errors.firstName}
          count={{ show: true, max: MAX_NAME_SYMBOLS }}
          classes={style.input}
        />
        <ControlledInput
          type="input"
          name="lastName"
          label={t('common.providerData.lastName.label') + '*'}
          control={control}
          error={errors.lastName}
          count={{ show: true, max: MAX_SURNAME_SYMBOLS }}
          classes={style.input}
        />
      </div>
      <div className={style.line}>
        <ControlledInput
          type="input"
          name="typeOfProvider"
          label={t('common.providerData.type.label')}
          control={control}
          classes={style.input}
          count={{ show: true, max: MAX_60_SYMBOLS }}
          error={errors.typeOfProvider}
        />
        <ControlledInput
          type="input"
          name="company"
          label={t('features.ProfileProviderEditForm.company.label')}
          control={control}
          error={errors.company}
          count={{ show: true, max: MAX_60_SYMBOLS }}
          classes={style.input}
        />
      </div>
      <div className={style.line}>
        <ControlledInput
          type="input"
          name="email"
          label={t('common.providerData.email.label') + '*'}
          control={control}
          error={errors.email}
          count={{ show: true, max: MAX_50_SYMBOLS }}
          classes={style.input}
        />
        <ControlledPhoneInput
          name="phone"
          label={t('features.ProfileProviderEditForm.phone.label') + '*'}
          control={control}
          placeholder={t('features.ProfileProvider.errEmptyPhone')}
          validationCheck={(value: string) => validationPhoneCheck(value)}
          error={!isPhoneValid}
          classes={style.input}
        />
      </div>
      <div className={style.line}>
        <ControlledSelect
          name="region"
          label={t('common.providerData.region.label')}
          control={control}
          options={listLocations}
          classes={style.input}
        />
        <ControlledInput
          type="input"
          name="address"
          label={t('common.providerData.address.label')}
          control={control}
          error={errors.address}
          count={{ show: true, max: MAX_60_SYMBOLS }}
          classes={style.input}
        />
      </div>

      <ControlledInput
        type="textarea"
        name="bio"
        label={t('common.providerData.bio.label')}
        control={control}
        error={errors.bio}
        count={{ show: true, max: MAX_BIO_SYMBOLS }}
        classes={style.bio}
      />

      <div className={style.actions}>
        <Button htmlType="submit" type="primary" className={`${style.button} buttonPrimary`} disabled={!isDirty}>
          {t('features.ProfileProviderEditForm.saveBtn.text')}
        </Button>
        <Button onClick={onCancel} type="primary" className={`${style.button} buttonCancel`}>
          {t('features.ProfileProviderEditForm.cancelBtn.text')}
        </Button>
      </div>
    </form>
  );
};
