import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import useModal from '..';
import styles from './modal.module.scss';

interface ConfirmModalProps {
  onOk: () => void;
  title?: string;
  onCancel?: () => void;
}

const useConfirmModal = (isDataChanged?: boolean) => {
  const { t } = useTranslate();
  const { Modal, hideModal, showModal } = useModal({});

  const { state, proceed, reset } = useBlocker(({ currentLocation, nextLocation }) => {
    const isLocationChanged =
      currentLocation.pathname !== nextLocation.pathname || currentLocation.search !== nextLocation.search;
    return isLocationChanged && !!isDataChanged;
  });

  const handleCancel = () => {
    reset?.();
    hideModal();
  };

  useEffect(() => {
    if (state === 'blocked') {
      showModal();
    }
  }, [state]);

  const ConfirmModal = useCallback(
    ({ onOk, title }: ConfirmModalProps) => (
      <Modal onCancel={handleCancel}>
        {title ? (
          <p className={styles.title}>{title}</p>
        ) : (
          <>
            <p className={styles.title}>{t('features.ConfirmModal.title')}</p>
            <p className={styles.text}>{t('features.ConfirmModal.message')}</p>
          </>
        )}
        <div className={styles.buttonsBlock}>
          <Button
            type="primary"
            className={styles.buttonPrimary}
            onClick={() => {
              onOk();
              hideModal();
              proceed?.();
            }}
          >
            {t('features.ConfirmModal.confirmBtn.text')}
          </Button>
          <Button type="primary" className={styles.buttonPrimaryLow} onClick={handleCancel}>
            {t('features.ConfirmModal.cancelBtn.text')}
          </Button>
        </div>
      </Modal>
    ),
    [Modal]
  );

  return {
    ConfirmModal,
    showConfirmModal: showModal
  };
};

export const useSimpleConfirmModal = (isDataChanged?: boolean) => {
  const { t } = useTranslate();
  const { Modal, hideModal, showModal } = useModal({});

  const handleCancel = () => {
    hideModal();
  };

  const ConfirmModal = useCallback(
    ({ onOk, title }: ConfirmModalProps) => (
      <Modal onCancel={handleCancel}>
        {title ? (
          <p className={styles.title}>{title}</p>
        ) : (
          <>
            <p className={styles.title}>{t('features.ConfirmModal.title')}</p>
            <p className={styles.text}>{t('features.ConfirmModal.message')}</p>
          </>
        )}
        <div className={styles.buttonsBlock}>
          <Button
            type="primary"
            className={styles.buttonPrimary}
            onClick={() => {
              onOk();
              hideModal();
            }}
          >
            {t('features.ConfirmModal.confirmBtn.text')}
          </Button>
          <Button type="primary" className={styles.buttonPrimaryLow} onClick={handleCancel}>
            {t('features.ConfirmModal.cancelBtn.text')}
          </Button>
        </div>
      </Modal>
    ),
    [Modal]
  );

  return {
    ConfirmModal,
    showConfirmModal: showModal
  };
};

export default useConfirmModal;
