import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Email } from '../../../../assets/icons/email.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone.svg';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { getFullName } from '../../../../common/utils/helpers';
import { ClinicServiceEnum } from '../../enums/clinicService';
import type { UserInfo } from '../../services/AppointmentCreateDaoService';
import styles from './appointment-create-selected-user.module.scss';

interface Props {
  user: Omit<UserInfo, 'id'>;
  onDelete?: () => void;
  closeBtnDisabled?: boolean;
  isAdmin: boolean;
  isPatient?: boolean;
}

export const AppointmentCreateSelectedUser: FC<Props> = ({ closeBtnDisabled, user, onDelete, isAdmin, isPatient }) => {
  const { t } = useTranslate();
  const userRolesTitle: UserRoleEnum =
    user.clinicServiceType === ClinicServiceEnum.PeerNavigator ? UserRoleEnum.PeerNavigator : user.role;

  const uri = isPatient ? `/patients/${user.userId}` : `/providers/${user.userId}`;

  return (
    <div className={classNames(styles.userInfo, { [styles.fullWidth]: !isAdmin })}>
      {onDelete && (
        <div className={styles.header}>
          <Button
            icon={<CloseOutlined />}
            disabled={closeBtnDisabled}
            className={styles.closeButton}
            onClick={onDelete}
          />
        </div>
      )}
      <p className={styles.userType}>{t(`common.userRolesTitles.${userRolesTitle}.name`)}</p>
      {(user.firstName || user.lastName) && (
        <Link to={uri} className={'userInfoName'}>
          {getFullName(user)}
        </Link>
      )}
      <span className={'userInfoId'}>
        {user.clinicServiceTitle || user.clinicServiceType || user.ssiId || user.uniqueCode}
      </span>
      {user.email && (
        <div className={'userInfoEmail'}>
          <Email className={styles.icon} />
          <span>{user.email}</span>
        </div>
      )}
      {user.phone && (
        <div className="userInfoEmail">
          <Phone className={styles.icon} />
          <span>{user.phone}</span>
        </div>
      )}
    </div>
  );
};
