import { Dictionary } from '@reduxjs/toolkit';
import { useTranslate } from '@tolgee/react';
import { FC, useMemo } from 'react';
import { StateStatus } from '../../../../common/enums/store';
import { groupAppointmentsByDate } from '../../../../common/helpers';
import { Appointment } from '../../../appointment/interfaces/appointment';
import { AppointmentsByPatientListItem } from '../AppointmentsByPatientListItem/AppointmentsByPatientListItem';
import style from './appointments-by-patient-list.module.scss';

interface Props {
  appointments: Dictionary<Appointment>;
  isHistoryList: boolean;
  status: StateStatus;
  error: any;
}

export const AppointmentsByPatientList: FC<Props> = ({ isHistoryList, appointments = {}, status, error }) => {
  const { t } = useTranslate();

  const groupedList = useMemo(() => {
    const grouped = groupAppointmentsByDate(appointments);

    return Object.entries(grouped);
  }, [appointments]);

  return (
    <>
      {groupedList.map(([date, groupList]) => {
        return (
          <div key={date}>
            <p className={style.date}>{date}</p>
            {groupList.map((item) => (
              <AppointmentsByPatientListItem appointment={item} isHistoryItem={isHistoryList} key={item.id} />
            ))}
          </div>
        );
      })}
      <div>{status === StateStatus.LOADING && t('features.AppointmentsByPatientList.loadingBar.text')}</div>
      <div>{error && t('features.AppointmentsByPatientList.errFetch')}</div>
    </>
  );
};