import { useTranslate } from '@tolgee/react';
import { useCallback } from 'react';
import { LoaderFunctionArgs, defer, useNavigate } from 'react-router-dom';
import { useLiveRefresh } from '../../common/hooks/useLiveRefresh';
import { ValidationError } from '../../common/services/http/errors.helper';
import { isInstanceOf } from '../../common/utils/typeUtils';
import { AppointmentDetails } from '../../features/appointment/components';
import { Appointment } from '../../features/appointment/interfaces/appointment';
import { AppointmentDaoService } from '../../features/appointment/services/AppointmentDaoService';
import { AsyncLoader, BackButton } from '../../features/ui';

export const AppointmentDetailsPage = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const refreshPage = useCallback(() => {
    navigate('.', { replace: true });
  }, []);

  useLiveRefresh({
    listener: refreshPage
  });

  return (
    <div>
      <BackButton label={t('common.backBtn.label')} onClick={() => navigate(-1)} />
      <AsyncLoader
        render={(appointment: Appointment | null) =>
          appointment && <AppointmentDetails appointment={appointment} refreshPage={refreshPage} />
        }
      />
    </div>
  );
};

const fetchDetails = async (id: string) => {
  try {
    const appointment = await AppointmentDaoService.getDetailedItem(id);
    return appointment;
  } catch (error) {
    if (isInstanceOf(error, ValidationError)) {
      throw error;
    }
    return null;
  }
};

AppointmentDetailsPage.loader = async function ({ params }: LoaderFunctionArgs) {
  return defer({ promise: fetchDetails(params.id!) });
};
