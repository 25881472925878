import { useTranslate } from '@tolgee/react';
import { Button } from 'antd';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Email from '../../../../assets/icons/email.svg';
import { ReactComponent as Phone } from '../../../../assets/icons/phone.svg';
import { useWindowSizeType } from '../../../../common/hooks/useWindowSizeType';
import { formatDateWithDayJS } from '../../../../common/utils/dateFormat';
import { getFullName } from '../../../../common/utils/helpers';
import { ProfileAvatar } from '../../../profile/components/ProfileAvatar/ProfileAvatar';
import { ConditionalWrapper } from '../../../ui/ConditionalWrapper/ConditionalWrapper';
import { AssignedProvider } from '../../store/assignedProvidersSlice';
import style from './assigned-providers-list-item.module.scss';

export const AssignedProvidersListItem: FC<AssignedProvider> = (props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const toCreateAppointment = () => {
    navigate('/appointments/appointment-create');
  };

  const { isMobile } = useWindowSizeType();

  const NameContainer = ({
    clinicServiceTitle,
    lastVisitedDate,
    id
  }: {
    lastVisitedDate: string;
    clinicServiceTitle?: string;
    id: string
  }) => (
    <p className={style.nameContainer}>
      <Link to={`/providers/${id}`} className={`${style.name} ${style.accentText}`}>{getFullName(props)}</Link> - {clinicServiceTitle}{' '}
      <span className={style.lastVisit}>(Last visit: {formatDateWithDayJS(lastVisitedDate, 'MMMM DD, YYYY')})</span>
    </p>
  );

  return (
    <div className={style.assignedProviderCard}>
      <ConditionalWrapper
        condition={isMobile}
        wrapper={(children) => <div className={style.providerMainInfo}>{children}</div>}
      >
        <ProfileAvatar uniqueCode={props.uniqueCode} className={style.avatar} />
        {isMobile && (
          <NameContainer clinicServiceTitle={props.clinicServiceTitle || props.clinicServiceType} lastVisitedDate={props.lastVisitedDate} id={props.id}/>
        )}
      </ConditionalWrapper>
      <div className={style.personalData}>
        {!isMobile && (
          <NameContainer clinicServiceTitle={props.clinicServiceTitle || props.clinicServiceType} lastVisitedDate={props.lastVisitedDate} id={props.id} />
        )}
        <p className={style.contactInfoContainer}>
          <span className="userInfoEmail">
            <img className={'emailIcon'} src={Email} alt="img" />
            <span>{props.email}</span>
          </span>
          {props.phone && (
            <span className="userInfoEmail">
              <Phone className={style.icon} />
              <span>{props.phone}</span>
            </span>
          )}
        </p>
      </div>
      <div className={style.cardActions}>
        <Button className='buttonPrimary' type="primary" size="middle" onClick={toCreateAppointment}>
          {t('features.AssignedProvidersListItem.bookBtn.text')}
        </Button>
      </div>
    </div>
  );
};
